import React, { useEffect, useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Modal,
  DropdownButton,
  Dropdown
} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import AdminSideMenu from "./AdminSideMenu";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { auto } from '@popperjs/core';
import config from '../../config';
import { FecthData } from '../../Services/FetchData';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom'
    },
    title: {
      display: true,
      text: '',
    },
  },
};

const labels = ['WEEK 3', 'WEEK 4', 'WEEK 1', 'WEEK 2', 'WEEK 3', 'WEEK 4'];

const data = {
  labels,
  datasets: [
    {
      label: 'School Registered',
      data: ['10', '60', '30', '25', '32', '15'],
      borderColor: 'rgba(51, 64, 212, 0.5)',
      backgroundColor: 'rgba(43, 99, 255, 0.8)',
      fill: {
        target: 'origin',
        above: 'rgba(43, 99, 255, 0.4)',   // Area will be red above the origin
        below: 'rgba(51, 64, 212, 0.4)'    // And blue below the origin
      }
    },
    {
      label: 'Tutors Registered',
      data: ['22', '18', '28', '21', '29', '16'],
      borderColor: 'rgba(208, 31, 43, 0.5)',
      backgroundColor: 'rgba(208, 31, 43, 0.8)',
      fill: {
        target: 'origin',
        above: 'rgba(208, 31, 43, 0.4)',   // Area will be red above the origin
        below: 'rgba(208, 31, 43, 0.4)'    // And blue below the origin
      }
    },
    {
      label: 'Students Impacted',
      data: ['24', '16', '26', '24', '22', '19'],
      borderColor: 'rgba(146, 77, 233, 0.5)',
      backgroundColor: 'rgba(146, 77, 233, 0.8)',
      fill: {
        target: 'origin',
        above: 'rgba(146, 77, 233, 0.4)',   // Area will be red above the origin
        below: 'rgba(146, 77, 233, 0.4)'    // And blue below the origin
      }
    },
    {
      label: 'Total Hours Spent',
      data: ['23', '19', '20', '31', '25', '23'],
      borderColor: 'rgba(116, 147, 62, 0.5)',
      backgroundColor: 'rgba(116, 147, 62, 0.8)',
      fill: {
        target: 'origin',
        above: 'rgba(116, 147, 62, 0.4)',   // Area will be red above the origin
        below: 'rgba(116, 147, 62, 0.4)'    // And blue below the origin
      }
    },
  ],
};

ChartJS.register(Tooltip, Legend);

function AdminStatistics() {
  const [statistic, setStatistic] = useState()
  const user = JSON.parse(localStorage.getItem('user'))
  const history = useHistory();
  useEffect(() => {
    FecthData.getData('users/get_admin_statistics').then(
      (data) => setStatistic(data?.data)
    )
  }, [])
  const selectedDay = val => {
    console.log(val);
  };
  const logout = () => {
    localStorage.clear()
    history.push('/admin')
  }
  return (
    <>
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="dashboard-wrap">
          <AdminSideMenu />
          <div className="middle">
            <div className="card">
              <Row>
                <Col md={6} className="mt-2">
                  <h4 className="text-muted mb-0">Overall Statistics</h4>
                </Col>
                <Col md={6} className="mt-2">
                  <div className="filters">
                    <ul>
                      <li>
                        <a href="javascript:void(0)">Sort <img src="images/sort-icon.png" alt="" width="17" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">Filter <img src="images/filter-icon.png" alt="" width="13" />
                        </a>
                      </li>
                      <li className="ms-0"></li>
                    </ul>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="video-category">
                    <ul className="no-space">
                      <li className="me-4 active">
                        <Link to="/admin-statistics">Schools</Link>
                      </li>
                      <li onClick={() => alert('work in progress')}>
                        <Link to="#/school-teachers">Organizations</Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="statistic-box">
                    <h4>No. Of Schools (2021)</h4>
                    <h3>{statistic?.school_count}</h3>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="statistic-box">
                    <h4>Tutors Involved (2021)</h4>
                    <h3>{statistic?.tutor_count}</h3>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="statistic-box">
                    <h4>Number of Students Enrolled (2021)</h4>
                    <h3>{statistic?.student_count}</h3>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="statistic-box">
                    <h4>Total Hours Spent (2021)</h4>
                    <h3>{statistic?.hours_count}</h3>
                  </div>
                </Col>
                <Col md={12}>
                  <h5 className="sub-heading mt-4 text-muted d-flex justify-content-between align-items-center">Total No. Of Schools Registered
                    <Form.Group style={{ minWidth: 120 }}>
                      <Form.Select>
                        <option>Weekly</option>
                        <option>Monthly</option>
                        <option>Yearly</option>
                      </Form.Select>
                    </Form.Group>
                  </h5>
                  <Line options={options} data={statistic?.graphData ? statistic?.graphData : data} />
                </Col>
                <Col md={12} className="mt-4">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">School Name</th>
                          <th scope="col">ID</th>
                          <th scope="col">Registration Date</th>
                          <th scope="col">State</th>
                          <th scope="col">Email</th>
                          <th scope="col">Contact</th>
                        </tr>
                      </thead>
                      <tbody>
                        {statistic?.school_list.map(school =>
                          <tr>
                            <td>
                              <img src="images/school-logo1.png" alt="" width="44" className="rounded me-2" />
                              {school.school_name}
                            </td>
                            <td>{school.school_code}</td>
                            <td>{new Date(school.created_at).toLocaleDateString('en-us')}</td>
                            <td>{school.state}</td>
                            <td>{school.email}</td>
                            <td>{school.phone}</td>
                          </tr>)}
                        <tr>
                          <td>
                            <img src="images/school-logo1.png" alt="" width="44" className="rounded me-2" />
                            SULLIVAN High School
                          </td>
                          <td>564789</td>
                          <td>12/01/2021</td>
                          <td>Illinois</td>
                          <td>jane@mather.edu</td>
                          <td>312-662-8517</td>
                        </tr>
                        <tr>
                          <td>
                            <img src="images/school-logo2.png" alt="" width="44" className="rounded me-2" />
                            Mater Dei Catholic
                          </td>
                          <td>564789</td>
                          <td>12/01/2021</td>
                          <td>Illinois</td>
                          <td>jane@mather.edu</td>
                          <td>312-662-8517</td>
                        </tr>
                        <tr>
                          <td>
                            <img src="images/school-logo3.png" alt="" width="44" className="rounded me-2" />
                            Mather Hight School
                          </td>
                          <td>564789</td>
                          <td>12/01/2021</td>
                          <td>Illinois</td>
                          <td>jane@mather.edu</td>
                          <td>312-662-8517</td>
                        </tr>
                        <tr>
                          <td>
                            <img src="images/school-logo4.png" alt="" width="44" className="rounded me-2" />
                            Mavericks
                          </td>
                          <td>564789</td>
                          <td>12/01/2021</td>
                          <td>Illinois</td>
                          <td>jane@mather.edu</td>
                          <td>312-662-8517</td>
                        </tr>
                        <tr>
                          <td>
                            <img src="images/school-logo5.png" alt="" width="44" className="rounded me-2" />
                            Lorem Ipsum
                          </td>
                          <td>564789</td>
                          <td>12/01/2021</td>
                          <td>Illinois</td>
                          <td>jane@mather.edu</td>
                          <td>312-662-8517</td>
                        </tr>
                        <tr>
                          <td>
                            <img src="images/school-logo3.png" alt="" width="44" className="rounded me-2" />
                            Mather Hight School
                          </td>
                          <td>564789</td>
                          <td>12/01/2021</td>
                          <td>Illinois</td>
                          <td>jane@mather.edu</td>
                          <td>312-662-8517</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="right">
            <div className="user-short-text">
              <div className="notification-bell">
                <img src="images/bell-icon.png" alt="" width="32" />
                <div className="noti-dot"></div>
              </div>
              <div className="user-short-profile">
                <div className="user-avatar">
                  {user?.propic && <img src={config.imgBaseUrl + user?.propic} width={'100%'} height={'100%'} alt="" />}{!user?.propic && <img src="images/no_profile_img.png" width={'100%'} height={'100%'} alt="" />}
                </div>
                <div className="user-short-name">
                  <h5>{user?.name} </h5>
                  <p>Status: {user?.role} | {user?.status}</p>
                </div>
                <Dropdown >
                  <Dropdown.Toggle drop='none' variant="">

                  </Dropdown.Toggle >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={logout}
                    // href="#/action"
                    >Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <div className="drop-down">
                  <img src="images/drop-icon.png" alt="" width="10" />
                </div> */}
              </div>
            </div>
            {/* <div className="card">
              <div className="school-side-details">
                <div className="school-logo-circle">
                  <img src="images/avatar.jpg" alt="" />
                </div>
                <div className="school-more-option">
                  <Dropdown>
                    <Dropdown.Toggle>
                      <img src="images/more-icon.png" alt="" width="20" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="/admin-tutor-profile">View Profile</Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item href="#">Edit Account</Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item href="#">Delete Account</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="school-name">
                  <h5>Jane Doe</h5>
                  <h6>Student ID: 567872</h6>
                </div>
                <div className="teacher-contact-detail">
                  <ul>
                    <li>
                      <a href="#">
                        <img src="images/teacher-info1.png" alt="" width="54" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src="images/teacher-info2.png" alt="" width="54" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src="images/teacher-info3.png" alt="" width="54" />
                      </a>
                    </li>
                  </ul>
                  <a href="#" className="btn btn-secondary solid btn-sm no-shadow d-block">Account Activated</a>
                </div>
                <div className="about-teacher">
                  <h4>About:</h4>
                  <p>
                    Lorem ipsum dolor sit, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p>
                  <h4>Gender:</h4>
                  <p>Female</p>
                  <h4>Date of birth</h4>
                  <p>2 Jan 2003</p>
                  <h4>Email:</h4>
                  <p>jane@mather.edu</p>
                  <h4>Parent’s Email</h4>
                  <p>sarahdoe@gmail.com</p>
                  <h4>Facilitator Email:</h4>
                  <p>john@mather.edu</p>
                  <h4>Subjects Interested:</h4>
                  <div className="tutor-subject mt-2">
                    <ul>
                      <li>
                        Algebra
                      </li>
                      <li>
                        Science
                      </li>
                      <li>
                        Physics
                      </li>
                      <li>
                        Geography
                      </li>
                      <li>
                        Calculus
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>

    </>
  )
}

export default AdminStatistics
