import React, { useState, useEffect } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Modal,
  DropdownButton,
  Dropdown
} from "react-bootstrap";
import { NavLink, Link, useParams } from "react-router-dom";
import AdminSideMenu from "../AdminSideMenu";
import Autocomplete from 'react-autocomplete'
import AdminLayout from './AdminLayout';
import { Admin } from '../../../Services/Admin';
import { NotificationManager } from 'react-notifications';
import config from "./../../../config";
import { School } from '../../../Services/School';
import { FecthData } from '../../../Services/FetchData';

function AdminDashboard() {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [value, setValue] = useState('');
  const [school, setSchool] = useState({});
  const [selectedSchool, selectSchool] = useState();
  const [schoolUser, setSchoolUser] = useState()
  const [error, setError] = useState();
  const [validated, setValidated] = useState(false);
  const user = JSON.parse(localStorage.getItem('user'))

  const [schlFormData, setSchlFormData] = useState({
    school: "",
    school_id: "",
    school_name: "",
    school_code: "",
    school_street: "",
    school_city: "",
    school_state: "",
    school_zip: "",
    logo: "",
    logo_url: "",
    display_logo: "yes",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    contact_by: [],
    accept_privacy_policy: false
  })
  const setSchoolInfo = (item) => {
    setSchlFormData({
      ...schlFormData,
      school: item,
      school_id: item._id,
      school_name: item.school_name,
      school_code: item.school_code,
      school_street: item.street,
      school_city: item.city,
      school_state: item.state,
      school_zip: item.zip_code
    })
  }
  const submitData = () => {
    let formData = new FormData();
    Object.entries(schlFormData).map(([key, value]) => {
      if (key !== 'contact_by') {
        formData.append(key, value);
      } else {
        formData.append(key, value.toString());
      }

    })
    School.registerSchool(formData).then(
      (data) => {
        if (!data.errors) {
          NotificationManager.success(data.message, 'success!', 2000);
          // setMessage(data.message)
          // history.push('/school');
        } else {
          NotificationManager.error(data.message.toString(), 'error!', 2000);
        }
        console.log(data)

      },
      (errors) => {
        // NotificationManager.error(data.message.toString(), 'error!', 2000);
        // setError(error.toString())
      }
    )
  }

  useEffect(() => {
    Admin.post({ 'page': 0 }, 'school/get_all_school').then(
      (data) => {
        setSchool(data?.data)
        // selectSchool(data?.data[0])
      },
      (error) => {
        setError(error.toString())
        NotificationManager.error(error.toString(), 'error!', 2000);
      }
    )

  }, []);
  const fetchSchoolUser = () => {
    if (selectedSchool) {
      FecthData.getData('school_user/get_school_user_by_school_id/' + selectedSchool._id).then(
        (data) => setSchoolUser(data?.data),
        (error) => {
          // NotificationManager.error(data?.message, 'error!', 2000)
        }
      )
    }
  }
  useEffect(() => {
    fetchSchoolUser()
  }, [selectedSchool])
  console.log(schoolUser)
  return (
    <>
      <AdminLayout school={selectedSchool} schoolUser={schoolUser} refreshCard={fetchSchoolUser} userDetailPath='/admin/school/students/' showUserCard={selectedSchool}>
        <Row>
          <Col md={12}>
            <Form.Group className="form-group mb-2">
              <Form.Control type="text" placeholder="Search" className="search-input" />
              <Button variant="primary" type="submit" className="search-btn">
                <img src="images/search-icon.png" alt="" width="22" />
              </Button>
            </Form.Group>
          </Col>
          <Col md={6} className="mt-4">
            <h3 className="text-muted mb-0">Schools</h3>
          </Col>
          <Col md={6} className="mt-4">
            <div className="filters">
              <ul>
                <li>
                  <a href="javascript:void(0)">Sort <img src="images/sort-icon.png" alt="" width="17" />
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">Filter <img src="images/filter-icon.png" alt="" width="13" />
                  </a>
                </li>
                <li onClick={handleShow}>
                  <a href="javascript:void(0)">Add <img src="images/add-icon.png" alt="" width="15" />
                  </a>
                </li>
              </ul>
            </div>
          </Col>
          {school && school.length > 0 && school?.map((content, index) => (
            <Col md={3} key={index} onClick={() => selectSchool(content)}>
              <div className="school-box">
                <Link >
                  <div className="school-box-logo">
                    <img src="images/school-logo3.png" alt="" />
                  </div>
                  <h4>{content.school_name}</h4>
                </Link>
              </div>
            </Col>
          ))}
          {!school && <Col md={12} >No school Found</Col>}
        </Row>

      </AdminLayout >
      <Modal show={show} onHide={handleClose}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add School</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="modal-form">
              <Row>
                {/* <Form.Group className="form-group mb-3" as={Col} md="12">
                <Form.Control type="text" placeholder="Search by school name, city or zip code" />
              </Form.Group> */}
                <Form.Group className="form-group mb-3" style={{ zIndex: 2 }}>
                  {/* <Form.Control type="text" placeholder="Search" /> */}
                  <Autocomplete className="search"
                    items={school || []}
                    shouldItemRender={(item, value
                    ) => item.school_name.toLowerCase()
                      .indexOf(value.toLowerCase()) > -1}
                    getItemValue={item => item.school_name}
                    renderItem={(item, isHighlighted) =>
                      <div style={{
                        background: isHighlighted ?
                          '#eee' : 'white',
                        padding: '5px 10px',
                      }}
                        key={item.id}>
                        {item.school_name}
                      </div>
                    }
                    value={value}
                    onChange={e => setValue(e.target.value)}
                    onSelect={(val, item) => {
                      setValue(val)
                      setSchoolInfo(item)
                    }}
                    inputProps={{
                      style: {
                        width: '100%',
                        background: '#E9ECF4',
                        // boxShadow: '0 0 8px rgba(0,0,0,0.25)',
                        width: '100%',
                        color: '#000',
                        borderRadius: 10,
                        minHeight: 52,
                        border: '1px solid #E9ECF4',
                        fontSize: 14,
                        paddingLeft: 15,
                        paddingRight: 15,
                      },
                      placeholder: 'Search by school name, city or zip code'
                    }}
                    wrapperStyle={{
                      width: '100%',
                    }}
                  />
                  <Button variant="primary" type="submit" className="search-btn" style={{ right: 18 }}>
                    <img src="images/search-icon.png" alt="" width="22" />
                  </Button>
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="Point of Contact First Name*" value={schlFormData.first_name} onChange={(e) => setSchlFormData({ ...schlFormData, first_name: e.target.value })} />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="Point of Contact Last Name*" value={schlFormData.last_name} onChange={(e) => setSchlFormData({ ...schlFormData, last_name: e.target.value })} />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="Contact number*" value={schlFormData.phone} onChange={(e) => setSchlFormData({ ...schlFormData, phone: e.target.value })} />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="email" placeholder="Email*" value={schlFormData.email} onChange={(e) => setSchlFormData({ ...schlFormData, email: e.target.value })} />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="School Name" value={schlFormData.school_name} />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value={schlFormData.school_code} placeholder="School Code" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value={schlFormData.school_street} placeholder="Address" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="email" value={schlFormData.school_city} placeholder="City" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="email" value={schlFormData.school_state} placeholder="State" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="email" value={schlFormData.school_zip} placeholder="Zip Code" />
                </Form.Group>
                <Col md="12" className="text-center mt-2">
                  <Button className="px-4 btn-pill" variant="primary" onClick={submitData}>Send Request</Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AdminDashboard
