import React, { useEffect, useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Modal,
  DropdownButton,
  Dropdown
} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import OrgSideMenu from "./OrgSideMenu";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { auto } from '@popperjs/core';
import Logout from '../logout';
import { FecthData } from '../../Services/FetchData';
import config from '../../config';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top'
    },
    title: {
      display: true,
      text: '',
    },
  },
};

const labels = [];

const data = {
  labels,
  datasets: [
    {
      label: 'Lessons',
      data: ['15', '20', '30', '25', '32', '15'],
      borderColor: 'rgba(51, 64, 212, 0.5)',
      backgroundColor: 'rgba(51, 64, 212, 0.5)',
      fill: {
        target: 'origin',
        above: 'rgba(43, 99, 255, 0.4)',   // Area will be red above the origin
        below: 'rgba(51, 64, 212, 0.4)'    // And blue below the origin
      }
    },
    // {
    //   label: 'Hours',
    //   data: ['18', '22', '24', '30', '28', '20'],
    //   borderColor: 'rgba(132, 176, 60, 0.4)',
    //   backgroundColor: 'rgba(132, 176, 60, 0.4)',
    //   fill: {
    //     target: 'origin',
    //     above: 'rgba(132, 176, 60, 0.4)',   // Area will be red above the origin
    //     below: 'rgba(132, 176, 60, 0.4)'    // And blue below the origin
    //   }
    // },
  ],
};

ChartJS.register(Tooltip, Legend);

function OrgStatistics() {
  const user = JSON.parse(localStorage.getItem('user'))
  const [tutors, setTutors] = useState([])
  const [statistics, setStatistics] = useState()
  const [graphData, setGraphData] = useState(data)

  const fetchCompanyTutor = () => {
    FecthData.getData('company_user/get_company_tutor/' + user?.company?.company_id).then(
      (data) => setTutors(data?.data)
    )
  }
  const getCompanyStatistics = () => {
    console.log(labels)
    FecthData.getData('company_user/get_company_statistics/' + user?.company?.company_id).then(
      (data) => {
        console.log(labels)
        let arr1 = []
        data?.data.map(pr => {
          console.log(pr)
          var obj = {
            label: 'Lessions',
            data: {
              MONDAY: new Date(pr?._id?.date).getDay() == 1 ? pr.total_lessions : 0,
              TUESDAY: new Date(pr?._id?.date).getDay() == 2 ? pr.total_lessions : 0,
              WEDNESDAY: new Date(pr?._id?.date).getDay() == 3 ? pr.total_lessions : 0,
              THURSDAY: new Date(pr?._id?.date).getDay() == 4 ? pr.total_lessions : 0,
              FRIDAY: new Date(pr?._id?.date).getDay() == 5 ? pr.total_lessions : 0,
              SATURDAY: new Date(pr?._id?.date).getDay() == 6 ? pr.total_lessions : 0,
              SUNDAY: new Date(pr?._id?.date).getDay() == 7 ? pr.total_lessions : 0,
            },
            borderColor: 'rgba(51, 64, 212, 0.5)',
            backgroundColor: 'rgba(51, 64, 212, 0.5)',
            fill: {
              target: 'origin',
              above: 'rgba(43, 99, 255, 0.4)',   // Area will be red above the origin
              below: 'rgba(51, 64, 212, 0.4)'    // And blue below the origin
            }
          }
          arr1.push(obj)
        })
        console.log(labels)
        const obj = {
          labels,
          datasets: arr1
        }
        console.log(obj)
        setGraphData(obj)
      }
    )
    FecthData.getData('company_user/get_company_statistics_count/' + user?.company?.company_id).then(
      (data) => setStatistics(data?.data)
    )
  }
  useEffect(() => {
    console.log(labels)
    fetchCompanyTutor()
    getCompanyStatistics()
  }, [])

  return (
    <>
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="dashboard-wrap">
          <OrgSideMenu />
          <div className="middle">
            <Row>
              <Col md={12}>
                <Form.Group className="form-group mb-2">
                  <Form.Control type="text" placeholder="Search" className="search-input" />
                  <Button variant="primary" type="submit" className="search-btn">
                    <img src="images/search-icon.png" alt="" width="22" />
                  </Button>
                </Form.Group>
              </Col>
            </Row>
            <div className="card">
              <Row>
                <Col md={4}>
                  <div className="card bg-purple progress-box" style={{ minHeight: 96 }}>
                    <div className="card-text text-white">
                      <h4>No. Of Tutors (2021)</h4>
                      <h5 className="count">{statistics?.number_of_tutor}</h5>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="card bg-danger progress-box" style={{ minHeight: 96 }}>
                    <div className="card-text text-white">
                      <h4>Classes Tutored (2021)</h4>
                      <h5 className="count">{statistics?.classes_tutored}</h5>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="card bg-success progress-box" style={{ minHeight: 96 }}>
                    <div className="card-text text-white">
                      <h4>Total Hours Spent (2021)</h4>
                      <h5 className="count">{statistics?.total_hours_spent}</h5>
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <h5 className="sub-heading mt-4 text-muted d-flex justify-content-between align-items-center">Statistics
                    <Form.Group style={{ minWidth: 120 }}>
                      <Form.Select>
                        <option>Weekly</option>
                        <option>Monthly</option>
                      </Form.Select>
                    </Form.Group>
                  </h5>
                  <Line options={options} data={graphData} />
                </Col>
              </Row>
            </div>
            {/* <div className="card">
              <Row>
                <Col md={12} className="mb-4">
                  <h6 className="text-muted mt-3 d-flex justify-content-between">Employees Volunteering As Tutors <a href="javascript:void(0)" className="text-muted"><small>View All</small></a></h6>
                </Col>
                {tutors.map(tutor => <Col md={4}>
                  <div className="student-rating-box">
                    <div className="rating-avatar">
                      <img src={tutor?.user_id?.propic ? config.imgBaseUrl + tutor?.user_id?.propic : "images/avatar.jpg"} alt="" />
                    </div>
                    <div className="rating-description">
                      <h4>{tutor?.first_name} </h4>
                      <h5>Academic Year 2021-2022</h5>
                      <div className="star-count">
                        <img src="images/rating-4.png" alt="" width="184" />
                      </div>
                      <Link to={{ pathname: "/organization-teacher", state: tutor }} className="btn btn-primary btn-pill px-4">View</Link>
                    </div>
                  </div>
                </Col>)}
                {/* <Col md={4}>
                  <div className="student-rating-box">
                    <div className="rating-avatar">
                      <img src="images/avatar.jpg" alt="" />
                    </div>
                    <div className="rating-description">
                      <h4>Sarah D. | ID: 567876</h4>
                      <h5>Academic Year 2021-2022</h5>
                      <div className="star-count">
                        <img src="images/rating-4.png" alt="" width="184" />
                      </div>
                      <Link to="/organization-teacher" className="btn btn-primary btn-pill px-4">View</Link>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="student-rating-box">
                    <div className="rating-avatar">
                      <img src="images/avatar.jpg" alt="" />
                    </div>
                    <div className="rating-description">
                      <h4>John W. | ID: 567876</h4>
                      <h5>Academic Year 2021-2022</h5>
                      <div className="star-count">
                        <img src="images/rating-4.png" alt="" width="184" />
                      </div>
                      <a href="javascript:void(0)" className="btn btn-primary btn-pill px-4">View</a>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="student-rating-box">
                    <div className="rating-avatar">
                      <img src="images/avatar.jpg" alt="" />
                    </div>
                    <div className="rating-description">
                      <h4>Joe M. | ID: 567876</h4>
                      <h5>Academic Year 2021-2022</h5>
                      <div className="star-count">
                        <img src="images/rating-4.png" alt="" width="184" />
                      </div>
                      <a href="javascript:void(0)" className="btn btn-primary btn-pill px-4">View</a>
                    </div>
                  </div>
                </Col> *
              </Row>
            </div> */}
          </div>
          <div className="right">
            <div className="user-short-text">
              <div className="notification-bell">
                <img src="images/bell-icon.png" alt="" width="32" />
                <div className="noti-dot"></div>
              </div>
              <div className="user-short-profile">
                <div className="user-avatar">
                  <img src={user?.propic ? config.imgBaseUrl + user?.propic : "images/no_profile_img.png"} width={'100%'} height={'100%'} alt="" />
                </div>
                <div className="user-short-name">
                  <h5>{user?.name} </h5>
                  <p>Status: {user?.role}</p>
                </div>
                <Logout url={'/organization'} />
                {/* <div className="drop-down">
                  <img src="images/drop-icon.png" alt="" width="10" />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default OrgStatistics
