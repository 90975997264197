import React, { useState, useEffect } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Stack,
  Modal
} from "react-bootstrap";
import Header from "../Header";
import Footer from "../Footer";
import { NavLink, Link } from "react-router-dom";
import { School } from '../../Services/School';
import Autocomplete from 'react-autocomplete';
import { NotificationManager } from 'react-notifications';
import ActionButton from '../ActionButton';

const SchoolSignupStepTwo = ({ nextStep, prevStep, handleFormData, handleContactBy, handleSchool, values }) => {
  const [error, setError] = useState(false);
  const [contactBy, setContactBy] = useState([]);
  const [message, setMessage] = useState(null);
  const [error2, setError2] = useState();


  // after form submit validating the form data using validator
  const submitFormData = (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (values.email !== '') {
        School.sendEmail({ email: values.email, in_register: true }).then(
          (data) => {
            if (data.error === 0) {
              NotificationManager.success(data.message, 'success!', 2000);
              setMessage(data.message)
              nextStep();
            }
            console.log(data)

          },
          (error) => {
            setError(error.toString())
            NotificationManager.success(error.toString(), 'Error!', 2000);
          }
        )

      } else {
        NotificationManager.success('Email is required', 'Error!', 2000);
      }
    }

  };


  const validateForm = () => {

    let fields = values;
    let errors = {};
    let formIsValid = true;
    console.log(fields["first_name"].trim().length)
    if (!fields["first_name"] || fields["first_name"].trim().length <= 0) {
      formIsValid = false;
      errors["first_name"] = "*Please Select Your First Name.";
    }
    if (!fields["last_name"] || fields["last_name"].trim().length <= 0) {
      formIsValid = false;
      errors["last_name"] = "*Please Select Your Last Name.";
    }
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "*Please enter your email-ID.";
    }

    if (typeof fields["email"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(fields["email"])) {
        formIsValid = false;
        errors["email"] = "*Please enter valid email-ID.";
      }
    }

    if (!fields["phone"]) {
      formIsValid = false;
      errors["phone"] = "*Please enter your phone.";
    }

    if (typeof fields["phone"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);
      if (!pattern.test(fields["phone"])) {
        formIsValid = false;
        errors["phone"] = "*Please enter valid phone.";
      }
    }
    setError2(errors)
    return formIsValid;


  }



  return (
    <>
      <Header />
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="login-body" style={{ backgroundImage: 'url("images/signup-bg.jpg")', height: '100%', width: 'auto', backgroundPosition: 'center' }}>
          <Container>
            <Row>
              <Col md={12}>
                <div className="step-progress">
                  <div className="step-progress-bar">
                    <div className="step-progress-percent" style={{ width: '50%' }}></div>
                  </div>
                  <div className="step-progress-text">
                    <h6>Profile Creation</h6>
                    <h6>50% Completed</h6>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={9}>
                <div className="signup-box">
                  <div className="signup-left">
                    <h4>Add Your Details</h4>
                    <p>The school information is uneditable. Please contact Dasya at dasyausa@gmail.com to make any updates to the school information.</p>
                    <div className="signup-step" style={{ marginTop: 260 }}>
                      <ul>
                        <li></li>
                        <li className="active"></li>
                        <li></li>
                        <li></li>
                      </ul>
                    </div>
                  </div>
                  <div className="signup-right">
                    <Form onSubmit={submitFormData}>
                      <div className="add-details">

                        <Row>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Control type="text" name="first_name" onChange={handleFormData("first_name")} defaultValue={values.first_name} placeholder="Point of contact First Name*" required />
                              <span className="err">{error2?.first_name}</span>
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Control type="text" name="last_name" onChange={handleFormData("last_name")} defaultValue={values.last_name} placeholder="Point of contact Last Name*" required />
                              <span className="err">{error2?.last_name}</span>
                            </Form.Group>
                          </Col>
                          <Col md={12}>
                            <Form.Group className="mb-3">
                              <Form.Control type="tel" name="phone" onChange={handleFormData("phone")} defaultValue={values.phone} placeholder="Point of Contact number*" required />
                              <span className="err">{error2?.phone}</span>
                            </Form.Group>
                          </Col>
                          <Col md={12}>
                            <Form.Group className="mb-3">
                              <Form.Control type="email" name="email" onChange={handleFormData("email")} defaultValue={values.email} placeholder="Point of Contact email*" required />
                              <span className="err">{error2?.email}</span>
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Control type="text" defaultValue={values.school_name} placeholder="School Name" readOnly={true} />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Control type="text" defaultValue={values.school_code} placeholder="School Code" readOnly={true} />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Control type="text" defaultValue={values.school_street} placeholder="Address" readOnly={true} />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Control type="text" defaultValue={values.school_city} placeholder="City" readOnly={true} />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Control type="text" defaultValue={values.school_state} placeholder="State" readOnly={true} />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Control type="text" defaultValue={values.school_zip} placeholder="Zip Code" readOnly={true} />
                            </Form.Group>
                          </Col>
                          <Col md={12}>
                            <Form.Group className="iagree mb-3" controlId="formbymail">
                              <Form.Check className="text-grey" name="contact_by" type="checkbox" onChange={handleContactBy('contact_by')} checked={values.contact_by.includes('email')} label="Contact Me By Email" value="email" />
                            </Form.Group>
                            <Form.Group className="iagree mb-3" controlId="formbyphone">
                              <Form.Check className="text-grey" name="contact_by" type="checkbox" onChange={handleContactBy('contact_by')} checked={values.contact_by.includes('phone')} label="Contact Me By Phone" value="phone" />
                            </Form.Group>
                          </Col>
                        </Row>

                      </div>
                      <div className="signup-back-btn">
                        <Link onClick={prevStep} to="#" className="btn btn-link">Back</Link>
                        <ActionButton type={'submit'} className={'btn btn-primary btn-pill px-4'} title={'Create Account'}
                          requiredFields={[values?.first_name, values?.last_name, values?.email, values?.phone]} />
                        {/* <button type="submit" className="btn btn-primary btn-pill px-4">Create Account</button> */}
                      </div>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <Footer />

    </>
  )
}

export default SchoolSignupStepTwo
