import { Col, Form } from "react-bootstrap";
import { Bar } from "react-chartjs-2";

export default function GraphUI({options,state,statistics}){

  function BadgesAndRewards(){
    return <><Col md={4}>
    <div className="card">
      <h5 className="sub-heading text-muted">Current Level</h5>
      comming soon!
      {/* <img src="images/progress-icon.png" alt="" width="150" style={{ margin: auto, marginTop: 10, }} /> */}
    </div>
  </Col>
  <Col md={8}>
    <div className="card">
      <h5 className="sub-heading text-muted d-flex justify-content-between">Badges & Rewards <span><a className=" text-muted" href="/teacher-review">See All</a></span></h5>
      <div className="badges">
        comming soon!
        {/* <ul>
          <li>
            <img src="images/badge1.png" alt="" width="80" />
            <h6>SCHEDULE 5+ Sessions</h6>
          </li>
          <li>
            <img src="images/badge2.png" alt="" width="50" />
            <h6>CHAT WITH TUTOR</h6>
          </li>
          <li>
            <img src="images/badge3.png" alt="" width="64" />
            <h6>REACH A NEW LEVEL</h6>
          </li>
          <li>
            <img src="images/badge4.png" alt="" width="104" />
            <h6>COMPLETE AN ASSIGNMENT</h6>
          </li>
          <li>
            <img src="images/badge5.png" alt="" width="58" />
            <h6>EARN TUTOR BADGE</h6>
          </li>
        </ul> */}
      </div>
    </div>
  </Col></>
  }
    
    return <><Col md={4}>
    <div className="card bg-purple progress-box">
      <div className="card-text text-white">
        <img src="images/hat-icon.png" alt="" width="70" />
        <h4>Scheduled Classes</h4>
      </div>
      <div className="card-big-text">
        <h5 className="count">{statistics?.schedule_classes}</h5>
      </div>
    </div>
  </Col>
  {/* <Col md={4}>
    <div className="card bg-danger progress-box">
      <div className="card-text text-white">
        <img src="images/book-icon.png" alt="" width="58" />
        <h4>Assignments Completed</h4>
      </div>
      <div className="card-big-text">
        <h5 className="count">07</h5>
      </div>
    </div>
  </Col> */}
  <Col md={4}>
    <div className="card bg-success progress-box">
      <div className="card-text text-white">
        <img src="images/timer-icon.png" alt="" width="34" />
        <h4>Time spent On Dasya</h4>
      </div>
      <div className="card-big-text">
        <h5 className="count">{statistics?.time_spent} <small>HRS</small></h5>
      </div>
    </div>
  </Col>
    <Col md={12}>
    <div className="card">
      <h5 className="sub-heading mt-4 text-muted d-flex justify-content-between">No. Of Hrs Spent Subject Wise
        <Form.Group style={{ minWidth: 120 }}>
          <Form.Select>
            <option>Weekly</option>
            <option>Monthly</option>
          </Form.Select>
        </Form.Group>
      </h5>
      {state ? <Bar options={options} data={state} /> : null}
    </div>
  </Col>
  {/* <BadgesAndRewards/> */}
  </>
  
}