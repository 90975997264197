import React, { useState, useEffect } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Stack,
  Modal
} from "react-bootstrap";
import Header from "../Header";
import Footer from "../Footer";
import { NavLink, Link } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import { School } from '../../Services/School';
import ActionButton from '../ActionButton';

const formatDate = (date) => {

  var d = new Date(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [month, day, year].join('/');
}

function TutorSignupStepThree({ nextStep, prevStep, handleFormData, handleContactBy, handleSchool, values }) {
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(null);
  const [error2, setError2] = useState();
  const [isChecked, setIsChecked] = useState(false);

  const submitFormData = (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (values.email !== '') {
        School.sendEmail({ email: values.email, in_register: true }).then(
          (data) => {
            if (data.error === 0) {
              NotificationManager.success(data.message, 'success!', 2000);
              setMessage(data.message)
              nextStep();
            }
            console.log(data)

          },
          (error) => {
            setError(error.toString())
            NotificationManager.success(error.toString(), 'Error!', 2000);
          }
        )

      } else {
        NotificationManager.success('Email is required', 'Error!', 2000);
      }
    }

  };


  const validateForm = () => {

    let fields = values;
    let errors = {};
    let formIsValid = true;
    console.log(fields["first_name"].trim().length)
    if (!fields["first_name"] || fields["first_name"].trim().length <= 0) {
      formIsValid = false;
      errors["first_name"] = "*Please enter Your First Name.";
    }
    if (!fields["last_name"] || fields["last_name"].trim().length <= 0) {
      formIsValid = false;
      errors["last_name"] = "*Please enter Your Last Name.";
    }
    if (!fields["email"] || fields["email"].trim().length <= 0) {
      formIsValid = false;
      errors["email"] = "*Please enter your email-ID.";
    }
    if (typeof fields["email"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(fields["email"])) {
        formIsValid = false;
        errors["email"] = "*Please enter valid email-ID.";
      }
    }
    if (!fields["phone"]) {
      formIsValid = false;
      errors["phone"] = "*Please enter your phone.";
    }

    if (typeof fields["phone"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);
      if (!pattern.test(fields["phone"])) {
        formIsValid = false;
        errors["phone"] = "*Please enter valid phone.";
      }
    }
    if (!fields["timezone"] || fields["timezone"].trim().length <= 0) {
      formIsValid = false;
      errors["timezone"] = "*Please select timezone.";
    }
    if (!fields["highest_education_background"] || fields["highest_education_background"].trim().length <= 0) {
      formIsValid = false;
      errors["highest_education_background"] = "*Please select Highest Educational Background.";
    }
    if (!isChecked) {
      formIsValid = false;
      errors["accept_privacy_policy"] = "*Please accept privacy policy";
    }
    setError2(errors)
    return formIsValid;
  }

  const handleChange = event => {
    if (event.target.checked) {
      console.log('✅ Checkbox is checked');
    } else {
      console.log('⛔️ Checkbox is NOT checked');
    }
    handleSchool(event.target.name, event.target.checked)
    setIsChecked(current => !current);
  };


  return (
    <>
      <Header />
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="login-body" style={{ backgroundImage: 'url("images/signup-bg.jpg")', height: '100%', width: 'auto', backgroundPosition: 'center' }}>
          <Container>
            <Row>
              <Col md={12}>
                <div className="step-progress">
                  <div className="step-progress-bar">
                    <div className="step-progress-percent" style={{ width: '50%' }}></div>
                  </div>
                  <div className="step-progress-text">
                    <h6>Profile Creation & Privacy Notice</h6>
                    <h6>50% Completed</h6>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={9}>
                <div className="signup-box">
                  <div className="signup-left">
                    <h4>Add Your Details</h4>
                    <p>A college degree is not required for becoming a mentor. We welcome all talents to join our community as long as you are a great fit. </p>
                    <p>Sharing your teaching philosophy and style will help us place you in the best student matches.</p>
                    <div className="signup-step" style={{ marginTop: 260 }}>
                      <ul>
                        <li></li>
                        <li></li>
                        <li className="active"></li>
                        <li></li>
                        <li></li>
                        <li></li>
                      </ul>
                    </div>
                  </div>
                  <div className="signup-right">
                    <Form onSubmit={submitFormData}>
                      <div className="add-details">
                        <Form>
                          <Row>
                            <Col md={6}>
                              <Form.Group className="mb-3">
                                <Form.Control type="text" onChange={handleFormData("first_name")} defaultValue={values.first_name} placeholder="First Name*" />
                                <span className="err">{error2?.first_name}</span>
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group className="mb-3">
                                <Form.Control type="text" onChange={handleFormData("last_name")} defaultValue={values.last_name} placeholder="Last Name*" />
                                <span className="err">{error2?.last_name}</span>
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group className="mb-3">
                                <Form.Control type="tel" onChange={handleFormData("phone")} defaultValue={values.phone} placeholder="Mobile Number*" />
                                <span className="err">{error2?.phone}</span>
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group className="mb-3">
                                <Form.Select name="timezone" onChange={handleFormData("timezone")} defaultValue={values.timezone}>
                                  <option disabled value=''>Select Time Zone*</option>
                                  <option value="EST">EST</option>
                                  <option value="CST">CST</option>
                                  <option value="PST">PST</option>
                                </Form.Select>
                                <span className="err">{error2?.timezone}</span>
                              </Form.Group>
                            </Col>
                            <Col md={12}>
                              <Form.Group className="mb-3">
                                <Form.Control type="email" onChange={handleFormData("email")} defaultValue={values.email} placeholder="Your Email*" />
                                <span className="err">{error2?.email}</span>
                              </Form.Group>
                            </Col>
                            <Col md={12}>
                              <Form.Group className="mb-3">
                                <Form.Select onChange={handleFormData("highest_education_background")} defaultValue={values.highest_education_background}>
                                  <option disabled value=''>Highest Educational Background*</option>
                                  <option value="UG">UG</option>
                                  <option value="PG">PG</option>
                                  <option value="PHD">PHD</option>
                                </Form.Select>
                                <span className="err">{error2?.highest_education_background}</span>
                              </Form.Group>
                            </Col>
                            <Col md={12}>
                              <Form.Group className="mb-3">
                                <Form.Control as="textarea" rows={5} onChange={handleFormData("about")} defaultValue={values.about} placeholder="Tell us about yourself:
• What experience or expertise do you have?
• Do you have any certifications or awards?" />
                              </Form.Group>
                            </Col>
                            <Col md={12}>
                              <h6 className="text-muted">Privacy notice</h6>
                              <p className="text-muted">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusm tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                              </p>
                              <p className="text-muted">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
                              </p>
                              <p className="text-muted">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                              </p>
                            </Col>
                            <Col md={12}>
                              <Form.Group className="iagree mb-3" controlId="formBasicCheckbox">
                                <Form.Check className="text-grey" type="checkbox" name="accept_privacy_policy" value={isChecked} onChange={handleChange} label={`I have read & accepted the Privacy Notice as on ${formatDate()}`} />
                                <span className="err">{error2?.accept_privacy_policy}</span>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                      <div className="signup-back-btn">
                        <Link onClick={prevStep} to="#" className="btn btn-link">Back</Link>
                        <ActionButton type={'submit'} className={'btn btn-primary btn-pill px-4'} title={'Create Account'}
                          requiredFields={[values?.first_name, values.last_name, values.email, values.phone, values.timezone, values.about, values?.accept_privacy_policy ? values?.accept_privacy_policy : '']} />
                        {/* <button type="submit"  className="btn btn-secondary btn-pill px-4">Create Account</button> */}
                      </div>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <Footer />

    </>
  )
}

export default TutorSignupStepThree
