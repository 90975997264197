import React, { useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Stack,
  Modal
} from "react-bootstrap";
import Header from "../Header";
import Footer from "../Footer";
import { NavLink, Link } from "react-router-dom";
import Autocomplete from 'react-autocomplete';
import OtpInput from 'react-otp-input';
import { NotificationManager } from 'react-notifications';
import { Organization } from '../../Services/Organization';
import ActionButton from '../ActionButton';


function AdminSignupStepThree({ nextStep, handleFormData, prevStep, values }) {
  const [error, setError] = useState(false);
  const [otp, setOtp] = useState();
  const [message, setMessage] = useState(null);

  const onChange = (value) => {
    setOtp(value);
    console.log(otp)
    console.log('value ', value)
  }
  const inputfocus = (elmnt, index) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {

        elmnt.target.form.elements[next].focus()
      }
    }
    else {
      console.log("next");

      const next = elmnt.target.tabIndex;
      if (next < 6) {
        elmnt.target.form.elements[next].focus()
      }
    }

    // if (isNaN(elmnt.target.value)) return false;
    ;

    setOtp(prevState => ({
      ...prevState,
      [index]: elmnt.target.value
    }));
    console.log(otp)

  }

  const resendOtp = (e) => {
    e.preventDefault();
    if (values.email !== '') {
      Organization.sendEmail({ email: values.email }).then(
        (data) => {
          if (data.error === 0) {
            NotificationManager.success(data.message, 'success!', 2000);
          }
          console.log(data)

        },
        (error) => setError(error.toString())
      )

    } else {
      NotificationManager.error('Email is required', 'error!', 2000);
    }

  };

  // after form submit validating the form data using validator
  const submitFormData = (e) => {
    e.preventDefault();
    if (otp && otp?.length === 6) {
      Organization.verifyOtp({ otp: otp, email: values.email }).then(
        (data) => {
          if (data.error === 0) {
            NotificationManager.success(data.message, 'success!', 2000);
            setMessage(data.message)
            nextStep();
          }
          console.log(data)

        },
        (error) => {
          NotificationManager.error(error.toString(), 'error!', 2000);
          setError(error.toString())
        }
      )
    } else {
      NotificationManager.error('please enter six digit valid OTP', 'Error!', 2000);
    }
    // nextStep();
  };

  return (
    <>
      <Header />
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="login-body" style={{ backgroundImage: 'url("images/signup-bg.jpg")', height: '100%', width: 'auto', backgroundPosition: 'center' }}>
          <Container>
            <Row>
              <Col md={12}>
                <div className="step-progress">
                  <div className="step-progress-bar">
                    <div className="step-progress-percent" style={{ width: '75%' }}></div>
                  </div>
                  <div className="step-progress-text">
                    <h6>Pending Activation</h6>
                    <h6>75% Completed</h6>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={9}>
                <div className="signup-box">
                  <div className="signup-left">
                    <h4>Verify Your Account</h4>
                    <p>Lorem ipsum dolor sit, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <div className="signup-step">
                      <ul>
                        <li></li>
                        <li></li>
                        <li className="active"></li>
                        <li></li>
                      </ul>
                    </div>
                  </div>
                  <div className="signup-right">
                    <Form onSubmit={submitFormData}>
                      {/* <div className="d-block alert alert-danger d-flex align-items-center" role="alert">
                          <img src="/images/cross.png" alt="" width="20"/>
                          <div className="ms-2">
                            Code entered is incorrect. Please enter again or request a new code 
                          </div>
                        </div> */}
                      <div className="verify-account">
                        <img src="images/lock-icon.png" alt="" width="76" />
                        <h4 className="my-3">Enter The Code</h4>
                        <p>
                          Protecting your account is our top priority. An email with the verification code <br />
                          has been sent. Please confirm your account by entering the authorization<br /> code sent to the email registered with us.
                        </p>
                        <div className="new-otp-field">
                          <OtpInput
                            value={otp}
                            containerStyle="otp-input"
                            inputStyle="form-control"
                            onChange={onChange}
                            numInputs={6}
                            isInputNum={true}
                          />
                        </div>
                        <p>
                          It may take a minute to receive your code.<br />
                          Haven’t received it? Resend a new code <Link to="#" onClick={resendOtp}>Resend a new code</Link>
                        </p>
                      </div>
                      <div className="signup-back-btn">
                        <Link onClick={prevStep} to="#" className="btn btn-link">Back</Link>
                        <ActionButton type={'submit'} className={'btn btn-primary btn-pill px-4'} title={'Continue'}
                          requiredFields={[otp?.length == 6 ? otp : '']} />
                        {/* <button type="submit" className="btn btn-secondary btn-pill px-4">Create Account</button> */}
                      </div>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <Footer />

    </>
  )
}

export default AdminSignupStepThree
