import config from "./../config";

export const Organization = {
    getCategory,
    sendEmail,
    verifyOtp,
    registerCompany,
    getCompanyUser
};

function getCategory(reqData) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqData),
    };
    return fetch(`${config.apiUrl}company/get_all_company`, requestOptions)
      .then(handleResponse)
      .then((data) => {
        return data;
      });
  }

  function sendEmail(reqData) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqData),
    };
    return fetch(`${config.apiUrl}account_otp_verification_send_otp`, requestOptions)
      .then(handleResponse)
      .then((data) => {
        return data;
      });
  }
  
  function verifyOtp(reqData) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqData),
    };
    return fetch(`${config.apiUrl}account_otp_verify`, requestOptions)
      .then(handleResponse)
      .then((data) => {
        return data;
      });
  }

  function registerCompany(reqData) {
    const requestOptions = {
      method: "POST",
    };
    if(reqData) {
     // requestOptions.headers['Content-Type'] = 'multipart/form-data';
      requestOptions.body = reqData;
     }
    return fetch(`${config.apiUrl}company_user/register`, requestOptions)
      .then(handleResponse)
      .then((data) => {
        return data;
      });
  }
  function getCompanyUser(reqData) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqData),
    };
    return fetch(`${config.apiUrl}company_user/get_company_wise_user`, requestOptions)
      .then(handleResponse)
      .then((data) => {
        return data;
      });
  }

  function handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      const error = (data && data.message) || response.message;
      if(error =='Auth failed'){
        return Promise.reject(error); 
      }else{
        if (data && data.error === 1 ) {
            return Promise.reject(error);   
        }
      }
      return data;
    });
  }