import _ from "lodash"
import { NotificationManager } from "react-notifications";

export default function FieldErrors(props) {
    let errors = props.errors
    if (errors != null) {
        return (
            <>
                {_.map(errors, message => {
                    return < div >{message.context.key == props.field ? <p className="text-danger">{message.message}</p> : ""}</div >
                    //  NotificationManager.error(message.message, 'error!', 2000);
                })
                }
            </>
        )
    }
    else {
        return <></>
    }



}