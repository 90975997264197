import React,{useState,Fragment } from "react";
import { Redirect , Outlet,Route } from "react-router-dom";
import { useUserRoles } from "./useUserRoles";

export function RolesAuthRoute({ children, ...rest }) {
    
      const canAccess = rest.roles.some(userRole => localStorage.getItem("user_type") === userRole)
      console.log(canAccess)

    return (
      <Route
        {...rest}
        render={({ location }) =>
        canAccess ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: location }
              }}
            />
          )
        }
      />
    );
  }