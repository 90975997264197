import React, { useState } from 'react'
import {
  Container,
  Modal,
  Col,
  Button,
  Form,
  FormControl,
  Offcanvas,
} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";

function SchoolInternalSideMenu() {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  return (
    <>
      <div className="left">
        <div className="dash-logo">
          <Link to="/organization-dashboard">
            <img src="images/logo.png" alt="" width="220" />
          </Link>
          <a href="#" onClick={handleShow} className="burger-menu">
            <img src="images/menu.png" alt="" width="60" />
          </a>
          <Offcanvas show={show} onHide={handleClose}>
            {/* <Offcanvas.Header closeButton>
              <Offcanvas.Title>DASYA</Offcanvas.Title>
            </Offcanvas.Header> */}
            <Offcanvas.Body>
              <div className="user-short-text user-short-text-mobile">
                <div className="notification-bell">
                  <img src="images/bell-icon.png" alt="" width="32" />
                  <div className="noti-dot"></div>
                </div>
                <div className="user-short-profile">
                  <div className="user-avatar">
                    <img src="images/avatar2.png" alt="" />
                  </div>
                  <div className="user-short-name">
                    <h5>Jane Doe | ID: 567876</h5>
                    <p>Academic Year 2021-2022</p>
                  </div>
                  <div className="drop-down">
                    <img src="images/drop-icon.png" alt="" width="10" />
                  </div>
                </div>
              </div>
              <div className="dash-menu mobile-side-menu">
                <ul>
                  <li>
                    <NavLink activeClassName='' to="/School-Internal-Dashboard"><span style={{ backgroundImage: 'url("images/dash-icon1.png")' }}></span> Schools</NavLink >
                  </li>
                  <li>
                    <NavLink activeClassName='' to="/"><span style={{ backgroundImage: 'url("images/dash-icon3.png")' }}></span> Organizations</NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName='' to="/"><span style={{ backgroundImage: 'url("images/dash-icon4.png")' }}></span> Authorizations</NavLink>
                  </li>
                  <li aria-current="page" className="active">
                    <NavLink activeClassName='active' to="/School-Internal-Profile-Setting"><span style={{ backgroundImage: 'url("images/dash-icon5.png")' }}></span> Profile Setting</NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName='' to="/"><span style={{ backgroundImage: 'url("images/dash-icon6.png")' }}></span> Audit Log</NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName='' to="/"><span style={{ backgroundImage: 'url("images/dash-icon6.png")' }}></span> Statistics</NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName='' to="/"><span style={{ backgroundImage: 'url("images/dash-icon6.png")' }}></span> Resources</NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName='' to="/"><span style={{ backgroundImage: 'url("images/dash-icon6.png")' }}></span> Settings</NavLink>
                  </li>
                </ul>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
        <div className="mobile-toggle">
          <img src="images/menu.png" alt="" width="40" />
        </div>
        <div className="dash-menu">
          <ul>
            <li>
              <NavLink activeClassName='' to="/School-Internal-Dashboard"><span style={{ backgroundImage: 'url("images/dash-icon7.png")' }}></span> Schools</NavLink >
            </li>
            <li>
              <NavLink activeClassName='' to="/"><span style={{ backgroundImage: 'url("images/dash-icon8.png")' }}></span> Organizations</NavLink>
            </li>
            <li>
              <NavLink activeClassName='' to="/"><span style={{ backgroundImage: 'url("images/dash-icon9.png")' }}></span> Authorizations</NavLink>
            </li>
            <li >
              <NavLink aria-current="page" className="active" activeClassName='active' to="/School-Internal-Profile-Setting"><span style={{ backgroundImage: 'url("images/dash-icon11.png")' }}></span> Profile Setting</NavLink>
            </li>
            <li>
              <NavLink activeClassName='' to="/"><span style={{ backgroundImage: 'url("images/dash-icon10.png")' }}></span> Audit Log</NavLink>
            </li>
            <li>
              <NavLink activeClassName='' to="/"><span style={{ backgroundImage: 'url("images/dash-icon4.png")' }}></span> Statistics</NavLink>
            </li>
            <li>
              <NavLink activeClassName='' to="/"><span style={{ backgroundImage: 'url("images/dash-icon5.png")' }}></span> Resources</NavLink>
            </li>
            <li>
              <NavLink activeClassName='' to="/"><span style={{ backgroundImage: 'url("images/dash-icon6.png")' }}></span> Settings</NavLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default SchoolInternalSideMenu
