import React, { useState, useEffect } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Stack,
  Modal
} from "react-bootstrap";
import Header from "../Header";
import Footer from "../Footer";
import { NavLink, Link } from "react-router-dom";
import Autocomplete from 'react-autocomplete'
import { Student } from '../../Services/Student';
import { NotificationManager } from 'react-notifications';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function SignupStepThreeA({ nextStep, prevStep, handleFormData, handleContactBy, handleSchool, values }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [value, setValue] = useState('');
  const [validated, setValidated] = useState(false);
  const history = useHistory()

  const next = () => {
    history.push('/');
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    let formData = new FormData();
    Object.entries(values).map(([key, value]) => {
      formData.append(key, value)
    })
    Student.post(formData, 'student/register').then(
      (data) => {
        if (data.error === 0) {
          NotificationManager.success(data.message, 'success!', 2000);
          // setMessage(data.message)
          localStorage.setItem('student', JSON.stringify(data.data))
          // nextStep();
          setShow(true)
        }
        console.log(data)

      },
      (error) => {
        NotificationManager.error(error.toString(), 'error!', 2000);
        // setError(error.toString())
      }
    )
    setValidated(true);
  };

  return (
    <>
      <Header />
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="login-body" style={{ backgroundImage: 'url("images/signup-bg.jpg")' }}>
          <Container>
            <Row>
              <Col md={12}>
                <div className="step-progress">
                  <div className="step-progress-bar">
                    <div className="step-progress-percent" style={{ width: '50%' }}></div>
                  </div>
                  <div className="step-progress-text">
                    <h6>Parental Consent</h6>
                    <h6>50% Completed</h6>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={9}>
                <div className="signup-box">
                  <div className="signup-left">
                    <h4>Add Your Details</h4>
                    <p>Now that you have obtained parental consent, go ahead and confirm your details.</p>
                    <div className="signup-step">
                      <ul>
                        <li></li>
                        <li></li>
                        <li className="active"></li>
                        <li></li>
                        <li></li>
                        <li></li>
                      </ul>
                    </div>
                  </div>
                  <div className="signup-right">
                    <div className="add-details">
                      <Form>
                        <Row>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Control type="text" placeholder="John" disabled />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Control type="text" placeholder="Doe" disabled />
                            </Form.Group>
                          </Col>
                          <Col md={12}>
                            <Form.Group className="mb-3">
                              <Form.Control type="tel" placeholder="Mobile Number*" />
                            </Form.Group>
                          </Col>
                          <Col md={12}>
                            <Form.Group className="mb-3">
                              <Form.Control type="email" placeholder="mather@school.com" disabled />
                            </Form.Group>
                          </Col>
                          <Col md={12}>
                            <Form.Group className="mb-3">
                              <Form.Control type="email" placeholder="sarah.doe@gmail.com" disabled />
                            </Form.Group>
                          </Col>
                          <Col md={12}>
                            <Form.Group className="mb-3">
                              <Form.Select disabled>
                                <option>Do You Require A Facilitator?*</option>
                                <option value="yes" selected>Yes</option>
                                <option value="no">No</option>
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col md={12}>
                            <h6 className="text-muted">Privacy notice</h6>
                            <p className="text-muted">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusm tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </p>
                            <p className="text-muted">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
                            </p>
                            <p className="text-muted">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </p>
                          </Col>
                          <Col md={12}>
                            <Form.Group className="iagree mb-3" controlId="formBasicCheckbox">
                              <Form.Check className="text-grey" type="checkbox" label="I have read & accepted the Privacy Notice as on 08/21/2021" />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                    <div className="signup-back-btn">
                      <Link to="/signup-step3" href="#" className="btn btn-link">Back</Link>
                      <button onClick={handleSubmit} className="btn btn-primary btn-pill px-4">Continue</button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <Footer />
      <Modal show={show} onHide={handleClose}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Consent Form Sent!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center my-4">
            <img src="images/mail-icon.png" alt="" width="110" />
          </div>
          <p className="modal-text text-center">
            We sent an email to doejohn@gmail.com to obtain parental consent.  Kindly ask your parent/guardian to sign the consent form in order to complete your registration process.
          </p>
          <div className="text-center">
            <Button variant="primary" onClick={handleClose} className="px-5">
              Okay
            </Button>
          </div>
        </Modal.Body>
      </Modal>

    </>
  )
}

export default SignupStepThreeA
