import React, { useEffect, useState } from 'react'
import {
  Col,
  Row,
  Form,
} from "react-bootstrap";
import ParSideMenu from "./ParSideMenu";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { auto } from '@popperjs/core';
import { FecthData } from '../../Services/FetchData';
import Logout from '../logout';
import _ from 'lodash';
import GraphUI from '../common/GraphUI';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom'
    },
    title: {
      display: true,
      text: '',
    },
  },
};

const labels = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

const data = {
  labels,
  datasets: [
    {
      label: 'Algebra',
      data: {
        MONDAY: 0,
        TUESDAY: 0,
        WEDNESDAY: 0,
        THURSDAY: 0,
        FRIDAY: 0,
        SATURDAY: 0,
        SUNDAY: 0,
      },
      backgroundColor: [
        '#914CE9',
      ],
    },
    {
      label: 'Science',
      data: {
        MONDAY: 0,
        TUESDAY: 0,
        WEDNESDAY: 0,
        THURSDAY: 0,
        FRIDAY: 0,
        SATURDAY: 0,
        SUNDAY: 0,
      },
      backgroundColor: [
        '#FEB41C',
      ],
    },
    {
      label: 'Physics',
      data: {
        MONDAY: 0,
        TUESDAY: 0,
        WEDNESDAY: 0,
        THURSDAY: 0,
        FRIDAY: 0,
        SATURDAY: 0,
        SUNDAY: 0,
      },
      backgroundColor: [
        '#D0202C',
      ],
    },
    {
      label: 'Geometry',
      data: {
        MONDAY: 0,
        TUESDAY: 0,
        WEDNESDAY: 0,
        THURSDAY: 0,
        FRIDAY: 0,
        SATURDAY: 0,
        SUNDAY: 0,
      },
      backgroundColor: [
        '#73913E',
      ],
    },
  ],
};

ChartJS.register(Tooltip, Legend);

function ParDashboard() {
  const user = JSON.parse(localStorage.getItem('user'))
  const [students, setStudents] = useState([])
  const [state, setState] = useState(data)
  const [statistics, setStatistics] = useState()
  const colorObj = {
    algebra: '#914CE9',
    science: '#FEB41C',
    physics: '#D0202C',
    geometry: '#73913E'

  }

  const getAllStudents = () => {
    FecthData.postJsonData({ parent_email: user.email }, 'student/get_all_child_by_parent_email').then(
      (data) => setStudents(data?.data)
    )
  }
  const getStudentStatistics = (student_id) => {
    FecthData.getData('student/get_student_progress_count/' + student_id).then(
      (data) => {
        setStatistics(data?.data)
        console.log(data?.data)
      }
    )
  }
  const getStudentProgress = (student_id) => {
    FecthData.getData('student/get_student_progress/' + student_id).then(
      (data) => {
        let arr = _.groupBy(data?.data, 'subject_name')
        let arr1 = []
        data?.data.map(pr => {
          console.log(pr)
          var obj = {
            label: pr?._id?.subject_name,
            data: {
              MONDAY: new Date(pr?._id?.date).getDay() == 1 ? pr.total_hours : 0,
              TUESDAY: new Date(pr?._id?.date).getDay() == 2 ? pr.total_hours : 0,
              WEDNESDAY: new Date(pr?._id?.date).getDay() == 3 ? pr.total_hours : 0,
              THURSDAY: new Date(pr?._id?.date).getDay() == 4 ? pr.total_hours : 0,
              FRIDAY: new Date(pr?._id?.date).getDay() == 5 ? pr.total_hours : 0,
              SATURDAY: new Date(pr?._id?.date).getDay() == 6 ? pr.total_hours : 0,
              SUNDAY: new Date(pr?._id?.date).getDay() == 7 ? pr.total_hours : 0,
            },
            backgroundColor: [
              colorObj[`${pr?._id?.subject_name}`]
            ],
          }
          arr1.push(obj)
        })
        const obj = {
          labels,
          datasets: arr1
        }
        console.log(obj)
        setState(obj)
      }
    )
    getStudentStatistics(student_id)
  }
  useEffect(() => {
    getAllStudents()
  }, [])
  const giveParentalConsent = (student_id) => {
    FecthData.postJsonData({ student_id: student_id, parental_consent: true }, 'guardian/parent_consents').then(
      (data) => getAllStudents()
    )
  }
  console.log(user.name)
  return (
    <>
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="dashboard-wrap">
          <ParSideMenu />
          <div className="middle">
            <Row>
              {/* <Col md={4}>
                <div className="card bg-purple progress-box">
                  <div className="card-text text-white">
                    <img src="images/hat-icon.png" alt="" width="70" />
                    <h4>Scheduled Classes</h4>
                  </div>
                  <div className="card-big-text">
                    <h5 className="count">15</h5>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="card bg-danger progress-box">
                  <div className="card-text text-white">
                    <img src="images/book-icon.png" alt="" width="58" />
                    <h4>Assignments Completed</h4>
                  </div>
                  <div className="card-big-text">
                    <h5 className="count">07</h5>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="card bg-success progress-box">
                  <div className="card-text text-white">
                    <img src="images/timer-icon.png" alt="" width="34" />
                    <h4>Time SPENT On Dasya</h4>
                  </div>
                  <div className="card-big-text">
                    <h5 className="count">14 <small>HRS</small></h5>
                  </div>
                </div>
              </Col> */}
              <GraphUI options={options} state={state} statistics={statistics} />
              {/* <Col md={12}>
                <div className="card">
                  <h5 className="sub-heading mt-4 text-muted d-flex justify-content-between">No. Of Hrs Spent Subject Wise
                    <Form.Group style={{ minWidth: 120 }}>
                      <Form.Select>
                        <option>Weekly</option>
                        <option>Monthly</option>
                      </Form.Select>
                    </Form.Group>
                  </h5>
                  <Bar options={options} data={data} />
                </div>
              </Col>
              <Col md={4}>
                <div className="card">
                  <h5 className="sub-heading text-muted">Current Level</h5>
                  <img src="images/progress-icon.png" alt="" width="150" style={{ margin: auto, marginTop: 10, }} />
                </div>
              </Col>
              <Col md={8}>
                <div className="card">
                  <h5 className="sub-heading text-muted d-flex justify-content-between">Badges & Rewards <span><a className=" text-muted" href="/teacher-review">See All</a></span></h5>
                  <div className="badges">
                    <ul>
                      <li>
                        <img src="images/badge1.png" alt="" width="80" />
                        <h6>SCHEDULE 5+ Sessions</h6>
                      </li>
                      <li>
                        <img src="images/badge2.png" alt="" width="50" />
                        <h6>CHAT WITH TUTOR</h6>
                      </li>
                      <li>
                        <img src="images/badge3.png" alt="" width="64" />
                        <h6>REACH A NEW LEVEL</h6>
                      </li>
                      <li>
                        <img src="images/badge4.png" alt="" width="104" />
                        <h6>COMPLETE AN ASSIGNMENT</h6>
                      </li>
                      <li>
                        <img src="images/badge5.png" alt="" width="58" />
                        <h6>EARN TUTOR BADGE</h6>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col> */}
            </Row>
          </div>
          <div className="right">
            <div className="user-added">
              <div className="user-short-text">
                <div className="notification-bell">
                  <img src="images/bell-icon.png" alt="" width="32" />
                  <div className="noti-dot"></div>
                </div>
                <div className="user-short-profile">
                  <div className="user-avatar">
                    <img src="images/no_profile_img.png" width={'100%'} height={'100%'} alt="" />
                  </div>
                  <div className="user-short-name">
                    <h5>{user.name} </h5>
                    <p>Academic Year 2021-2022</p>
                  </div>
                  <Logout url={'/parents'} />
                  {/* <div className="drop-down">
                    <img src="images/drop-icon.png" alt="" width="10" />
                  </div> */}
                </div>
              </div>
              {students.map(student => <div className="user-short-text-two" onClick={() => getStudentProgress(student._id)}>
                <div className="user-avatar">
                  <img src="images/avatar3.png" alt="" />
                </div>
                <div className="user-short-name">
                  <h5>{student.first_name} | ID: {student.student_code}</h5>
                  <p>Academic Year {student.academic_year}</p>
                </div>
                {!student.parental_consent ? <button className='btn btn-primary py-1 px-1' onClick={() => giveParentalConsent(student._id)}>consent</button> : null}
                {/* <div className="drop-down">
                  <img src="images/drop-icon.png" alt="" width="10" />
                </div> */}
              </div>)}
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default ParDashboard
