import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import AdminLayout from "./Dashboard/AdminLayout";
import { Organization } from "../../Services/Organization";
import { Link } from "react-router-dom";
import { FecthData } from "../../Services/FetchData";

export default function AdminOrganization() {
  const [company, setCompany] = useState(null)
  const [selectedCompany, selectCompany] = useState()
  const [companyUser, setCompanyUser] = useState()
  const [error, setError] = useState(null)
  const fetchCompanies = () => {
    Organization.getCategory({ is_registered: true }).then(
      (data) => {
        setCompany(data?.data)
        // selectCompany(data?.data[0])
      },
      (error) => setError(error.toString())
    )
  }
  useEffect(() => {
    fetchCompanies()
  }, [])
  const fetchCompanyUser = () => {
    if (selectedCompany) {
      FecthData.getData('company_user/get_company_user_by_company_id/' + selectedCompany._id).then(
        (data) => setCompanyUser(data?.data)
      )
    }
  }
  useEffect(() => {
    fetchCompanyUser()
  }, [selectedCompany])
  return <AdminLayout company={selectedCompany} refreshCard={fetchCompanyUser} companyUser={companyUser} userDetailPath='/admin/company/user/' showUserCard={selectedCompany}>
    <Row>
      <Col md={12}>
        <Form.Group className="form-group mb-2">
          <Form.Control type="text" placeholder="Search" className="search-input" />
          <Button variant="primary" type="submit" className="search-btn">
            <img src="images/search-icon.png" alt="" width="22" />
          </Button>
        </Form.Group>
      </Col>
      <Col md={6} className="mt-4">
        <h3 className="text-muted mb-0">Companies</h3>
      </Col>
      <Col md={6} className="mt-4">
        <div className="filters">
          <ul>
            <li>
              <a href="javascript:void(0)">Sort <img src="images/sort-icon.png" alt="" width="17" />
              </a>
            </li>
            <li>
              <a href="javascript:void(0)">Filter <img src="images/filter-icon.png" alt="" width="13" />
              </a>
            </li>
            {/* <li onClick={handleShow}>
                <a href="javascript:void(0)">Add <img src="images/add-icon.png" alt="" width="15" />
                </a>
              </li> */}
          </ul>
        </div>
      </Col>
      {company && company?.length > 0 && company?.map((content, index) => (
        <Col md={3} key={index} onClick={() => selectCompany(content)}>
          <div className="school-box">
            <Link>
              <div className="school-box-logo">
                <img src="images/school-logo3.png" alt="" />
              </div>
              <h4>{content.company_name}</h4>
            </Link>
          </div>
        </Col>
      ))}
      {!company && <Col md={12} >No company Found</Col>}
    </Row>

  </AdminLayout>
}