import { Button } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";


function Video(props) {
    var callWindow= window.Daily
    
    const startCall = () => {
        var callFrame = callWindow.createFrame({
            showLeaveButton: true,
            showFullscreenButton: true,
            layoutConfig: {
                grid: {
                  minTilesPerPage: 3, // default: 1, minimum required: 1
                  maxTilesPerPage: 36, // default: 25, maximum allowable: 49
                },
              },
            //   onLeave: endCall
            // iframeStyle: {
            //   position: 'fixed',
            //   top: '0',
            //   left: '0',
            //   width: '100%',
            //   height: '100%',
            // },
            // enableKnocking: props.role=="tutor"?true:false
            // showShareButton : false
        });




        if(window.Daily.getCallInstance()!=null){
            console.log("in if ",window.Daily.getCallInstance())
            callFrame = window.Daily.getCallInstance()
          
            callFrame.join({ url: props.session.room_url });
            callFrame.on("left-meeting", () => {
                callFrame.destroy();
            });

        }else{
            console.log("in else ",window.Daily.getCallInstance())
            callFrame.join({ url: props.session.room_url });
            callFrame.on("left-meeting", () => {
                callFrame.destroy();
            });
        }
        
    }
    const endCall =() =>{
        console.log(window.Daily.getCallInstance())
        if(props.role=="tutor")
        window.Daily.getCallInstance().destroy()
    }


    return <Link
    // to="/tutor-join-class"
    to="#"
     onClick={startCall} className="btn btn-success px-5 mt-3">Start Class Now</Link>


}

export default Video;