

export default {
  // apiUrl: "https://dasya.myvtd.site:6466/",
  // imgBaseUrl: "https://dasya.myvtd.site/uploads/",
  // baseUrl: "https://dasya.myvtd.site:6466/",

  //   apiUrl: "http://localhost:3000/",
  //   imgBaseUrl: "https://dasya.myvtd.site/uploads/",
  //  baseUrl: "http://localhost:3000/",

  apiUrl: process.env.REACT_APP_API_URL,
  imgBaseUrl: process.env.REACT_APP_API_URL + "uploads/",
  baseUrl: process.env.REACT_APP_API_URL,

  // apiUrl: 'http://54.226.146.52:3001/',
  // imgBaseUrl: 'http://54.226.146.52:3001/uploads/',
  // baseUrl: "http://54.226.146.52:3001/",

  // apiUrl: 'http://34.200.165.69:3001/',
  // imgBaseUrl: 'http://34.200.165.69:3001/uploads/',
  // baseUrl: "http://34.200.165.69:3001/",

  FB_APP_ID: process.env.REACT_APP_FB_APP_ID,
  GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID
};
