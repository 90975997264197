import React, { useEffect, useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Modal,
  Dropdown,
} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import SchoolSideMenu from "./SchoolSideMenu";
import { School } from '../../Services/School';
import { FecthData } from '../../Services/FetchData';
import { Student } from '../../Services/Student';
import { Admin } from '../../Services/Admin';
import config from '../../config';
import { NotificationManager } from 'react-notifications';
import FieldErrors from '../../Services/FieldErrors';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ShortUserInfo from './ShortUserInfo';

function SchoolResources() {
  const user = JSON.parse(localStorage.getItem('user'))
  let history = useHistory()

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [modal, setModal] = useState(false);
  const handleClose1 = () => setModal(false);
  const handleShow1 = () => setModal(true);

  const [conf, setConf] = useState(false);
  const handleClose2 = () => setConf(false);
  const handleShow2 = () => setConf(true);

  const [state, setState] = useState([]);
  const [grade, setGrade] = useState([]);
  const [searchCat, setSearchCat] = useState('');
  const [subject, setSubject] = useState([]);
  const [selectedTab, setSelectedTab] = useState(2);
  const [error, setError] = useState()
  const [formData, setFormData] = useState({
    user_id: user?._id,
    logo: null,
    title: '',
    grade_id: null,
    subject_id: null,
    description: '',
    category: 'E-Books',
    audience: [],
    link: '',
    file: null
  })

  const onChangeTabs = (searchCat, tabId) => {
    setSearchCat(searchCat)
    setSelectedTab(tabId)
  }
  const submitForm = () => {
    const payload = new FormData()
    Object.entries(formData).map(([key, value]) => {
      payload.append(key, value)
    })
    Admin.postFormData(payload, 'users/create_resources').then(
      (data) => {
        if (data?.data) {
          NotificationManager.success(data?.message, 'Success!', 2000);
        } else {
          setError(data.errors.details)
          NotificationManager.error(data?.message, 'error!', 2000);
        }
      }
    )
  }
  useEffect(() => {
    Student.post({ page: 0 }, 'grades/get_all_grade').then(
      (data) => {
        setGrade(data?.data)
        setFormData({ ...formData, grade_id: data?.data[0]?._id })
      },
      // (error) => setError1(error.toString())
    );
    School.post({}, 'streem_and_subject/get_all_subject').then(
      (data) => {
        if (data.error === 0) {
          setSubject(data?.data)
          setFormData({ ...formData, subject_id: data?.data[0]?._id })
        }
      },
      // (error) => {
      //   NotificationManager.error(error.toString(), 'error!', 2000);
      //   setError(error.toString())
      // }
    )
  }, [])
  useEffect(() => {
    FecthData.getData('users/get_resources?category=' + searchCat + '&role=' + user?.role).then(
      (data) => setState(data.data)
    )
  }, [searchCat])
  const logout = () => {
    localStorage.clear()
    history.push('/school')
  }
  return (
    <>
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="dashboard-wrap">
          <SchoolSideMenu />
          <div className="middle">
            <Row>
              <Col md={12}>
                <Form.Group className="form-group mb-2">
                  <Form.Control type="text" placeholder="Search" className="search-input" />
                  <Button variant="primary" type="submit" className="search-btn">
                    <img src="images/search-icon.png" alt="" width="22" />
                  </Button>
                </Form.Group>
                <div className="mt-4">
                  <h6 className="text-muted mt-3">K12 Lesson Plan & Curriculum</h6>
                </div>
              </Col>
              <Col md={4}>
                <div className="dash-box">
                  <img src="images/dash-box1.jpg" alt="" />
                  <div className="dash-box-text">
                    <h4>Functions & Graphing</h4>
                    <p>1:30 min</p>
                  </div>
                  <a href="javascript:void(0)" className="watch-btn-option">
                    <img src="images/three-dot.png" alt="" width="20" />
                  </a>
                </div>
              </Col>
              <Col md={4}>
                <div className="dash-box">
                  <img src="images/dash-box2.jpg" alt="" />
                  <div className="dash-box-text">
                    <h4>Expressions & Equations</h4>
                    <p>1:00 min</p>
                  </div>
                  <a href="javascript:void(0)" className="watch-btn-option">
                    <img src="images/three-dot.png" alt="" width="20" />
                  </a>
                </div>
              </Col>
              <Col md={4}>
                <div className="dash-box">
                  <img src="images/dash-box3.jpg" alt="" />
                  <div className="dash-box-text">
                    <h4>Statistics & Probability</h4>
                    <p>1:30 min</p>
                  </div>
                  <a href="javascript:void(0)" className="watch-btn-option">
                    <img src="images/three-dot.png" alt="" width="20" />
                  </a>
                </div>
              </Col>
              <Col md={12}>
                <div className="video-category">
                  <ul>
                    {/* <li className={selectedTab == 1 ? "active" : ''} onClick={() => onChangeTabs('', 1)}>
                      <a href="javascript:void(0)">Upload Request</a>
                    </li> */}
                    <li className={selectedTab == 2 ? "active" : ''} onClick={() => onChangeTabs('E-Books', 2)}>
                      <a href="javascript:void(0)" eventKey="first">E-Books</a>
                      {/* <Link ></Link> */}
                    </li>
                    <li className={selectedTab == 3 ? "active" : ''} onClick={() => onChangeTabs('Teaching Material', 3)}>
                      <a href="javascript:void(0)">Teaching Material</a>
                    </li>
                    <li className={selectedTab == 4 ? "active" : ''} onClick={() => onChangeTabs('Archived Classed', 4)}>
                      <a href="javascript:void(0)">Archived Classed</a>
                    </li>
                    <li className={selectedTab == 5 ? "active" : ''} onClick={() => onChangeTabs('Training', 5)}>
                      <a href="javascript:void(0)">Training</a>
                    </li>
                    <li className={selectedTab == 6 ? "active" : ''} onClick={() => onChangeTabs('Favorites', 6)}>
                      <a href="javascript:void(0)">Favorites</a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={12}>
                <h6 className="text-muted mt-2">Featured Videos</h6>
              </Col>
              {
                state.map(item => <Col md={3}>
                  <div className="video-box">
                    <img src={item.image ? config.imgBaseUrl + item.image : "images/video1.jpg"} alt="" />
                    <div className="video-box-text">
                      <h4>{item.title}</h4>
                      <p>1:30 min</p>
                    </div>
                    <a href={item.file ? config.imgBaseUrl + 'resources/' + item.file : 'javascript:void(0)'}
                      target={item.file ? "_blank" : null} className="watch-btn">
                      <img src="images/play-icon.png" alt="" />
                    </a>
                    <a href="javascript:void(0)" className="watch-btn-option">
                      <img src="images/three-dot.png" alt="" width="20" />
                    </a>
                    {/* <Dropdown className="watch-btn-option">
                      <Dropdown.Toggle>
                        <img src="images/three-dot.png" alt="" width="20" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={setModal} >Edit</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={setConf}>Delete</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown> */}
                  </div>
                </Col>)
              }
              {/* <Col md={3}>
                <div className="video-box">
                  <img src="images/video1.jpg" alt="" />
                  <div className="video-box-text">
                    <h4>Functions & Graphing</h4>
                    <p>1:30 min</p>
                  </div>
                  <a href="javascript:void(0)" className="watch-btn">
                    <img src="images/play-icon.png" alt="" />
                  </a>
                  <a href="javascript:void(0)" className="watch-btn-option">
                    <img src="images/three-dot.png" alt="" width="20" />
                  </a>
                </div>
              </Col>
              <Col md={3}>
                <div className="video-box">
                  <img src="images/video2.jpg" alt="" />
                  <div className="video-box-text">
                    <h4>Functions & Graphing</h4>
                    <p>1:30 min</p>
                  </div>
                  <a href="javascript:void(0)" className="watch-btn">
                    <img src="images/play-icon.png" alt="" />
                  </a>
                  <a href="javascript:void(0)" className="watch-btn-option">
                    <img src="images/three-dot.png" alt="" width="20" />
                  </a>
                </div>
              </Col>
              <Col md={3}>
                <div className="video-box">
                  <img src="images/video3.jpg" alt="" />
                  <div className="video-box-text">
                    <h4>Functions & Graphing</h4>
                    <p>1:30 min</p>
                  </div>
                  <a href="javascript:void(0)" className="watch-btn">
                    <img src="images/play-icon.png" alt="" />
                  </a>
                  <a href="javascript:void(0)" className="watch-btn-option">
                    <img src="images/three-dot.png" alt="" width="20" />
                  </a>
                </div>
              </Col>
              <Col md={3}>
                <div className="video-box">
                  <img src="images/video4.jpg" alt="" />
                  <div className="video-box-text">
                    <h4>Functions & Graphing</h4>
                    <p>1:30 min</p>
                  </div>
                  <a href="javascript:void(0)" className="watch-btn">
                    <img src="images/play-icon.png" alt="" />
                  </a>
                  <a href="javascript:void(0)" className="watch-btn-option">
                    <img src="images/three-dot.png" alt="" width="20" />
                  </a>
                </div>
              </Col> */}
              <Col md={12}>
                <h6 className="text-muted mt-2">Most Viewed</h6>
              </Col>
              {
                state.map(item => <Col md={3}>
                  <div className="video-box">
                    <img src={item.image ? config.imgBaseUrl + item.image : "images/video1.jpg"} alt="" />
                    <div className="video-box-text">
                      <h4>{item.title}</h4>
                      <p>1:30 min</p>
                    </div>
                    <a href={item.file ? config.imgBaseUrl + 'resources/' + item.file : 'javascript:void(0)'}
                      target={item.file ? "_blank" : null} className="watch-btn">
                      <img src="images/play-icon.png" alt="" />
                    </a>
                    <a href="javascript:void(0)" className="watch-btn-option">
                      <img src="images/three-dot.png" alt="" width="20" />
                    </a>
                    {/* <Dropdown className="watch-btn-option">
                      <Dropdown.Toggle>
                        <img src="images/three-dot.png" alt="" width="20" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={setModal} >Edit</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={setConf}>Delete</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown> */}
                  </div>
                </Col>)
              }
              {/* <Col md={3}>
                <div className="video-box">
                  <img src="images/video1.jpg" alt="" />
                  <div className="video-box-text">
                    <h4>Functions & Graphing</h4>
                    <p>1:30 min</p>
                  </div>
                  <a href="javascript:void(0)" className="watch-btn">
                    <img src="images/play-icon.png" alt="" />
                  </a>
                  <a href="javascript:void(0)" className="watch-btn-option">
                    <img src="images/three-dot.png" alt="" width="20" />
                  </a>
                </div>
              </Col>
              <Col md={3}>
                <div className="video-box">
                  <img src="images/video2.jpg" alt="" />
                  <div className="video-box-text">
                    <h4>Functions & Graphing</h4>
                    <p>1:30 min</p>
                  </div>
                  <a href="javascript:void(0)" className="watch-btn">
                    <img src="images/play-icon.png" alt="" />
                  </a>
                  <a href="javascript:void(0)" className="watch-btn-option">
                    <img src="images/three-dot.png" alt="" width="20" />
                  </a>
                </div>
              </Col>
              <Col md={3}>
                <div className="video-box">
                  <img src="images/video3.jpg" alt="" />
                  <div className="video-box-text">
                    <h4>Functions & Graphing</h4>
                    <p>1:30 min</p>
                  </div>
                  <a href="javascript:void(0)" className="watch-btn">
                    <img src="images/play-icon.png" alt="" />
                  </a>
                  <a href="javascript:void(0)" className="watch-btn-option">
                    <img src="images/three-dot.png" alt="" width="20" />
                  </a>
                </div>
              </Col>
              <Col md={3}>
                <div className="video-box">
                  <img src="images/video4.jpg" alt="" />
                  <div className="video-box-text">
                    <h4>Functions & Graphing</h4>
                    <p>1:30 min</p>
                  </div>
                  <a href="javascript:void(0)" className="watch-btn">
                    <img src="images/play-icon.png" alt="" />
                  </a>
                  <a href="javascript:void(0)" className="watch-btn-option">
                    <img src="images/three-dot.png" alt="" width="20" />
                  </a>
                </div>
              </Col> */}
            </Row>
          </div>
          <div className="right">
            <ShortUserInfo />
            {/* <div className="user-short-text">
              <div className="notification-bell">
                <img src="images/bell-icon.png" alt="" width="32" />
                <div className="noti-dot"></div>
              </div>
              <div className="user-short-profile">
                <div className="user-avatar">
                  {user?.propic && <img src={config.imgBaseUrl + user?.propic} width={'100%'} height={'100%'} alt="" />}{!user?.propic && <img src="images/no_profile_img.png" width={'100%'} height={'100%'} alt="" />}
                </div>
                <div className="user-short-name">
                  <h5>{user?.name} </h5>
                  <p>Academic Year 2021-2022</p>
                </div>
                <Dropdown >
                  <Dropdown.Toggle drop='none' variant="">

                  </Dropdown.Toggle >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={logout}
                    // href="#/action"
                    >Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <div className="drop-down">
                  <img src="images/drop-icon.png" alt="" width="10" />
                </div> *
              </div>
            </div> */}
            <div className="card">
              <div className="school-side-details">
                <h5 className="text-muted">Upload Resources</h5>
                <Form>
                  <Row>
                    <Form.Group className="form-group my-3" as={Col} md="12">
                      <div className="upload-resource">
                        <img src={formData.logo ? URL.createObjectURL(formData.logo) : "images/camera-icon.png"} alt="" width="78" />
                        <input type="file" onChange={(e) => setFormData({ ...formData, logo: e.target.files[0] })} />
                      </div>
                      <FieldErrors errors={error} field="logo" />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="12">
                      <Form.Control className="bg-grey" placeholder='Title' value={formData.title} onChange={(e) => setFormData({ ...formData, title: e.target.value })}>
                      </Form.Control>
                      <FieldErrors errors={error} field="title" />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="6">
                      <Form.Select className="bg-grey" defaultValue={formData?.grade_id} onChange={(e) => setFormData({ ...formData, grade_id: e.target.value })}>
                        <option disabled>Select Grade</option>
                        {grade.map(item => <option value={item._id}>{item.title}</option>)}
                      </Form.Select>
                      <FieldErrors errors={error} field="grade" />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="6">
                      <Form.Select className="bg-grey" defaultValue={formData?.subject_id} onChange={(e) => setFormData({ ...formData, subject_id: e.target.value })}>
                        <option disabled>Select Subject</option>
                        {subject?.map(sub => <option value={sub._id}>{sub.title}</option>)}
                      </Form.Select>
                      <FieldErrors errors={error} field="subject" />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="12">
                      <Form.Select placeholder='Select' className="bg-grey" defaultValue={formData.category} onChange={(e) => setFormData({ ...formData, category: e.target.value })}>
                        <option disabled>Select Category</option>
                        <option value="E-Books">E-Book</option>
                        <option value="Teaching Material">Teaching Material</option>
                        <option value="Archived Classed">Archived Classed</option>
                        <option value="Training">Training</option>
                      </Form.Select>
                      <FieldErrors errors={error} field="category" />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="12">
                      <Form.Control as="select" className="bg-grey" multiple defaultValue={formData.audience} onChange={(e) => {
                        var audience = [].slice.call(e.target.selectedOptions).map(item => item.value)
                        setFormData({ ...formData, audience: audience })
                      }} >
                        <option disabled>Select Audience</option>
                        <option value='global'>Global</option>
                        <option value="student">Student</option>
                        <option value="tutor">Tutor</option>
                        {/* <option value="school">School</option>
                        <option value="company">Company</option> */}
                        <option value="facilitator">Facilitator</option>
                        <option value="parent">Parent</option>
                      </Form.Control>
                      <FieldErrors errors={error} field="audience" />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="12">
                      <Form.Control className="bg-grey" type="text" placeholder="Add link" value={formData.link} onChange={(e) => setFormData({ ...formData, link: e.target.value })} />
                      <FieldErrors errors={error} field="link" />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="12">
                      <Form.Control className="bg-grey" as="textarea" placeholder="Add Description" rows="4" value={formData.description} onChange={(e) => setFormData({ ...formData, description: e.target.value })} />
                      <FieldErrors errors={error} field="description" />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="12">
                      <div className="attach-file-res mt-3">
                        <img src="images/attach-file.png" alt="" width="100" />
                        <input type="file" onChange={(e) => setFormData({ ...formData, file: e.target.files[0] })} />
                      </div>
                      <FieldErrors errors={error} field="file" />
                    </Form.Group>
                    <Col md="12" className="text-center mt-2">
                      <Button className="px-4 btn-pill no-shadow" variant="primary" onClick={submitForm}>Upload</Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal show={show} onHide={handleClose}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload Request By ID: 778987 </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="modal-form">
              <Row>
                <div className="row justify-content-center">
                  <Form.Group className="form-group mb-3" as={Col} md="6">
                    <img className="img-fluid rounded" src="images/dash-box2.jpg" alt="" />
                  </Form.Group>
                </div>
                <div className="clearfix"></div>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value="8th Grade" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value="Algebra" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value="E-Book" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="Students /Mentees" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="12">
                  <Form.Control type="email" value="loremipsum@dolores.com" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="12">
                  <Form.Control as="textarea" value="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do 
eiusmod tempor incididunt ut labore et dolore magna aliqua. 
Ut enim ad minim veniam, quis nostrud exercitation ullamco 
laboris nisi ut aliquip ex ea commodo consequat. " rows="4" />
                </Form.Group>
                <Col md="12" className="text-center mt-2">
                  <Button className="px-4 btn-pill me-2" variant="primary">Upload Video</Button>
                  <Button className="px-4 btn-pill ms-2" variant="secondary">Delete Video</Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

    </>
  )
}

export default SchoolResources
