import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import AdminLayout from "./Dashboard/AdminLayout";
import { Organization } from "../../Services/Organization";
import { Link } from "react-router-dom";
import { Admin } from "../../Services/Admin";
import { setAppState } from "../../helpers/app_state";
import { NotificationManager } from "react-notifications";
import _ from "lodash"
import FieldErrors from "../../Services/FieldErrors";

export default function AdminSetting() {
    const [company, setCompany] = useState(null)
    const [error, setError] = useState(null)
    const [message, setMessage] = useState(null);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')))
    const [password, setPassword] = useState({
        password: '',
        confirm_password: '',
        error: null
    })


    const submit = () => {
        console.log(user)
        Admin.post(user, "users/update_admin_profile").then(
            (data) => {
                if (data?.data) {
                    localStorage.setItem('user', JSON.stringify(data?.data))
                    NotificationManager.success(data.message, 'success!', 2000);
                    setMessage(data.message)
                    setError(null)
                }
                else {

                    setError(data.errors.details)
                }
            }
        )
    }
    const changePassword = () => {

        Admin.post({ ...password, _id: user._id }, "users/change_password").then(
            // (data) => {
            //     if (data?.data) {
            //         // localStorage.setItem('user', JSON.stringify(data?.data))
            //     }
            // }
            (data) => {
                if (!data.errors) {

                    NotificationManager.success(data.message, 'success!', 2000);
                    setMessage(data.message)
                    // history.push('/admin-dashboard');
                }
                else {
                    // NotificationManager.error(data.message, 'error!', 2000);
                    setPassword({ ...password, error: data.errors.details })

                    // FieldErrors(data)
                    // < fieldErrors errors = { data } />
                }

            }
        )


    }

    // const fetchCompanies = () => {
    //     Organization.getCategory({ is_registered: true }).then(
    //         (data) => setCompany(data),
    //         (error) => setError(error.toString())
    //     )
    // }
    // useEffect(() => {
    //     fetchCompanies()
    // }, [])
    return <AdminLayout showUserCard={false}>
        <div className="middle">
            <Row>
                <Col md={12}>
                    <Form.Group className="form-group mb-2">
                        <Form.Control type="text" placeholder="Search" className="search-input" />
                        <Button variant="primary" type="submit" className="search-btn">
                            <img src="images/search-icon.png" alt="" width="22" />
                        </Button>
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <div className="card">
                        <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
                            <Tab eventKey="home" title="Account Settings">
                                <div className="account-setting">
                                    <h5 className="text-muted">Basic Information</h5>
                                    <Form>


                                        {/* <Form.Group className="mb-3">
                                            <Form.Label>Parents/Guardian Email</Form.Label>
                                            <Form.Control type="email" value="doejohn@gmail.com" />
                                        </Form.Group> */}
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Name</Form.Label>
                                                    <Form.Control type="text" value={user?.name} onChange={(e) => setUser({ ...user, name: e.target.value })} />
                                                    <FieldErrors errors={error} field="name" />
                                                </Form.Group>

                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control type="email" value={user?.email} onChange={(e) => setUser({ ...user, email: e.target.value })} />
                                                    <FieldErrors errors={error} field="email" />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Username</Form.Label>
                                                    <Form.Control type="text" value={user?.username} onChange={(e) => setUser({ ...user, username: e.target.value })} />
                                                    <FieldErrors errors={error} field="username" />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Mobile Number</Form.Label>

                                                    <Form.Control type="tel" value={user?.phone} onChange={(e) => setUser({ ...user, phone: e.target.value })} />
                                                    <FieldErrors errors={error} field="phone" />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Address</Form.Label>
                                                    <Form.Control type="text" value={user?.address} onChange={(e) => setUser({ ...user, address: e.target.value })} />
                                                    <FieldErrors errors={error} field="address" />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Status</Form.Label>
                                                    <Form.Select aria-label="Default select example" defaultValue={user?.status} onChange={(e) => setUser({ ...user, status: e.target.value })}>
                                                        <option value="Active">Active</option>
                                                        <option value="Inactive">Inactive</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            {/* <Col md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>School</Form.Label>
                                                    <Form.Select aria-label="Default select example">
                                                        <option value="1">Mather High School, IL</option>
                                                        <option value="2">Marion High School, IL</option>
                                                        <option value="3">Don Bosco School</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Grade</Form.Label>
                                                    <Form.Select aria-label="Default select example">
                                                        <option value="1">4th Grade</option>
                                                        <option value="2">8th Grade</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col> */}
                                        </Row>
                                        <Button variant="primary" onClick={submit}>
                                            Save Changes
                                        </Button>
                                    </Form>
                                    {/* <hr />
                                    <h5 className="text-muted">Accessbility</h5>
                                    <Form>
                                        <div className="accessbility mb-3">
                                            <Form.Check
                                                type="checkbox"
                                                id="one"
                                                label="Request a facilitator"
                                                checked
                                            />
                                            <Form.Check
                                                type="checkbox"
                                                id="two"
                                                label="Get notifications"
                                            />
                                            <Form.Check
                                                type="checkbox"
                                                id="three"
                                                label="Use chat feature"
                                            />
                                            <Button variant="primary" type="submit" disabled className="mt-3">
                                                Save Changes
                                            </Button>
                                        </div>
                                    </Form> */}
                                    <hr />
                                    <h5 className="text-muted">Delete Accounts</h5>
                                    <Button variant="danger" type="submit" className="mt-3" style={{ width: 200, }}>
                                        Delete Your Account
                                    </Button>
                                </div>
                            </Tab>
                            <Tab eventKey="profile" title="Privacy Settings">
                                <div className="account-setting">
                                    <Form>
                                        <h5 className="text-muted">Change Password</h5>
                                        {/* <Form.Group className="mb-3">
                            <Form.Label>Old Password</Form.Label>
                            <Form.Control type="text" value="Jane Doe" />
                          </Form.Group> */}
                                        <Form.Group className="mb-3">
                                            <Form.Label>New Password</Form.Label>
                                            <Form.Control type="password" value={password?.password} onChange={(e) => setPassword({ ...password, password: e.target.value })} />
                                            <FieldErrors errors={password.error} field="password" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Confirm Password</Form.Label>
                                            <Form.Control type="password" value={password?.confirm_password} onChange={(e) => setPassword({ ...password, confirm_password: e.target.value })} />
                                            <FieldErrors errors={password.error} field="confirm_password" />
                                        </Form.Group>
                                        <Button variant="primary" onClick={changePassword}>
                                            Save Changes
                                        </Button>
                                    </Form>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </Col>
            </Row>
        </div>
    </AdminLayout>
}