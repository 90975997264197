import React, { useEffect, useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Modal,
  Dropdown
} from "react-bootstrap";
import { NavLink, Link, useParams } from "react-router-dom";
import SideMenu from "./SideMenu";
import CalendarTemplate from 'availability-calendar-react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import config from '../../config';
import Calendar from 'react-calendar';

import { FecthData } from '../../Services/FetchData';





function TutorProfile(props) {
  const user = JSON.parse(localStorage.getItem('user'))
  const history = useHistory()
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [tutor, setTutor] = useState({});

  const { id } = useParams();



  const fetchTutor = () => {
    FecthData.getData('student/get_tutor_profile/' + id).then(
      (data) => setTutor(data?.data)
    )
  }


  const [availability, setAvailability] = useState([])
  // const Calendar = CalendarTemplate({
  //   availability,
  //   setAvailability,
  //   primaryColor: "#CCCCCC",
  //   secondaryColor: "#EEEEEE",
  //   primaryFontColor: "#444444",
  //   fontSize: 14,
  //   startTime: "10:00",
  //   endTime: "12:00"
  // });


  const selectedDay = val => {
    console.log(val);
  };
  const logout = (url) => {
    localStorage.clear()
    history.push(url)
  }


  useEffect(()=>{
    fetchTutor()
  }, [])


  return (
    <>
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="dashboard-wrap">
          <SideMenu />
          <div className="middle">
            <Row>
              <Col md={12}>
                <Form.Group className="form-group mb-2">
                  <Form.Control type="text" placeholder="Search" className="search-input" />
                  <Button variant="primary" type="submit" className="search-btn">
                    <img src="images/search-icon.png" alt="" width="22" />
                  </Button>
                </Form.Group>
              </Col>
              <Col md={12}>
                <div className="tutor-list mt-3">
                  <div className="tutor-item">
                    <div className="tutor-wrap">
                      <div className="tutor-avatar">
                        <img src="images/avatar-male.jpg" alt="" />
                      </div>
                      <div className="tutor-subject">
                        <h6> { tutor?.first_name + " " + tutor?.last_name } </h6>
                        {/* <h5> </h5> */}
                        <p>{ tutor?.tutoring_experience}</p>
                        <h6 className="mt-4">
                          BIO
                        </h6>
                        <p>
                        { tutor?.about }
                        </p>
                        {/* <h6 className="mt-4">
                          WORK EXPERIENCE
                        </h6> */}
                        {/* <div className="d-flex justify-content-between mt-3">
                          <p><strong>Lorem Ipsum School</strong></p>
                          <p><strong>(September 2019-Present)</strong></p>
                        </div>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                          et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                          ea commodo consequat.
                        </p>
                        <div className="d-flex justify-content-between mt-3">
                          <p><strong>Lorem Ipsum School</strong></p>
                          <p><strong>(September 2019-Present)</strong></p>
                        </div>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                          et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                          ea commodo consequat.
                        </p> */}
                        <h6 className="mt-4 mb-3">
                          Subjects Taught
                        </h6>
                        {/* <ul>
                          <li>
                            Algebra
                          </li>
                          <li>
                            Science
                          </li>
                          <li>
                            Physics
                          </li>
                          <li>
                            Geography
                          </li>
                        </ul> */}

<ul>
                        {tutor?.subjects?.map(subject => <li>
                            {subject.title}
                        </li>)}
                        {/* <li>
                            Algebra
                        </li>
                        <li>
                            Science
                        </li>
                        <li>
                            Physics
                        </li>
                        <li>
                            Geography
                        </li> */}
                    </ul>

                        {/* <div className="text-center">
                          <Link onClick={handleShow} to="#" className="btn btn-primary text-center mt-5 px-5">Request A Lesson</Link>
                        </div> */}
                      </div>
                    </div>
                    <div className="tutor-action">
                      <ul>
                        <li>
                          <a href="javascript:void(0)"><i className="fa fa-heart"></i></a>
                        </li>
                        <li className="active"><a href="javascript:void(0)" onClick={handleShow}><i className="fa fa-comment-o"></i>
                        </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="right">
            <div className="user-short-text">
              <div className="notification-bell">
                <img src="images/bell-icon.png" alt="" width="32" />
                <div className="noti-dot"></div>
              </div>
              <div className="user-short-profile">
                <div className="user-avatar">
                  {user?.student?.gender_avatar?.icon_image && <img src={config.imgBaseUrl + user?.student?.gender_avatar?.icon_image} width={'100%'} height={'100%'} alt="" />}{!user?.student?.gender_avatar?.icon_image && <img src="images/avatar2.png" width={'100%'} height={'100%'} alt="" />}
                </div>
                <div className="user-short-name">
                  <h5>{user?.name} | ID: {user?.student?.student_code}</h5>
                  <p>Academic Year {user?.student?.academic_year}</p>
                </div>
                <Dropdown >
                  <Dropdown.Toggle drop='none' variant="">

                  </Dropdown.Toggle >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => logout('/')}
                    // href="#/action"
                    >Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <div className="drop-down">
                  <img src="images/drop-icon.png" alt="" width="10" />
                </div> */}
              </div>
            </div>
            <div className="upcom-class">
              <h5 className="text-muted">Tutor Availability</h5>
              <Calendar />
            </div>
            <h5 className="sub-heading mt-4 text-muted d-flex justify-content-between">Reviews <span><Link className=" text-muted" to="/teacher-review">See All</Link></span></h5>
            <div className="upcom-class">
              <div id="testimonial-carousel" className="owl-carousel owl-theme">
                <div className="item">
                  <div className="testimoial-box">
                    <div className="testi-avatar">
                      <img src="images/avatar2.png" alt="" />
                    </div>
                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that</p>
                  </div>
                </div>
                <div className="item">
                  <div className="testimoial-box">
                    <div className="testi-avatar">
                      <img src="images/avatar2.png" alt="" />
                    </div>
                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal show={show} onHide={handleClose}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Get in touch with John D.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="modal-form">
              <Row>
                <Col md="12">
                  <Form.Group className="form-group relative">
                    <Form.Control className="mb-3"
                      as="textarea"
                      value="Example: Hi, I need help with linear equations, functions, & 
                    graphs. Can we schedule a tutoring session?"
                      style={{ height: '120px' }}
                    />
                    <div className="attachment">
                      <div className="attach-file">
                        <input type="file" />
                        <i className="fa fa-paperclip" aria-hidden="true"></i>
                        <p>Attach File</p>
                      </div>
                      <div className="msg-btn">
                        <button className="btn btn-primary btn-sm">Sample Message</button>
                      </div>
                    </div>
                  </Form.Group>
                </Col>
                <Form.Group className="form-group mb-3" as={Col} md="12">
                  <Form.Select>
                    <option>Algebra</option>
                    <option value="1">Geometry</option>
                    <option value="2">Calculus</option>
                    <option value="3">Biology</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Select>
                    <option>1:00 HR</option>
                    <option value="1">2:00 HR</option>
                    <option value="2">3:00 HR</option>
                    <option value="3">4:00 HR</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Select>
                    <option>Live Session</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Select>
                    <option>Start Time</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Select>
                    <option>End Time</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="12">
                  <Form.Select>
                    <option>Apply This Schedule To All Sundays</option>
                  </Form.Select>
                </Form.Group>
                <Col md="12" className="text-center mt-2">
                  <Button className="px-4" variant="primary">Submit</Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

    </>
  )
}

export default TutorProfile
