import React, { useEffect, useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Stack,
} from "react-bootstrap";
import Header from "../Header";
import Footer from "../Footer";
import { NavLink, Link } from "react-router-dom";
import Autocomplete from 'react-autocomplete'
import { Organization } from '../../Services/Organization';
import SignupStepOne from './SignupStepOne';
import SignupStepTwo from './SignupStepTwo';
import SignupStepThree from './SignupStepThree';
import SignupStepThreeA from './SignupStepThreeA';
import SignupStepFour from './SignupStepFour';
import SignupStepFive from './SignupStepFive';
import SignupStepSix from './SignupStepSix';
import { buildUser } from '../../helpers/user-builder';
import { School } from '../../Services/School';
import { NotificationManager } from 'react-notifications';

function StudentRegistration(props) {
  const data = props.location.state ? props.location.state : null
  const queryParameters = new URLSearchParams(window.location.search)
  const step1 = queryParameters.get("step")
  const email = queryParameters.get("email")
  const student_id = queryParameters.get('student_id')
  const [step, setstep] = useState(step1 ? parseInt(step1) : 1);
  let userData = buildUser(data)
  // if (data) {
  //   userData = buildUser(data)
  // }
  console.log(step)
  const [schlFormData, setSchlFormData] = useState({
    ...userData,
    school: "",
    school_id: "",
    school_name: "",
    grade_id: "",
    academic_year: "",
    gender_avatar: "",
    // first_name: "",
    // last_name: "",
    // email: "",
    phone: '',
    timezone: '',
    guardian_email: "",
    require_facilitator: "no",
    facilitator_email: "",
    subjects: [],
    contact_by: [],


  })
  console.log(schlFormData)

  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    setstep(step + 1);
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setstep(step - 1);
  };

  //set my subject
  const addYourSubject = e => {
    console.log(e.target)
    const { value, checked } = e.target;
    const { subjects } = schlFormData;
    // if (checked) {
    setSchlFormData(prevState => ({
      ...prevState,
      subjects: [value],
    }));

    // } else {
    //   setSchlFormData(prevState => ({
    //     ...prevState,
    //     subjects: subjects.filter((e) => e !== value),
    //   }));

    // }
    console.log(schlFormData)
  }


  // handling form input data by taking onchange value and updating our previous form data state
  const handleInputData = input => e => {
    console.log(e)
    // input value from the form
    const { value } = e.target;

    //updating for data state taking previous state and then adding new value to create new object
    setSchlFormData(prevState => ({
      ...prevState,
      [input]: value
    }));
  }
  function handleImage(newValue) {
    setSchlFormData(prevState => ({
      ...prevState,
      ['logo']: newValue
    }));
  }

  function handleSchool(name, newValue) {
    console.log(newValue)
    setSchlFormData(prevState => ({
      ...prevState,
      [name]: newValue
    }));
  }
  const handleContactBy = input => e => {
    const { value, checked } = e.target;
    const { contact_by } = schlFormData;
    if (checked) {
      setSchlFormData(prevState => ({
        ...prevState,
        contact_by: [...contact_by, value],
      }));

    } else {
      setSchlFormData(prevState => ({
        ...prevState,
        contact_by: contact_by.filter((e) => e !== value),
      }));

    }
  }

  const [value, setValue] = useState('');
  const [error, setError] = useState();
  const [validated, setValidated] = useState(false);
  const [file, setFile] = useState();
  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  const sendOtp = () => {
    if (email !== '') {
      School.sendEmail({ email: email, in_register: true }).then(
        (data) => {
          if (!data.errors) {
            NotificationManager.success(data.message, 'success!', 2000);
            setSchlFormData({ ...schlFormData, email: email, student_id: student_id })
            // setMessage(data.message)
          } else {
            NotificationManager.error(data.message, 'error!', 2000);
            // setMessage(data.message)
          }
          console.log(data)

        },
        (error) => setError(error.toString())
      )

    } else {
      NotificationManager.error('Email is required', 'error!', 2000);
    }

  };
  useEffect(() => {
    console.log('in useEffect step: ', typeof (step1))
    if (email && student_id) {
      setSchlFormData({ ...schlFormData, email: email, student_id: student_id })
      // sendOtp()
    }
  }, [email])



  switch (step) {
    case 1:
      return (
        <>
          <SignupStepOne nextStep={nextStep} handleImage={handleImage} handleFormData={handleInputData} handleSchool={handleSchool} values={schlFormData} />
        </>
      )
    case 2:
      return (
        <>
          <SignupStepTwo nextStep={nextStep} prevStep={prevStep} handleFormData={handleInputData} handleContactBy={handleContactBy} handleSchool={handleSchool} values={schlFormData} />
        </>
      )
    case 3:
      return (
        <>
          <SignupStepThree nextStep={nextStep} prevStep={prevStep} handleFormData={handleInputData} handleContactBy={handleContactBy} handleSchool={handleSchool} values={schlFormData} />
        </>
      )
    case 0:
      return (
        <>
          <SignupStepThreeA nextStep={nextStep} prevStep={prevStep} handleFormData={handleInputData} handleContactBy={handleContactBy} handleSchool={handleSchool} values={schlFormData} />
        </>
      )
    case 4:
      return (
        <>
          <SignupStepFour nextStep={nextStep} prevStep={prevStep} handleFormData={handleInputData} values={schlFormData} />
        </>
      )
    case 5:
      return (
        <>
          <SignupStepFive nextStep={nextStep} prevStep={prevStep} handleFormData={handleInputData} values={schlFormData} addYourSubject={addYourSubject} />
        </>
      )
    case 6:
      return (
        <>
          <SignupStepSix nextStep={nextStep} prevStep={prevStep} handleFormData={handleInputData} values={schlFormData} />
        </>
      )
    // never forget the default case, otherwise VS code would be mad!
    default:
      return <div>hello</div>
    // do nothing
  }
}

export default StudentRegistration
