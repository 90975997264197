import { Link } from "react-router-dom";

function TutorBlock(props) {

    var tutor = props.tutor

    return (


        <Link to={"/tutor/profile/" + tutor?.tutor._id} className="tutor-item">
            <div className="tutor-wrap">

                <div className="tutor-avatar">
                    <img src="images/avatar.jpg" alt="" />
                </div>
                <div className="tutor-subject">
                    <h6>{tutor?.tutor?.first_name} {tutor?.tutor?.last_name}</h6>
                    <p>{tutor?.tutor?.tutoring_experience}  | {tutor?.tutor?.about}</p>
                    <ul>
                        {tutor?.tutor?.subjects?.map(subject => <li>
                            {subject.title}
                        </li>)}
                        {/* <li>
                            Algebra
                        </li>
                        <li>
                            Science
                        </li>
                        <li>
                            Physics
                        </li>
                        <li>
                            Geography
                        </li> */}
                    </ul>
                </div>
            </div>
            <div className="tutor-action">
                <ul>
                    <li className="active">
                        <a href="javascript:void(0)"><i className="fa fa-heart"></i></a>
                    </li>
                    {/* <li><a href="javascript:void(0)"><i className="fa fa-comment-o"></i>
                    </a>
                    </li> */}
                </ul>
            </div>
        </Link>

    )



}


export default TutorBlock