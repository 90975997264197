import React, { useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Stack,
  Modal
} from "react-bootstrap";
import Header from "../Header";
import Footer from "../Footer";
import { NavLink, Link } from "react-router-dom";
import { Organization } from '../../Services/Organization';
import Autocomplete from 'react-autocomplete';
import { NotificationManager } from 'react-notifications';
import ActionButton from '../ActionButton';

function AdminSignupStepTwo({ nextStep, prevStep, handleFormData, handleContactBy, values }) {
  const [error, setError] = useState(false);
  const [error2, setError2] = useState();
  const [contactBy, setContactBy] = useState([]);
  const [message, setMessage] = useState(null);
  const toggleHandler = (e) => {
    console.log('toggle data ', e)
    if (e.target.checked) {

    } else {

    }

  }
  // after form submit validating the form data using validator
  const submitFormData = (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (values.email !== '' && values.email !== null && values.email !== undefined) {

        Organization.sendEmail({ email: values.email, in_register: true }).then(
          (data) => {
            if (data.error === 0) {
              NotificationManager.success(data.message, 'success!', 2000);
              setMessage(data.message)
              nextStep();
            }
            console.log(data)

          },
          (error) => setError(error.toString())
        )

      } else {

        NotificationManager.error('Email is required', 'error!', 2000);
      }
    }


    //  nextStep(); 

  };

  const validateForm = () => {
    let fields = values;
    console.log(values)
    let errors = {};
    let formIsValid = true;

    if (!fields["department"] || fields["department"].trim().length <= 0) {
      formIsValid = false;
      errors["department"] = "*Please Enter Your department.";
    }
    if (!fields["address"] || fields["address"].trim().length <= 0) {
      formIsValid = false;
      errors["address"] = "*Please Enter Your address.";
    }
    if (!fields["first_name"] || fields["first_name"].trim().length <= 0) {
      formIsValid = false;
      errors["first_name"] = "*Please Enter Your First Name.";
    }
    if (!fields["last_name"] || fields["last_name"].trim().length <= 0) {
      formIsValid = false;
      errors["last_name"] = "*Please Enter Your Last Name.";
    }
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "*Please enter your email-ID.";
    }

    if (typeof fields["email"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(fields["email"])) {
        formIsValid = false;
        errors["email"] = "*Please enter valid email-ID.";
      }
    }

    if (!fields["phone"]) {
      formIsValid = false;
      errors["phone"] = "*Please enter your phone.";
    }

    if (typeof fields["phone"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);
      if (!pattern.test(fields["phone"])) {
        formIsValid = false;
        errors["phone"] = "*Please enter valid phone.";
      }
    }
    setError2(errors)
    return formIsValid;


  }

  return (
    <>
      <Header />
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="login-body" style={{ backgroundImage: 'url("images/signup-bg.jpg")', height: '100%', width: 'auto', backgroundPosition: 'center' }}>
          <Container>
            <Row>
              <Col md={12}>
                <div className="step-progress">
                  <div className="step-progress-bar">
                    <div className="step-progress-percent" style={{ width: '50%' }}></div>
                  </div>
                  <div className="step-progress-text">
                    <h6>Profile Creation</h6>
                    <h6>50% Completed</h6>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={9}>
                <div className="signup-box">
                  <div className="signup-left">
                    <h4>Add Your Details</h4>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    <div className="signup-step" style={{ marginTop: 260 }}>
                      <ul>
                        <li></li>
                        <li className="active"></li>
                        <li></li>
                        <li></li>
                      </ul>
                    </div>
                  </div>
                  <div className="signup-right">
                    <Form onSubmit={submitFormData}>
                      <div className="add-details">
                        <Row>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Control type="text" name="first_name" onChange={handleFormData("first_name")} defaultValue={values.first_name} placeholder="First Name*" required />
                              <span className="err">{error2?.first_name}</span>
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Control type="text" name="last_name" onChange={handleFormData("last_name")} defaultValue={values.last_name} placeholder="Last Name*" required />
                              <span className="err">{error2?.last_name}</span>
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Control type="text" name="department" onChange={handleFormData("department")} defaultValue={values.department} placeholder="Department*" required />
                              <span className="err">{error2?.department}</span>
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Control type="tel" name="phone" onChange={handleFormData("phone")} defaultValue={values.phone} placeholder="Contact Number*" required />
                              <span className="err">{error2?.phone}</span>
                            </Form.Group>
                          </Col>
                          <Col md={12}>
                            <Form.Group className="mb-3">
                              <Form.Control type={values.source == 'FACEBOOK' ? 'text' : "email"} name="email" onChange={handleFormData("email")} defaultValue={values.email} placeholder="Email*" required />
                              <span className="err">{error2?.email}</span>
                            </Form.Group>
                          </Col>
                          <Col md={12}>
                            <Form.Group className="mb-3">
                              <Form.Control type="text" name="address" onChange={handleFormData("address")} defaultValue={values.address} placeholder="Address*" required />
                              <span className="err">{error2?.address}</span>
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Control type="text" defaultValue={values.company_name} placeholder="Company Name*" readOnly={true} />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Control type="text" defaultValue={values.company_ein} placeholder="EIN*" readOnly={true} />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Control type="text" defaultValue={values.company_address} placeholder="Company Address*" readOnly={true} />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Control type="text" defaultValue={values.company_city} placeholder="City*" readOnly={true} />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Control type="text" defaultValue={values.company_state} placeholder="State*" readOnly={true} />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Control type="text" defaultValue={values.company_zip} placeholder="Zip Code*" readOnly={true} />
                            </Form.Group>
                          </Col>
                          <Col md={12}>
                            <Form.Group className="iagree mb-3" controlId="formbymail">
                              <Form.Check className="text-grey" name="contact_by" type="checkbox" onChange={handleContactBy('contact_by')} checked={values.contact_by.includes('email')} label="Contact Me By Email" value="email" />
                            </Form.Group>
                            <Form.Group className="iagree mb-3" controlId="formbyphone">
                              <Form.Check className="text-grey" name="contact_by" type="checkbox" onChange={handleContactBy('contact_by')} checked={values.contact_by.includes('phone')} label="Contact Me By Phone" value="phone" />
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                      <div className="signup-back-btn">
                        <Link onClick={prevStep} to="#" className="btn btn-link">Back</Link>
                        {/* <button type="submit" className="btn btn-secondary btn-pill px-4">Create Account</button> */}
                        <ActionButton type={'submit'} className={'btn btn-primary btn-pill px-4'} title={'Create Account'}
                          requiredFields={[values?.first_name, values?.last_name, values?.email, values?.phone, values?.address]} />
                      </div>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <Footer />

    </>
  )
}

export default AdminSignupStepTwo
