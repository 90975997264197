import React, { useEffect, useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Stack,
} from "react-bootstrap";
import Header from "../Header";
import Footer from "../Footer";
import { NavLink, Link, useHistory } from "react-router-dom";
import Autocomplete from 'react-autocomplete'
import { Organization } from '../../Services/Organization';
import TextField from "@material-ui/core/TextField";
import validator from "validator";
import ActionButton from '../ActionButton';
import RemoveImage from '../RemoveImage';

const OrgSignupStepOne = ({ nextStep, handleImage, handleFormData, handleCompany, values }) => {
  const [value, setValue] = useState(values.company_name);
  const [category, setCategory] = useState('');
  const [error, setError] = useState();
  const [error1, setError1] = useState();
  const [validated, setValidated] = useState(false);
  const [file, setFile] = useState();
  let history = useHistory();
  function handleChange(e) {

    setFile(URL.createObjectURL(e.target.files[0]));
    handleImage(e.target.files[0])
    handleCompany('logo_url', URL.createObjectURL(e.target.files[0]))
    console.log('this is image', e.target.files[0]);
  }
  const removeImage = () => {
    handleCompany('logo_url', '')
  }
  const submitFormData = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (
      validator.isEmpty(values.display_logo)
    ) {
      setError1(true);
    } else {
      nextStep();
    }

  };

  useEffect(() => {
    Organization.getCategory({ is_registered: true }).then(
      (data) => setCategory(data),
      (error) => setError(error.toString())
    )
    console.log(value)
  }, [])
  return (
    <>
      <Header />
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="login-body" style={{ backgroundImage: 'url("images/signup-bg.jpg")', height: '100%', width: 'auto', backgroundPosition: 'center' }}>
          <Container>
            <Row>
              <Col md={12}>
                <div className="step-progress">
                  <div className="step-progress-bar">
                    <div className="step-progress-percent" style={{ width: '25%' }}></div>
                  </div>
                  <div className="step-progress-text">
                    <h6>Account Creation</h6>
                    <h6>25% Completed</h6>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={9}>
                <div className="signup-box">
                  <div className="signup-left">
                    <h4>Select Profile Image</h4>
                    <p>Your profile image is used as the icon for your personal space and to represent you in Dasya</p>
                    <div className="signup-step">
                      <ul>
                        <li className="active"></li>
                        <li></li>
                        <li></li>
                        <li></li>
                      </ul>
                    </div>
                  </div>
                  <div className="signup-right">
                    <Form onSubmit={submitFormData}>
                      <Row>
                        <Col md={12}>
                          <div className="upload-profile">
                            <div className="upload-profile-img">
                              {values.logo_url && <span><img src={values.logo_url} alt="" style={{ objectFit: 'cover', height: '170px', width: '170px' }} /></span>}{!values.logo_url && <span><img style={{ objectFit: 'cover', height: '170px', width: '170px' }} src="images/no_img.png" alt="" /></span>}
                            </div>
                            <div className="upload-icon">
                              {values.logo_url ? <RemoveImage isHaveImage={values.logo_url} handleClick={removeImage} /> : <>
                                <img src="images/camera-icon.png" alt="" width="78" />
                                <input type="file" name="logo" onChange={handleChange} /></>}
                            </div>
                          </div>
                        </Col>
                        <Col md={12}>
                          <Form.Group className="form-group my-4">
                            <Autocomplete className="search"
                              items={category?.data || []}
                              shouldItemRender={(item, value
                              ) => item.company_name.toLowerCase()
                                .indexOf(value.toLowerCase()) > -1}
                              getItemValue={item => item.company_name}
                              renderItem={(item, isHighlighted) =>
                                <div style={{
                                  background: isHighlighted ?
                                    '#eee' : 'white',
                                  padding: '5px 10px',
                                }}
                                  {...item} key={item._id}>
                                  {item.company_name}
                                </div>
                              }
                              value={value}
                              onChange={(e) => {
                                setValue(e.target.value)
                                handleFormData(e)
                              }}
                              onSelect={(val, item) => {
                                setValue(val)
                                handleCompany('company', item)
                                handleCompany('company_id', item._id)
                                handleCompany('company_name', item.company_name)
                                handleCompany('company_ein', item.company_code)
                                handleCompany('company_address', item.company_address_line1)
                                handleCompany('company_city', item.company_city)
                                handleCompany('company_state', item.company_state)
                                handleCompany('company_zip', item.company_zip)
                                console.log(item)
                              }}
                              inputProps={{
                                style: {
                                  width: '100%',
                                  background: '#fff',
                                  boxShadow: '0 0 8px rgba(0,0,0,0.25)',
                                  width: '100%',
                                  color: '#000',
                                  borderRadius: 10,
                                  minHeight: 52,
                                  border: '1px solid #E9ECF4',
                                  fontSize: 14,
                                  paddingLeft: 15,
                                  paddingRight: 15,
                                },
                                name: 'company',
                                placeholder: 'Search by company name',
                                required: true
                              }}
                              wrapperStyle={{
                                width: '100%',
                              }}
                            />
                            <Button variant="primary" type="submit" className="search-btn">
                              <img src="images/search-icon.png" alt="" width="22" />
                            </Button>

                          </Form.Group>

                        </Col>
                        <Col md={6}>
                          <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <Form.Check className="text-grey" type="radio" name="display_logo" id="flexRadioDefault1" defaultValue="yes" onChange={handleFormData("display_logo")} checked={values.display_logo === "yes"} label="Display my image on my profile" />
                            {error1 ? (
                              <Form.Text style={{ color: "red" }}>
                                This is a required field
                              </Form.Text>
                            ) : (
                              ""
                            )}
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="mb-3" controlId="formBasicCheckbox1">
                            <Form.Check className="text-grey" type="radio" name="display_logo" id="flexRadioDefault2" defaultValue="no" onChange={handleFormData("display_logo")} checked={values.display_logo === "no"} label="Don’t display my image on my profile" />
                          </Form.Group>
                        </Col>
                      </Row>
                      <div className="signup-back-btn">
                        <Link to="/organization" className="btn btn-link">Back</Link>
                        <ActionButton type={'submit'} className={'btn btn-primary btn-pill px-4'} title={'Continue'}
                          requiredFields={[values?.display_logo == 'yes' ? values?.logo_url : 'No', values.company_id]} />
                        {/* <button type="submit" className="btn btn-primary btn-pill px-4">Continue</button> */}
                      </div>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <Footer />

    </>
  )
}

export default OrgSignupStepOne
