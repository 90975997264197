import React, { useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Stack,
  Modal
} from "react-bootstrap";
import Header from "../Header";
import Footer from "../Footer";
import { NavLink, Link, useHistory } from "react-router-dom";
import Autocomplete from 'react-autocomplete';
import { Admin } from '../../Services/Admin';
import { NotificationManager } from 'react-notifications';
import ActionButton from '../ActionButton';

function AdminSignupStepFour({ prevStep, values, handleFormData }) {
  const [isChecked, setIsChecked] = useState(false);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(false);
  let history = useHistory();

  const formatDate = (date) => {

    var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [month, day, year].join('/');
  }

  const submitFormData = (e) => {
    e.preventDefault();
    console.log('value ', typeof (values))
    if (values.accept_privacy_policy) {
      let formData = new FormData();
      Object.entries(values).map(([key, value]) => {
        if (key !== 'contact_by') {
          formData.append(key, value);
        } else {
          formData.append(key, value.toString());
        }

      })
      Admin.registerAdmin(formData).then(
        (data) => {
          if (!data.errors) {
            NotificationManager.success(data.message, 'success!', 2000);
            setMessage(data.message)
            history.push('/admin');
          } else {
            NotificationManager.error(data.message.toString(), 'error!', 2000);
            setMessage(data.message)
          }
          console.log(data)

        },
        (error) => {
          NotificationManager.error(error.toString(), 'error!', 2000);
          setError(error.toString())
        }
      )
    } else {
      NotificationManager.error('Please accept privacy policy', 'error!', 2000);
    }
  }

  const handleChange = event => {
    if (event.target.checked) {
      console.log('✅ Checkbox is checked');
    } else {
      console.log('⛔️ Checkbox is NOT checked');
    }
    setIsChecked(current => !current);
  };


  return (
    <>
      <Header />
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="login-body" style={{ backgroundImage: 'url("images/signup-bg.jpg")', height: '100%', width: 'auto', backgroundPosition: 'center' }}>
          <Container>
            <Row>
              <Col md={12}>
                <div className="step-progress">
                  <div className="step-progress-bar">
                    <div className="step-progress-percent" style={{ width: '100%' }}></div>
                  </div>
                  <div className="step-progress-text">
                    <h6>Privacy Notice</h6>
                    <h6>100% Completed</h6>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={9}>
                <div className="signup-box">
                  <div className="signup-left">
                    <h4>Privacy Notice</h4>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    <div className="signup-step" style={{ marginTop: 260 }}>
                      <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li className="active"></li>
                      </ul>
                    </div>
                  </div>
                  <div className="signup-right">
                    <Form onSubmit={submitFormData}>
                      <div className="add-details">
                        <Row>
                          <Col md={12}>
                            <h6 className="text-muted">Privacy notice</h6>
                            <p className="text-muted">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusm tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </p>
                            <p className="text-muted">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
                            </p>
                            <p className="text-muted">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </p>
                          </Col>
                          <Col md={12}>
                            <Form.Group className="iagree mb-3" controlId="formBasicCheckbox">
                              <Form.Check className="text-grey" type="checkbox" name="accept_privacy_policy" value={values.accept_privacy_policy} onChange={(e) => handleFormData(e.target.name, e.target.checked)} label={`I have read & accepted the Privacy Notice as on ${formatDate()}`} />
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                      <div className="signup-back-btn">
                        <Link onClick={prevStep} href="#" className="btn btn-link">Back</Link>
                        <ActionButton type={'submit'} className={'btn btn-primary btn-pill px-4'} title={'Submit'}
                          requiredFields={[values.accept_privacy_policy ? values.accept_privacy_policy : '']} />
                        {/* <button type="submit" className="btn btn-secondary btn-pill px-4">Submit</button> */}
                      </div>
                    </Form>
                  </div>

                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <Footer />

    </>
  )
}

export default AdminSignupStepFour
