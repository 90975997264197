import React, { useEffect, useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Modal,
  DropdownButton,
  Dropdown
} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import SchoolSideMenu from "./SchoolSideMenu";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { auto } from '@popperjs/core';
import { FecthData } from '../../Services/FetchData';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import config from '../../config';
import ShortUserInfo from './ShortUserInfo';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top'
    },
    title: {
      display: true,
      text: '',
    },
  },
};

const labels = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUNE'];

const data = {
  labels,
  datasets: [
    {
      label: 'Students',
      data: ['15', '20', '30', '25', '32', '15'],
      borderColor: 'rgba(51, 64, 212, 0.5)',
      backgroundColor: 'rgba(51, 64, 212, 0.5)',
      fill: {
        target: 'origin',
        above: 'rgba(43, 99, 255, 0.4)',   // Area will be red above the origin
        below: 'rgba(51, 64, 212, 0.4)'    // And blue below the origin
      }
    },
    {
      label: 'Tutors',
      data: ['18', '22', '24', '30', '28', '20'],
      borderColor: 'rgba(132, 176, 60, 0.4)',
      backgroundColor: 'rgba(132, 176, 60, 0.4)',
      fill: {
        target: 'origin',
        above: 'rgba(132, 176, 60, 0.4)',   // Area will be red above the origin
        below: 'rgba(132, 176, 60, 0.4)'    // And blue below the origin
      }
    },
  ],
};

ChartJS.register(Tooltip, Legend);

function SchoolStatistics() {
  const [statistics, setStatistics] = useState()
  const user = JSON.parse(localStorage.getItem('user'))
  let history = useHistory()
  useEffect(() => {

    FecthData.getData('school/get_school_statistics/' + user?.school.school_id).then(
      (data) => setStatistics(data.data)
    )
  }, [])
  const logout = () => {
    localStorage.clear()
    history.push('/school')
  }
  console.log(statistics)
  return (
    <>
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="dashboard-wrap">
          <SchoolSideMenu />
          <div className="middle">
            <Row>
              <Col md={12}>
                <Form.Group className="form-group mb-2">
                  <Form.Control type="text" placeholder="Search" className="search-input" />
                  <Button variant="primary" type="submit" className="search-btn">
                    <img src="images/search-icon.png" alt="" width="22" />
                  </Button>
                </Form.Group>
              </Col>
            </Row>
            <div className="card">
              <Row>
                <Col md={4}>
                  <div className="card bg-purple progress-box" style={{ minHeight: 96 }}>
                    <div className="card-text text-white">
                      <h4>No. Of Students (2021)</h4>
                    </div>
                    <div className="card-big-text">
                      <h5 className="count">{statistics?.student_count}</h5>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="card bg-danger progress-box" style={{ minHeight: 96 }}>
                    <div className="card-text text-white">
                      <h4>No. Of Tutors (2021)</h4>
                      {/* <h5 className="count">{statistics?.tutor_count}</h5> */}
                    </div>
                    <div className="card-big-text">
                      <h5 className="count">{statistics?.tutor_count}</h5>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="card bg-success progress-box" style={{ minHeight: 96 }}>
                    <div className="card-text text-white">
                      <h4>No. Of Facilitators (2021)</h4>
                      {/* <h5 className="count">{statistics?.facilitator_count}</h5> */}
                    </div>
                    <div className="card-big-text">
                      <h5 className="count">{statistics?.facilitator_count}</h5>
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <h5 className="sub-heading mt-4 text-muted d-flex justify-content-between align-items-center">Statistics
                    <Form.Group style={{ minWidth: 120 }}>
                      <Form.Select>
                        <option>Weekly</option>
                        <option>Monthly</option>
                      </Form.Select>
                    </Form.Group>
                  </h5>
                  <Line options={options} data={data} />
                </Col>
              </Row>
            </div>
            <Row>
              <Col md={12} className="mb-4">
                <h6 className="text-muted mt-5 d-flex justify-content-between">Tutors <a href="javascript:void(0)" className="text-muted"><small>View All</small></a></h6>
              </Col>
              {statistics?.tutor_list.map(tutor => <Col md={4}>
                <div className="student-rating-box">
                  <div className="rating-avatar">
                    <img src="images/avatar.jpg" alt="" />
                  </div>
                  <div className="rating-description">
                    <h4>{tutor.first_name + ' ' + tutor.last_name} | ID: 567876</h4>
                    <h5>Academic Year 2021-2022</h5>
                    <div className="star-count">
                      <img src="images/rating-4.png" alt="" width="184" />
                    </div>
                    <Link to={{ pathname: "/school-teacher-stats", state: tutor }} className="btn btn-primary btn-pill px-4">View</Link>
                  </div>
                </div>
              </Col>)}
              {/* <Col md={4}>
                <div className="student-rating-box">
                  <div className="rating-avatar">
                    <img src="images/student3.jpg" alt="" />
                  </div>
                  <div className="rating-description">
                    <h4>John W. | ID: 567876</h4>
                    <h5>Academic Year 2021-2022</h5>
                    <div className="star-count">
                      <img src="images/rating-4.png" alt="" width="184" />
                    </div>
                    <Link to="/school-teacher-stats" className="btn btn-primary btn-pill px-4">View</Link>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="student-rating-box">
                  <div className="rating-avatar">
                    <img src="images/student2.jpg" alt="" />
                  </div>
                  <div className="rating-description">
                    <h4>Joe M. | ID: 567876</h4>
                    <h5>Academic Year 2021-2022</h5>
                    <div className="star-count">
                      <img src="images/rating-4.png" alt="" width="184" />
                    </div>
                    <Link to="/school-teacher-stats" className="btn btn-primary btn-pill px-4">View</Link>
                  </div>
                </div>
              </Col> */}
              <Col md={12}>
                <h6 className="text-muted mt-5 d-flex justify-content-between">Students <a className="text-muted" href="javascript:void(0)"><small>View All</small></a></h6>
              </Col>
              <Col md={12}>
                <div className="student-list">
                  <ul>
                    {statistics?.student_list.map(student => <li className='p-1'>
                      <Link to={{ pathname: "/school-student-stats", state: student }}>
                        <div className="tutor-user">
                          <div className="tutor-user-img">
                            <img src="images/avatar2.png" alt="" />
                          </div>
                          <h4>{student.student_code}</h4>
                          <p>{student.grade_details.length > 0 ? student.grade_details[0].title : ''}</p>
                        </div>
                        <div className="online-status active"></div>
                      </Link>
                    </li>)}
                    {/* <li>
                      <Link to="/school-student-stats">
                        <div className="tutor-user">
                          <div className="tutor-user-img">
                            <img src="images/avatar2.png" alt="" />
                          </div>
                          <h4>567876</h4>
                          <p>8th Grade</p>
                        </div>
                        <div className="online-status active"></div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/school-student-stats">
                        <div className="tutor-user">
                          <div className="tutor-user-img">
                            <img src="images/avatar1.png" alt="" />
                          </div>
                          <h4>346789</h4>
                          <p>8th Grade</p>
                        </div>
                        <div className="online-status active"></div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/school-student-stats">
                        <div className="tutor-user">
                          <div className="tutor-user-img">
                            <img src="images/avatar3.png" alt="" />
                          </div>
                          <h4>187726</h4>
                          <p>8th Grade</p>
                        </div>
                        <div className="online-status active"></div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/school-student-stats">
                        <div className="tutor-user">
                          <div className="tutor-user-img">
                            <img src="images/avatar2.png" alt="" />
                          </div>
                          <h4>567876</h4>
                          <p>8th Grade</p>
                        </div>
                        <div className="online-status active"></div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/school-student-stats">
                        <div className="tutor-user">
                          <div className="tutor-user-img">
                            <img src="images/avatar1.png" alt="" />
                          </div>
                          <h4>346789</h4>
                          <p>8th Grade</p>
                        </div>
                        <div className="online-status active"></div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/school-student-stats">
                        <div className="tutor-user">
                          <div className="tutor-user-img">
                            <img src="images/avatar3.png" alt="" />
                          </div>
                          <h4>187726</h4>
                          <p>8th Grade</p>
                        </div>
                        <div className="online-status active"></div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/school-student-stats">
                        <div className="tutor-user">
                          <div className="tutor-user-img">
                            <img src="images/avatar2.png" alt="" />
                          </div>
                          <h4>ID: 567876</h4>
                          <p>8th Grade</p>
                        </div>
                        <div className="online-status active"></div>
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </Col>
            </Row>

          </div>
          <div className="right">
            <ShortUserInfo />
            {/* <div className="user-short-text">
              <div className="notification-bell">
                <img src="images/bell-icon.png" alt="" width="32" />
                <div className="noti-dot"></div>
              </div>
              <div className="user-short-profile">
                <div className="user-avatar">
                  {user?.propic && <img src={config.imgBaseUrl + user?.propic} width={'100%'} height={'100%'} alt="" />}{!user?.propic && <img src="images/no_profile_img.png" width={'100%'} height={'100%'} alt="" />}
                </div>
                <div className="user-short-name">
                  <h5>{user?.name}</h5>
                  <p>Academic Year 2021-2022</p>
                </div>
                <Dropdown >
                  <Dropdown.Toggle drop='none' variant="">

                  </Dropdown.Toggle >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={logout}
                    // href="#/action"
                    >Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <div className="drop-down">
                  <img src="images/drop-icon.png" alt="" width="10" />
                </div> *
              </div>
            </div> */}
          </div>
        </div>
      </section>

    </>
  )
}

export default SchoolStatistics
