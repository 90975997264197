import React, { useState, useEffect } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Stack,
  Modal,
  Toast
} from "react-bootstrap";
import { TextField } from '@material-ui/core';
import Header from "../Header";
import Footer from "../Footer";
import { NavLink, Link, useHistory } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import { School } from '../../Services/School';
import { Student } from '../../Services/Student';
import TimePicker from 'react-time-picker';
import Session from '../Session/Session';
import Session1 from '../Session/Session1';
import config from '../../config';
import ActionButton from '../ActionButton';
import _ from 'lodash';

function TutorSignupStepSix({ nextStep, handleFormData, prevStep, values }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [startTime, setStartTime] = useState([new Date()]);
  let history = useHistory();
  const [datez, setDatez] = useState([new Date()]);
  const [endTime, setEndTime] = useState([new Date()]);
  const [error, setError] = useState('');
  const [error1, setError1] = useState([]);
  const [scheduleDay, setScheduleDay] = useState([['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][new Date().getDay()]]);
  const [scheduleDate, setScheduleDate] = useState([[new Date()].toLocaleString('default', { month: 'short', day: "2-digit" })]);
  const [validated, setValidated] = useState(false);
  const [tutorUser, setTutorUser] = useState();
  const [subject, setSubject] = useState([]);
  const [grade, setGrade] = useState([]);
  const [inputSlot, setInputSlot] = useState([
    { recurring_option: '', date: '', subject: '', grade_id: '', start_time: '', end_time: '', type_of_tutoring_session: '', tutor_id: tutorUser?._id }
  ])
  const selectdate = new Date();
  const formatDate = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [month, day, year].join('/');
  }
  const selectedDay = (index, val) => {
    let scheduleDays = [...scheduleDay];
    scheduleDays[index] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][val.getDay()];
    setScheduleDay(scheduleDays);

    let scheduleDates = [...scheduleDate];
    scheduleDates[index] = [val].toLocaleString('default', { month: 'short', day: "2-digit" });
    setScheduleDate(scheduleDates);

    let datezz = [...datez];
    datezz[index] = formatDate(val);
    setDatez(datezz);
    console.log(val)
    handleChangeSetData(index, 'date', formatDate(val))

  };

  const removeFields = (index) => {
    let data = [...inputSlot];
    data.splice(index, 1)
    setInputSlot(data)
  }
  const addFormFields = () => {
    console.log('test')
    let object = { recurring_option: '', date: '', subject: '', grade_id: '', start_time: '', end_time: '', type_of_tutoring_session: '', tutor_id: tutorUser?._id }
    setInputSlot([...inputSlot, object])
    setScheduleDay([...scheduleDay, ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][new Date().getDay()]])
    setScheduleDate([...scheduleDate, [new Date()].toLocaleString('default', { month: 'short', day: "2-digit" })])
    setStartTime([...startTime, new Date()])
    setEndTime([...endTime, new Date()]);
    setDatez([...datez, new Date()])
    return true;
  }

  const handleChange = (index, event) => {
    // setValue(event.target.name,event.target.value) 
    let data = [...inputSlot];
    data[index][event.target.name] = event.target.value;
    setInputSlot(data);
    console.log(data)
  }
  const handleChangeSetData = (index, name, value) => {
    let data = [...inputSlot];
    data[index][name] = value;
    setInputSlot(data);
    console.log(data)
  }
  const addStartTime = (index, e) => {
    let data = [...startTime];
    data[index]['start_time'] = e;
    setStartTime(data);
    handleChangeSetData(index, 'start_time', e)
  }
  const addEndTime = (index, e) => {
    let data = [...endTime];
    data[index]['end_time'] = e;
    setEndTime(data);
    handleChangeSetData(index, 'end_time', e)
  }
  const redirectToHome = () => {
    handleShow()
    setTimeout(() => history.push('/tutor'), 3000)
  }

  const submitFormData = (e) => {
    e.preventDefault();
    if (validateForm()) {
      School.post({ tutor_id: tutorUser?._id, slot: inputSlot }, 'tutor/tutor_add_session').then(
        (data) => {
          if (data.error === 0) {
            NotificationManager.success(data.message, 'success!', 2000);
            redirectToHome()
            //setMessage(data.message)
            // nextStep()
            //  localStorage.setItem('student_subject', JSON.stringify(data.data))
            // history.push('/tutor');
          }
          console.log(data)

        },
        (error) => {
          NotificationManager.error(error.toString(), 'error!', 2000);
          setError(error.toString())
        }
      )
    }
  }


  const validateForm = () => {

    let fields = inputSlot;
    let errors1 = [{}];
    let data = [...error1];
    let formIsValid = true;
    fields.map((field, index) => {
      let errors = [];
      console.log(index, field)
      if (!field["date"]) {
        formIsValid = false;
        errors["date"] = "*Please select date.";
      }
      if (!field["recurring_option"]) {
        formIsValid = false;
        errors["recurring_option"] = "*Please select Recurring Option.";
      }
      if (!field["subject"]) {
        formIsValid = false;
        errors["subject"] = "*Please select subject.";
      }
      // if (!field["session"]) {
      //   formIsValid = false;
      //   errors["session"] = "*Please select session.";
      // }
      if (!field["start_time"]) {
        formIsValid = false;
        errors["start_time"] = "*Please select start time.";
      }
      if (!field["end_time"]) {
        formIsValid = false;
        errors["end_time"] = "*Please select end time.";
      }
      if (!field["type_of_tutoring_session"]) {
        formIsValid = false;
        errors["type_of_tutoring_session"] = "*Please select tutoring session.";
      }
      console.log('this is error', errors)
      data[index] = errors;
      setError1(data)
    })

    //setError1(errors)
    console.log('this is error1', error1?.[0])
    return formIsValid;


  }


  useEffect(() => {
    const tutorUserz = localStorage.getItem("tutor");
    if (tutorUserz) {
      const foundUser = JSON.parse(tutorUserz);
      console.log(foundUser?._id)
      setTutorUser(foundUser);
      let object = { recurring_option: '', date: '', subject: '', grade_id: '', start_time: '', end_time: '', type_of_tutoring_session: '', tutor_id: foundUser?._id }
      setInputSlot([object])
      School.post({ 'tutor_id': foundUser._id }, 'tutor/get_tutor_selected_subject').then(
        (data) => {
          if (data.error === 0) {
            setSubject(data.data)
          }

        },
        (error) => {
          NotificationManager.error(error.toString(), 'error!', 2000);
          setError(error.toString())
        }
      )
    }
    Student.post({ page: 0 }, 'grades/get_all_grade').then(
      (data) => setGrade(data?.data),
      (error) => setError1(error.toString())
    );
    console.log(error1)
  }, []);
  return (
    <>
      <Header />
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="login-body" style={{ backgroundImage: 'url("images/signup-bg.jpg")', height: '100%', width: 'auto', backgroundPosition: 'center' }}>
          <Container>
            <Row>
              <Col md={12}>
                <div className="step-progress">
                  <div className="step-progress-bar">
                    <div className="step-progress-percent" style={{ width: '100%' }}></div>
                  </div>
                  <div className="step-progress-text">
                    <h6>Profile Completion</h6>
                    <h6>100% Completed</h6>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={9}>
                <Form onSubmit={submitFormData} className="signup-box">
                  <div className="signup-left">
                    <div className="user-are">
                      <div className="user-avatar">
                        {(tutorUser?.user_id?.display_logo == 'yes' && tutorUser?.user_id?.propic) ? (<img src={config.imgBaseUrl + tutorUser?.user_id?.propic} alt="" />) : <img src='images/no_profile_img.png' alt="" />}

                      </div>
                      <div className="user-text">
                        <h4>{tutorUser?.first_name} {tutorUser?.last_name}</h4>
                      </div>
                    </div>
                    <h4>When are you available for your first session?</h4>
                    <p>Select the days and time on which you would like to schedule a tutoring session. Based on your schedule preference we will help match you with a suitable tutor.</p>
                    <div className="signup-step" style={{ marginTop: 202, }}>
                      <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li className="active"></li>
                      </ul>
                    </div>
                  </div>
                  <div className="signup-right">
                    <div className="available-date mt-3">
                      {inputSlot.map((element, index) => (<>
                        <Session1 key={index} index={index} element={element} grade={grade} subject={subject} error1={error1} handleChange={handleChange} selectedDay={selectedDay} addStartTime={addStartTime} addEndTime={addEndTime} removeFields={removeFields} handleChangeSetData={handleChangeSetData} startTime={startTime} endTime={endTime} scheduleDay={scheduleDay} scheduleDate={scheduleDate} />

                      </>))}
                      <Row>
                        <Col md={12} className="text-center">
                          <Button variant="outline-secondary" onClick={() => addFormFields()}><i className="fa fa-calendar" aria-hidden="true" ></i> Schedule another session</Button>
                        </Col>
                      </Row>
                    </div>
                    <div className="signup-back-btn">
                      <Link onClick={prevStep} to="#" className="btn btn-link">Back</Link>
                      <ActionButton type="submit" className="btn btn-primary btn-pill px-4" title={'Continue'}
                        requiredFields={_.values(inputSlot[0])} />

                    </div>
                  </div>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <Footer />


      <Modal show={show} onHide={handleClose} onShow={() => setTimeout(handleClose, 3000)}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add session</Modal.Title>
        </Modal.Header>
        <Modal.Body className='align-item-center'>
          <div className='alert alert-success text-center'>Your session added successfully! please login now</div>
          <div className="text-center">
            <Button variant="primary" onClick={handleClose} className="px-5">
              Okay
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default TutorSignupStepSix
