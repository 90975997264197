import config from "./../config";
import { authHeader } from "./../helpers/auth-header"

export const Admin = {
  registerAdmin,
  postFormData,
  post,
  get,
  put,
  getInactiveSchoolUser,
  changeUserStatus
};

function registerAdmin(reqData) {
  const requestOptions = {
    method: "POST",
  };
  if (reqData) {
    // requestOptions.headers['Content-Type'] = 'multipart/form-data';
    requestOptions.body = reqData;
  }
  return fetch(`${config.apiUrl}users/create_user`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
function postFormData(reqData, url) {
  const requestOptions = {
    method: "POST",
  };
  if (reqData) {
    // requestOptions.headers['Content-Type'] = 'multipart/form-data';
    requestOptions.body = reqData;
  }
  return fetch(`${config.apiUrl}${url}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function post(reqData, url) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };
  if (reqData) {
    requestOptions.headers['Content-Type'] = 'application/json';
    requestOptions.body = JSON.stringify(reqData);
  }
  return fetch(`${config.apiUrl}${url}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
function get(reqData, url) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  if (reqData) {
    requestOptions.headers['Content-Type'] = 'application/json';
    requestOptions.body = JSON.stringify(reqData);
  }
  return fetch(`${config.apiUrl}${url}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
function put(reqData, url) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
  };
  if (reqData) {
    requestOptions.headers['Content-Type'] = 'application/json';
    requestOptions.body = JSON.stringify(reqData);
  }
  return fetch(`${config.apiUrl}${url}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    const error = (data && data.message) || response.message;
    if (error == 'Auth failed') {
      return Promise.reject(error);
    } else {
      if (data && data.error === 1) {
        return Promise.reject(error);
      }
    }
    return data;
  });
}
function getInactiveSchoolUser(role) {
  const authHeaders = authHeader()
  const requestOptions = {
    method: "GET",
    headers: {
      authHeaders,
      'Content-Type': 'application/json'
    },
  };
  return fetch(`${config.apiUrl}users/get-inactive-user/` + role, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
function changeUserStatus(reqData) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };
  if (reqData) {
    requestOptions.headers['Content-Type'] = 'application/json';
    requestOptions.body = JSON.stringify(reqData);
  }
  return fetch(`${config.apiUrl}users/update_status/`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}