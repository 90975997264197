import React, { useEffect, useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  Dropdown,
} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import SchoolSideMenu from "./SchoolSideMenu";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { auto } from '@popperjs/core';
import { FecthData } from '../../Services/FetchData';
import { NotificationManager } from 'react-notifications';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom'
    },
    title: {
      display: true,
      text: '',
    },
  },
};

const labels = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

const data = {
  labels,
  datasets: [
    {
      label: 'Algebra',
      data: {
        MONDAY: 10,
        TUESDAY: 20,
        WEDNESDAY: 32,
        THURSDAY: 24,
        FRIDAY: 55,
        SATURDAY: 42,
        SUNDAY: 5,
      },
      backgroundColor: [
        '#914CE9',
      ],
    },
    {
      label: 'Science',
      data: {
        MONDAY: 42,
        TUESDAY: 20,
        WEDNESDAY: 45,
        THURSDAY: 5,
        FRIDAY: 46,
        SATURDAY: 13,
        SUNDAY: 10,
      },
      backgroundColor: [
        '#FEB41C',
      ],
    },
    {
      label: 'Physics',
      data: {
        MONDAY: 10,
        TUESDAY: 20,
        WEDNESDAY: 32,
        THURSDAY: 24,
        FRIDAY: 59,
        SATURDAY: 42,
        SUNDAY: 5,
      },
      backgroundColor: [
        '#D0202C',
      ],
    },
    {
      label: 'Geometry',
      data: {
        MONDAY: 42,
        TUESDAY: 22,
        WEDNESDAY: 54,
        THURSDAY: 68,
        FRIDAY: 12,
        SATURDAY: 5,
        SUNDAY: 30,
      },
      backgroundColor: [
        '#73913E',
      ],
    },
  ],
};

ChartJS.register(Tooltip, Legend);

function SchoolStudentStat(props) {
  const student = props.location.state
  const [school, setSchool] = useState()
  const selectedDay = val => {
    console.log(val);
  };

  useEffect(() => {
    FecthData.postJsonData({ _id: student?.school_id }, 'school/get_school_by_id').then(
      (data) => setSchool(data?.data),
      (error) => NotificationManager.error(error.toString(), 'error!', 2000)
    )
  }, [])
  let history = useHistory()
  const logout = () => {
    localStorage.clear()
    history.push('/school')
  }
  return (
    <>
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="dashboard-wrap">
          <SchoolSideMenu />
          <div className="middle">
            <Col md={12}>
              <Form.Group className="form-group mb-2">
                <Form.Control type="text" placeholder="Search" className="search-input" />
                <Button variant="primary" type="submit" className="search-btn">
                  <img src="images/search-icon.png" alt="" width="22" />
                </Button>
              </Form.Group>
            </Col>
            <Row>
              <Col md={12}>
                <div className="card">
                  <Row>
                    <Col md={9} className="mt-2">
                      <div className="d-flex align-items-center">
                        <div className="school-logo-small">
                          <img src="images/avatar2.png" alt="" />
                        </div>
                        <div className="ms-1">
                          <h4 className="text-muted mb-0">ID: {student?.student_code}</h4>
                          <h5 className="text-muted mb-0">Grade: {student?.grade_details.length > 0 ? student?.grade_details[0].title : null} | {school?.school_name}</h5>
                        </div>
                      </div>
                    </Col>
                    <Col md={3} className="mt-2">
                      <div className="tutor-action">
                        <ul className="justify-content-end">
                          <li className="active"><a href="javascript:void(0)"><i className="fa fa-comment-o"></i>
                          </a>
                          </li>
                        </ul>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="card bg-purple progress-box">
                        <div className="card-text text-white">
                          <img src="images/hat-icon.png" alt="" width="70" />
                          <h4>Scheduled Classes</h4>
                        </div>
                        <div className="card-big-text">
                          <h5 className="count">15</h5>
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="card bg-danger progress-box">
                        <div className="card-text text-white">
                          <img src="images/book-icon.png" alt="" width="58" />
                          <h4>Assignments Completed</h4>
                        </div>
                        <div className="card-big-text">
                          <h5 className="count">07</h5>
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="card bg-success progress-box">
                        <div className="card-text text-white">
                          <img src="images/timer-icon.png" alt="" width="34" />
                          <h4>Time SPENT On Dasya</h4>
                        </div>
                        <div className="card-big-text">
                          <h5 className="count">14 <small>HRS</small></h5>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <h5 className="sub-heading mt-4 text-muted d-flex justify-content-between">No. Of Hrs Spent Subject Wise
                    <Form.Group style={{ minWidth: 120 }}>
                      <Form.Select>
                        <option>Weekly</option>
                        <option>Monthly</option>
                      </Form.Select>
                    </Form.Group>
                  </h5>
                  <Bar options={options} data={data} />
                </div>
              </Col>
              <Col md={4}>
                <div className="card">
                  <h5 className="sub-heading text-muted">Current Level</h5>
                  <img src="images/progress-icon.png" alt="" width="150" style={{ margin: auto, marginTop: 10, }} />
                </div>
              </Col>
              <Col md={8}>
                <div className="card">
                  <h5 className="sub-heading text-muted d-flex justify-content-between">Badges & Rewards <span><a className=" text-muted" href="/teacher-review">See All</a></span></h5>
                  <div className="badges">
                    <ul>
                      <li>
                        <img src="images/badge1.png" alt="" width="80" />
                        <h6>SCHEDULE 5+ Sessions</h6>
                      </li>
                      <li>
                        <img src="images/badge2.png" alt="" width="50" />
                        <h6>CHAT WITH TUTOR</h6>
                      </li>
                      <li>
                        <img src="images/badge3.png" alt="" width="64" />
                        <h6>REACH A NEW LEVEL</h6>
                      </li>
                      <li>
                        <img src="images/badge4.png" alt="" width="104" />
                        <h6>COMPLETE AN ASSIGNMENT</h6>
                      </li>
                      <li>
                        <img src="images/badge5.png" alt="" width="58" />
                        <h6>EARN TUTOR BADGE</h6>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="right">
            <div className="user-short-text">
              <div className="notification-bell">
                <img src="images/bell-icon.png" alt="" width="32" />
                <div className="noti-dot"></div>
              </div>
              <div className="user-short-profile">
                <div className="user-avatar">
                  <img src="images/no_profile_img.png" width={'100%'} height={'100%'} alt="" />
                </div>
                <div className="user-short-name">
                  <h5>Mather High School</h5>
                  <p>Academic Year 2021-2022</p>
                </div>
                <Dropdown >
                  <Dropdown.Toggle drop='none' variant="">

                  </Dropdown.Toggle >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={logout}
                    // href="#/action"
                    >Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <div className="drop-down">
                  <img src="images/drop-icon.png" alt="" width="10" />
                </div> */}
              </div>
            </div>
            <div className="card">
              <div className="school-side-details">
                <div className="school-logo-circle">
                  <img src="images/avatar3.png" alt="" />
                </div>
                <div className="school-more-option">
                  <Dropdown>
                    <Dropdown.Toggle>
                      <img src="images/more-icon.png" alt="" width="20" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {/* <Dropdown.Item href="#">View Profile</Dropdown.Item>
                      <Dropdown.Divider /> */}
                      {/* <Dropdown.Item href="#" onClick={() => history.push({ pathname: "/school/user/edit", state: { ...student, user_role: 'student', status: student?.user_details[0].status, name: student?.user_details[0].name } })}>Edit Account</Dropdown.Item> */}
                      {/* <Dropdown.Divider /> */}
                      <Dropdown.Item href="#">Delete Account</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="school-name">
                  <h5>{student?.first_name} {student?.last_name}</h5>
                  <h6>Student ID: {student?.student_code}</h6>
                </div>
                <div className="teacher-contact-detail">
                  <ul>
                    <li>
                      <a href="javascript:void(0)">
                        <img src="images/teacher-info1.png" alt="" width="54" />
                      </a>
                    </li>
                    <li className='phone-li'>
                      <a href={`tel:${student?.phone}`} itemRef='ghgf'>
                        <img src="images/teacher-info2.png" alt="" width="54" />
                      </a>
                      {student?.phone ? <span className='show-phone'>{student?.phone}</span> : <span className='show-phone'>Phone Not Available</span>}
                    </li>
                    <li>
                      <a href={`mailto:${student?.email}`}>
                        <img src="images/teacher-info3.png" alt="" width="54" />
                      </a>
                    </li>
                  </ul>
                  <a href="javascript:void(0)" className="btn btn-secondary solid btn-sm no-shadow d-block">Status: {student?.user_details[0]?.status}</a>
                </div>
                <div className="about-teacher">
                  <h4>About:</h4>
                  <p>
                    Lorem ipsum dolor sit, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p>
                  <h4>Gender:</h4>
                  <p>Female</p>
                  <h4>Date of birth</h4>
                  <p>2 Jan 2003</p>
                  <h4>Email:</h4>
                  <p>{student?.email}</p>
                  <h4>Parent’s Email:</h4>
                  <p>{student?.guardian_email}</p>
                  {student?.facilitator_email ? <><h4>Facilitator Email:</h4>
                    <p>{student?.facilitator_email}</p></> : null}
                  <h4>Subjects Interested:</h4>
                  <div className="tutor-subject mt-2">
                    <ul>
                      {student?.subjects.map(subject => <li>
                        {subject}
                      </li>)}
                      <li>
                        Algebra
                      </li>
                      <li>
                        Science
                      </li>
                      <li>
                        Physics
                      </li>
                      <li>
                        Geography
                      </li>
                      <li>
                        Calculus
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default SchoolStudentStat
