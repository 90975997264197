import React, { useState, useEffect } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Stack,
  Modal
} from "react-bootstrap";
import Header from "../Header";
import Footer from "../Footer";
import { NavLink, Link } from "react-router-dom";
import Autocomplete from 'react-autocomplete';
import { School } from '../../Services/School';
import { NotificationManager } from 'react-notifications';
import { Student } from '../../Services/Student';
import config from "./../../config";
import ActionButton from '../ActionButton';


function SignupStepTwo({ nextStep, prevStep, handleFormData, handleContactBy, handleSchool, values }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [value, setValue] = useState('');
  const [gender, setGender] = useState('');
  const [validated, setValidated] = useState(false);
  const [error2, setError2] = useState();
  const [error1, setError1] = useState();

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }

      setValidated(true);
      nextStep();
    }
  };

  const validateForm = () => {

    let fields = values;
    let errors = {};
    let formIsValid = true;

    if (!fields["gender_avatar"]) {
      formIsValid = false;
      errors["gender_avatar"] = "*Please choose your gender.";
    }
    setError2(errors)
    return formIsValid;


  }
  useEffect(() => {
    Student.post({ page: 0 }, 'gender/get-all-gender-avatar').then(
      (data) => setGender(data),
      (error) => setError1(error.toString())
    );


  }, [])
  return (
    <>
      <Header />
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="login-body" style={{ backgroundImage: 'url("images/signup-bg.jpg")', height: '100%', width: 'auto', backgroundPosition: 'center' }}>
          <Container>
            <Row>
              <Col md={12}>
                <div className="step-progress">
                  <div className="step-progress-bar">
                    <div className="step-progress-percent" style={{ width: '35%' }}></div>
                  </div>
                  <div className="step-progress-text">
                    <h6>Account Creation</h6>
                    <h6>35% Completed</h6>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={9}>
                <div className="signup-box">
                  <div className="signup-left">
                    <h4>Choose Your Avatar!</h4>
                    <p>Select an Avatar from our list that best suits you.</p>
                    <div className="signup-step">
                      <ul>
                        <li></li>
                        <li className="active"></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                      </ul>
                    </div>
                  </div>
                  <div className="signup-right">
                    <Form onSubmit={handleSubmit}>
                      <div className="choose-avatar">
                        {gender && gender?.data && gender?.data?.map((content, index) => (<>

                          {content.avatars.length > 0 ? <ul className='slctGrade'>
                            {content?.avatars && content?.avatars.map((avatar, indx) => (
                              <li key={indx} className={values.gender_avatar == avatar._id ? 'selected' : ''} onClick={(e) => handleSchool('gender_avatar', avatar._id)}>
                                <input type="radio" name="gender_avatar" value={avatar._id} onChange={(e) => handleSchool('gender_avatar', avatar._id)} checked={values.gender_avatar == avatar._id} />
                                <img src={config.imgBaseUrl + avatar.icon_image} alt={avatar.name} />
                              </li>
                            ))}

                          </ul> : ''} </>))}
                        <span className="err">{error2?.gender_avatar}</span>
                      </div>
                      <div className="signup-back-btn">
                        <Link onClick={prevStep} to="#" className="btn btn-link">Back</Link>
                        <ActionButton type='submit' className='btn btn-primary btn-pill px-4' requiredFields={[values?.gender_avatar]} title='Continue' />
                        {/* <button type="submit" className="btn btn-primary btn-pill px-4" disabled={!values?.gender_avatar}>Continue</button> */}
                      </div>
                    </Form>
                  </div>

                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default SignupStepTwo
