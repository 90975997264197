import _ from "lodash"
import { useEffect, useState } from "react"
import { Dropdown } from "react-bootstrap"
import { FecthData } from "../../Services/FetchData"
import timeFormate from "../../helpers/time_formate"
import Calendar from "react-calendar"

function TutorSessionCalendar({ dateWiseSessions, selectedDay }) {
  // const selectedDay = val => {
  //     console.log(val);
  //     const date = new Date(val).toLocaleDateString('en-US')
  //     selectDate(new Date(date + 'UTC').toJSON())
  // };
  // const [selectedDate, selectDate] = useState()
  // const [sessions, setSessions] = useState([])
  // const user = JSON.parse(localStorage.getItem('user'))
  // const getStudentSessions = () => {
  //     FecthData.postJsonData({ student_id: user?.student?._id, date: selectedDate }, 'student/get_student_session_by_student_id').then(
  //         (data) => setSessions(data?.data)
  //     )
  // }
  // useEffect(() => {
  //     if (selectedDate)
  //         getStudentSessions()
  // }, [selectedDate])
  // const studentSessions = _.filter(sessions, session => {
  //     if (session?.tutor_session_id) {
  //         return session
  //     }
  // })
  return <div className="upcom-class">
    <h5 className="text-muted">Calendar</h5>
    {/* <DatePicker getSelectedDay={selectedDay}
      endDate={100}
      selectDate={new Date()}
      labelFormat={"MMMM"}
      color={"#287AEB"}
    /> */}
    <Calendar onChange={selectedDay} />
    {dateWiseSessions.length > 0 ? <div className="upcoming-class-schedule">
      <ul>
        {dateWiseSessions?.map(session => <li className="eight-am mb-1">
          <div className="left">
            <p>{timeFormate(session?.start_time)}</p>
          </div>
          <div className="right">
            <div className="classname">
              <h5>{session?.subject?.title}</h5>
              <p>{timeFormate(session?.start_time)}-{timeFormate(session?.end_time)}</p>
            </div>
            {/* <div className="three-dot">
                        <img src="images/three-dot.png" alt="" width="20" />
                      </div> */}
            <Dropdown className="watch-btn-option">
              <Dropdown.Toggle>
                <img src="images/three-dot.png" alt="" width="20" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#">View</Dropdown.Item>
                <Dropdown.Item href="#">Edit</Dropdown.Item>
                <Dropdown.Item href="#">Delete</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </li>)}
        {/* <li className="nine-am">
          <div className="left">
            <p>9 AM</p>
          </div>
          <div className="right">
            <div className="classname">
              <h5>Algebra with Sarah D.</h5>
              <p>9:00 am-9:30 am</p>
            </div>
            <Dropdown className="watch-btn-option">
              <Dropdown.Toggle>
                <img src="images/three-dot.png" alt="" width="20" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#">View</Dropdown.Item>
                <Dropdown.Item href="#">Edit</Dropdown.Item>
                <Dropdown.Item href="#">Delete</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </li>
        <li className="ten-am mt-4">
          <div className="left">
            <p>10 AM</p>
          </div>
          <div className="right">
            <div className="classname">
              <h5>Geography with John M.</h5>
              <p>8:00 am-9:00 am</p>
            </div>
            <Dropdown className="watch-btn-option">
              <Dropdown.Toggle>
                <img src="images/three-dot.png" alt="" width="20" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#">View</Dropdown.Item>
                <Dropdown.Item href="#">Edit</Dropdown.Item>
                <Dropdown.Item href="#">Delete</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </li>
        <li className="eleven-am no-class">
          <div className="left">
            <p>11 AM</p>
          </div>
          <div className="right">
            
          </div>
        </li>
        <li className="twelve-pm">
          <div className="left">
            <p>12 AM</p>
          </div>
          <div className="right">
            <div className="classname">
              <h5>Physics with David G.</h5>
              <p>12:00 pm-1:00 pm</p>
            </div>
            <div className="three-dot">
              <img src="images/three-dot.png" alt="" width="20" />
            </div>
          </div>
        </li>
        <li className="one-pm">
          <div className="left">
            <p>1 PM</p>
          </div>
          <div className="right">
            <div className="classname">
              <h5>Geometry with Jane D.</h5>
              <p>1:00 pm-1:30 pm</p>
            </div>
            <div className="three-dot">
              <img src="images/three-dot.png" alt="" width="20" />
            </div>
          </div>
        </li>
        <li className="no-class">
          <div className="left">
            <p>2 PM</p>
          </div>
          <div className="right">

          </div>
        </li>
        <li className="no-class">
          <div className="left">
            <p>3 PM</p>
          </div>
          <div className="right">

          </div>
        </li>
        <li className="no-class">
          <div className="left">
            <p>4 PM</p>
          </div>
          <div className="right">

          </div>
        </li> */}
      </ul>
    </div> : <p className="text-center m-1" style={{ fontSize: '20px' }}>No upcoming sessions found!</p>}
  </div>
}
export default TutorSessionCalendar