import React, { useEffect, useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Modal,
  DropdownButton,
  Dropdown
} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import AdminSideMenu from "./AdminSideMenu";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { auto } from '@popperjs/core';
import config from '../../config';
import { Admin } from '../../Services/Admin';
import { NotificationManager } from 'react-notifications';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top'
    },
    title: {
      display: true,
      text: '',
    },
  },
};

const labels = ['WEEK 3', 'WEEK 4', 'WEEK 1', 'WEEK 2', 'WEEK 3', 'WEEK 4'];

const data = {
  labels,
  datasets: [
    {
      label: 'Lessons',
      data: ['15', '20', '30', '25', '32', '15'],
      borderColor: 'rgba(51, 64, 212, 0.5)',
      backgroundColor: 'rgba(51, 64, 212, 0.5)',
      fill: {
        target: 'origin',
        above: 'rgba(43, 99, 255, 0.4)',   // Area will be red above the origin
        below: 'rgba(51, 64, 212, 0.4)'    // And blue below the origin
      }
    },
    {
      label: 'Hours',
      data: ['18', '22', '24', '30', '28', '20'],
      borderColor: 'rgba(132, 176, 60, 0.4)',
      backgroundColor: 'rgba(132, 176, 60, 0.4)',
      fill: {
        target: 'origin',
        above: 'rgba(132, 176, 60, 0.4)',   // Area will be red above the origin
        below: 'rgba(132, 176, 60, 0.4)'    // And blue below the origin
      }
    },
  ],
};

ChartJS.register(Tooltip, Legend);

function AdminTutorProfile(props) {
  const user = JSON.parse(localStorage.getItem('user'))
  const tutor = props?.location?.state
  const [school, setSchool] = useState()
  const history = useHistory()
  const selectedDay = val => {
    console.log(val);
  };
  const getSchool = () => {
    Admin.post({ '_id': tutor?.school_id }, 'school/get_school_by_id').then(
      (data) => setSchool(data?.data),
      (error) => {
        // setError(error.toString())
        NotificationManager.error(error.toString(), 'error!', 2000);
      }
    )
  }
  useEffect(() => {
    getSchool()
  }, [])
  const logout = () => {
    localStorage.clear()
    history.push('/admin')
  }
  return (
    <>
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="dashboard-wrap">
          <AdminSideMenu />
          <div className="middle">
            <div className="card">
              <Row>
                <Col md={9} className="mt-2">
                  <div className="d-flex align-items-center">
                    <div className="school-logo-small">
                      <img src="images/teacher-avatar.jpg" alt="" />
                    </div>
                    <div className="ms-1">
                      <h4 className="text-muted mb-0">{tutor?.first_name} | ID: 567876</h4>
                      <h5 className="text-muted mb-0">Grade: 8 | {school?.school_name}</h5>
                    </div>
                  </div>
                </Col>
                <Col md={3} className="mt-2">
                  <div className="tutor-action">
                    <ul className="justify-content-end">
                      <li className="active"><a href="javascript:void(0)"><i className="fa fa-comment-o"></i>
                      </a>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="card bg-purple progress-box">
                    <div className="card-text text-white">
                      <img src="images/hat-icon.png" alt="" width="70" />
                      <h4>Scheduled Classes</h4>
                    </div>
                    <div className="card-big-text">
                      <h5 className="count">15</h5>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="card bg-danger progress-box">
                    <div className="card-text text-white">
                      <img src="images/book-icon.png" alt="" width="58" />
                      <h4>Assignments Completed</h4>
                    </div>
                    <div className="card-big-text">
                      <h5 className="count">07</h5>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="card bg-success progress-box">
                    <div className="card-text text-white">
                      <img src="images/timer-icon.png" alt="" width="34" />
                      <h4>Time Spent On Dasya</h4>
                    </div>
                    <div className="card-big-text">
                      <h5 className="count">14 <small>HRS</small></h5>
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <h5 className="sub-heading mt-4 text-muted d-flex justify-content-between align-items-center">Statistics
                    <Form.Group style={{ minWidth: 120 }}>
                      <Form.Select>
                        <option>Weekly</option>
                        <option>Monthly</option>
                      </Form.Select>
                    </Form.Group>
                  </h5>
                  <Line options={options} data={data} />
                </Col>
              </Row>
            </div>
            <div className="card">
              <Row>
                <Col md={12} className="mb-4">
                  <h6 className="text-muted mt-3">Student’s Ratings</h6>
                </Col>
                <Col md={4}>
                  <div className="student-rating-box">
                    <div className="rating-avatar">
                      <img src="images/avatar2.png" alt="" />
                    </div>
                    <div className="rating-description">
                      <h4>ID: 567876</h4>
                      <h5>8th Grade</h5>
                      <div className="star-count">
                        <img src="images/rating-4.png" alt="" width="184" />
                      </div>
                      <p>
                        It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="student-rating-box">
                    <div className="rating-avatar">
                      <img src="images/avatar2.png" alt="" />
                    </div>
                    <div className="rating-description">
                      <h4>ID: 567876</h4>
                      <h5>8th Grade</h5>
                      <div className="star-count">
                        <img src="images/rating-4.png" alt="" width="184" />
                      </div>
                      <p>
                        It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="student-rating-box">
                    <div className="rating-avatar">
                      <img src="images/avatar2.png" alt="" />
                    </div>
                    <div className="rating-description">
                      <h4>ID: 567876</h4>
                      <h5>8th Grade</h5>
                      <div className="star-count">
                        <img src="images/rating-4.png" alt="" width="184" />
                      </div>
                      <p>
                        It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="right">
            <div className="user-short-text">
              <div className="notification-bell">
                <img src="images/bell-icon.png" alt="" width="32" />
                <div className="noti-dot"></div>
              </div>
              <div className="user-short-profile">
                <div className="user-avatar">
                  {user?.propic && <img src={config.imgBaseUrl + user?.propic} width={'100%'} height={'100%'} alt="" />}{!user?.propic && <img src="images/no_profile_img.png" width={'100%'} height={'100%'} alt="" />}
                </div>
                <div className="user-short-name">
                  <h5>{user?.name} </h5>
                  <p>Status: {user?.role} | {user?.status}</p>
                </div>
                <Dropdown >
                  <Dropdown.Toggle drop='none' variant="">

                  </Dropdown.Toggle >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={logout}
                    // href="#/action"
                    >Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <div className="drop-down">
                  <img src="images/drop-icon.png" alt="" width="10" />
                </div> */}
              </div>
            </div>
            <div className="card">
              <div className="school-side-details">
                <div className="school-logo-circle">
                  <img src="images/teacher-avatar.jpg" alt="" />
                </div>
                <div className="school-more-option">
                  <Dropdown>
                    <Dropdown.Toggle>
                      <img src="images/more-icon.png" alt="" width="20" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {/* <Dropdown.Item href="/admin-tutor-profile">View Profile</Dropdown.Item>
                      <Dropdown.Divider /> */}
                      <Dropdown.Item href="#/action-2">Edit Profile</Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item href="#/action-3">Delete Profile</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="school-name">
                  <h5>{tutor?.first_name} {tutor?.last_name}</h5>
                  <h6>ID: 567825</h6>
                </div>
                <div className="teacher-contact-detail">
                  <ul>
                    <li>
                      <a href="javascript:void(0)">
                        <img src="images/teacher-info1.png" alt="" width="54" />
                      </a>
                    </li>
                    <li className='phone-li'>
                      <a href={`tel:${tutor?.phone}`}>
                        <img src="images/teacher-info2.png" alt="" width="54" />
                      </a>
                      {tutor?.phone ? <span className='show-phone'>{tutor?.phone}</span> : <span className='show-phone'>Phone Not Available</span>}
                    </li>
                    <li>
                      <a href={`mailto:${tutor?.email}`}>
                        <img src="images/teacher-info3.png" alt="" width="54" />
                      </a>
                    </li>
                  </ul>
                  <a href="javascript:void(0)" className={`btn btn-${tutor?.user_details?.status == 'Active' ? 'primary' : 'secondary'} solid btn-sm no-shadow d-block`}>Status: {tutor?.user_details?.status}</a>
                </div>
                <div className="about-teacher">
                  <h4>About:</h4>
                  <p>
                    {tutor?.about}
                  </p>
                  <h4>Experience:</h4>
                  <p>
                    {tutor?.tutoring_experience}
                  </p>
                  <h4>Gender:</h4>
                  <p>Female</p>
                  <h4>Date of birth</h4>
                  <p>2 Jan 2003</p>
                  <h4>Email:</h4>
                  <p>{tutor?.email}</p>
                  <h4>Subjects Interested In Teaching:</h4>
                  <div className="tutor-subject mt-2">
                    <ul>
                      <li>
                        Algebra
                      </li>
                      <li>
                        Science
                      </li>
                      <li>
                        Physics
                      </li>
                      <li>
                        Geography
                      </li>
                      <li>
                        Calculus
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default AdminTutorProfile
