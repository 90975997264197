import React, { useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Stack,
} from "react-bootstrap";
import Header from "../Header";
import Footer from "../Footer";
import { NavLink, Link } from "react-router-dom";
import Autocomplete from 'react-autocomplete';
import validator from "validator";
import ActionButton from '../ActionButton';
import RemoveImage from '../RemoveImage';

function TutorSignupStepTwo({ nextStep, prevStep, handleImage, handleFormData, handleSchool, values }) {
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState();
  const [error1, setError1] = useState();
  const [error2, setError2] = useState();
  const [file, setFile] = useState();
  function handleChange(e) {

    setFile(URL.createObjectURL(e.target.files[0]));
    handleImage(e.target.files[0])
    handleSchool('logo_url', URL.createObjectURL(e.target.files[0]))
    console.log('this is image', e.target.files[0]);
  }
  const removeImage = () => {
    handleSchool('logo_url', '')
  }

  const submitFormData = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (validateForm()) {
      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
      }

      setValidated(true);
      if (
        validator.isEmpty(values.display_logo)
      ) {
        setError1(true);
      } else {
        nextStep();
      }
    }

  };

  const validateForm = () => {

    let fields = values;
    console.log(values)
    let errors = {};
    let formIsValid = true;

    // if (!fields["username"] || fields["username"].trim().length <= 0) {
    //   formIsValid = false;
    //   errors["school_name"] = "*Please select Your school.";
    // }

    if (!fields["display_logo"]) {
      formIsValid = false;
      errors["display_logo"] = "*Please select display OR not.";
    }
    // if (typeof fields["username"] !== "undefined") {
    //   //regular expression for email validation
    //   var pattern = new RegExp(/^[a-zA-Z0-9-]+$/i);
    //   if (!pattern.test(fields["username"])) {
    //     formIsValid = false;
    //     errors["username"] = "*Only characters A-Z, a-z, 0-9 and '-' are  acceptable.";
    //   }
    // }
    setError2(errors)
    return formIsValid;


  }

  return (
    <>
      <Header />
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="login-body" style={{ backgroundImage: 'url("images/signup-bg.jpg")', height: '100%', width: 'auto', backgroundPosition: 'center' }}>
          <Container>
            <Row>
              <Col md={12}>
                <div className="step-progress">
                  <div className="step-progress-bar">
                    <div className="step-progress-percent" style={{ width: '25%' }}></div>
                  </div>
                  <div className="step-progress-text">
                    <h6>Account Creation</h6>
                    <h6>25% Completed</h6>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={9}>
                <div className="signup-box">
                  <div className="signup-left">
                    <h4>Select Profile Image</h4>
                    <p>Your profile image is used as the icon for your personal space and to represent you in Dasya</p>
                    <div className="signup-step">
                      <ul>
                        <li></li>
                        <li className="active"></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                      </ul>
                    </div>
                  </div>
                  <div className="signup-right">
                    <Form onSubmit={submitFormData}>
                      <Row>
                        <Col md={12}>
                          <div className="upload-profile">
                            <div className="upload-profile-img">
                              {values.logo_url && <span><img src={values.logo_url} alt="" style={{ objectFit: 'cover', height: '170px', width: '170px' }} /></span>}{!values.logo_url && <span><img style={{ objectFit: 'cover', height: '170px', width: '170px' }} src="images/no_img.png" alt="" /></span>}
                            </div>
                            <div className="upload-icon">
                              {values.logo_url ? <RemoveImage isHaveImage={values.logo_url} handleClick={removeImage} /> : <><img src="images/camera-icon.png" alt="" width="78" />
                                <input type="file" name="logo" onChange={handleChange} /></>}
                              {/* <Form.Control.Feedback type="invalid">
                                Please chosse a image
                              </Form.Control.Feedback> */}
                            </div>
                          </div>
                        </Col>
                        {/* <Col md={12}>
                          <Form.Group className="form-group my-4">
                            <Form.Control className="bg-grey no-shadow" type="text" name="username" placeholder="Enter an username" onChange={handleFormData("username")} defaultValue={values?.username} />
                            <span className="err">{error2?.username}</span>
                          </Form.Group>
                        </Col> */}
                        <Col md={6}>
                          <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <Form.Check className="text-grey" type="radio" name="display_logo" id="flexRadioDefault1" defaultValue="yes" onChange={handleFormData("display_logo")} checked={values.display_logo === "yes"} label="Display my image on my profile" />
                            <span className="err">{error2?.display_logo}</span>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="mb-3" controlId="formBasicCheckbox1">
                            <Form.Check className="text-grey" type="radio" name="display_logo" id="flexRadioDefault2" defaultValue="no" onChange={handleFormData("display_logo")} checked={values.display_logo === "no"} label="Don’t display my image on my profile" />
                          </Form.Group>
                        </Col>
                      </Row>
                      <div className="signup-back-btn">
                        <Link onClick={prevStep} to="#" className="btn btn-link">Back</Link>
                        <ActionButton type={'submit'} className={'btn btn-primary btn-pill px-4'} title={'Continue'}
                          requiredFields={[values?.display_logo == 'yes' ? values?.logo_url : 'No']} />
                        {/* <button type="submit" className="btn btn-primary btn-pill px-4">Continue</button> */}
                      </div>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <Footer />

    </>
  )
}

export default TutorSignupStepTwo
