import React, { useState, useEffect } from 'react'
import {
  Container,
  Col,
  Row,
  Form
} from "react-bootstrap";
import Header from "../Header";
import Footer from "../Footer";
import { Link, useParams } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import { School } from '../../Services/School';
import { FecthData } from '../../Services/FetchData';
import ActionButton from '../ActionButton';
import config from '../../config';

function ParSignupStepTwo({ nextStep, prevStep, handleFormData, values, handleSchool }) {
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(null);
  const [student, setStudent] = useState([]);
  const [selectedStudent, selectStudent] = useState();
  let { id } = useParams();

  const submitFormData = (e) => {
    console.log('submit called: ::', selectedStudent?._id)
    e.preventDefault();
    handleSchool('student_id', selectedStudent?._id,)
    nextStep();
  };
  const getStudentInfo = (guardian_email) => {
    FecthData.postJsonData({ parent_email: guardian_email }, 'student/get_all_child_by_parent_email').then(
      (data) => setStudent(data?.data)
    )
    // School.post({ token: id }, 'guardian/get_students_by_token').then(
    //   (data) => {
    //     if (data.error === 0) {
    //       setStudent(data.data)
    //     }
    //     console.log(data)

    //   },
    //   (error) => {
    //     NotificationManager.error(error.toString(), 'error!', 2000);
    //     setError(error.toString())
    //   }
    // )
  }
  useEffect(() => {
    getStudentInfo(values?.guardian_email)
  }, [values?.guardian_email])
  console.log(selectedStudent)
  return (
    <>
      <Header />
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="login-body" style={{ backgroundImage: 'url("images/signup-bg.jpg")', height: '100%', width: 'auto', backgroundPosition: 'center' }}>
          <Container>
            <Row>
              <Col md={12}>
                <div className="step-progress">
                  <div className="step-progress-bar">
                    <div className="step-progress-percent" style={{ width: '50%' }}></div>
                  </div>
                  <div className="step-progress-text">
                    <h6>Select Child’s Profile </h6>
                    <h6>50% Completed</h6>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={9}>
                <div className="signup-box">
                  <div className="signup-left">
                    <h4>Select Child’s Account</h4>
                    <p>Lorem ipsum dolor sit, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <div className="signup-step">
                      <ul>
                        <li></li>
                        <li className="active"></li>
                        <li></li>
                        <li></li>
                      </ul>
                    </div>
                  </div>
                  <div className="signup-right">
                    <Form onSubmit={submitFormData}>
                      <div className="user-avatar">
                        <div className="label-wrap">
                          {student.map((value, index) => <><input type="radio" name="child" value={value} id={index} onChange={() => selectStudent(value)} />
                            <label htmlFor={index}>
                              <div className="child-avatar">
                                <img src={config.imgBaseUrl + value.gender[0]?.icon_image} alt="" />
                                {/* <img src="images/avatar1.png" alt="" /> */}
                              </div>
                              <h6>{value.first_name} {value.last_name} | ID: {value?.student_code}</h6>
                              <p>Academic Year {value?.academic_year}</p>
                            </label></>)}
                          {/* <input type="radio" name="child" value='two' id="two" />
                          <label htmlFor="two">
                            <div className="child-avatar">
                              <img src="images/avatar2.png" alt="" />
                            </div>
                            <h6>Jane Doe | ID: 567876</h6>
                            <p>Academic Year 2021-2022</p>
                          </label>
                          <input type="radio" name="child" value='three' id="three" />
                          <label htmlFor="three">
                            <div className="child-avatar">
                              <img src="images/avatar3.png" alt="" />
                            </div>
                            <h6>Jane Doe | ID: 567876</h6>
                            <p>Academic Year 2021-2022</p>
                          </label>
                          <input type="radio" name="child" value='four' id="four" />
                          <label htmlFor="four">
                            <div className="child-avatar">
                              <img src="images/avatar1.png" alt="" />
                            </div>
                            <h6>Jane Doe | ID: 567876</h6>
                            <p>Academic Year 2021-2022</p>
                          </label>
                          <input type="radio" name="child" value='five' id="five" />
                          <label htmlFor="five">
                            <div className="child-avatar">
                              <img src="images/avatar3.png" alt="" />
                            </div>
                            <h6>Jane Doe | ID: 567876</h6>
                            <p>Academic Year 2021-2022</p>
                          </label> */}
                        </div>
                      </div>
                      <div className="signup-back-btn">
                        <Link onClick={prevStep} className="btn btn-link">Back</Link>
                        <ActionButton type={'submit'} className={'btn btn-primary btn-pill px-4'} requiredFields={[selectedStudent ? selectedStudent : '']} title={'Continue'} />
                        {/* <Link onClick={nextStep} className="btn btn-primary btn-pill px-4">Continue</Link> */}
                      </div>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default ParSignupStepTwo
