const timeFormate = (time) => {
    console.log('time formate called', time)
    if (time?.length <= 5) {
        const timeArr = time.split(':')
        const hh = timeArr[0] > 12 ? timeArr[0] - 12 : timeArr[0]
        const mm = timeArr[1]
        const am_pm = timeArr[0] >= 12 ? 'PM' : 'AM'
        return hh + ':' + mm + am_pm
    } else {
        const timeArr = []
        var date = new Date(time)
        var hhmmss = date.toLocaleTimeString()
        var hh = date.getUTCHours() > 12 ? date.getUTCHours() - 12 : date.getUTCHours()
        var mm = date.getUTCMinutes()
        var time1 = date.getTime()
        const am_pm = date.getUTCHours() > 12 ? ' PM' : ' AM'
        console.log(hh + " " + mm + ' ' + time1 + " hhmmss: " + hhmmss)
        return ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2) + am_pm
    }
}
export default timeFormate

