import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { useEffect, useState } from 'react';

export default function TimeSelect({index,onChange,name,label}) {
    // const onChange=(val)=>{
    //     console.log(val)
    // }

  return <TimePicker disableClock={true} clearIcon={''} format='hh:mm aa' label={label} name={name} onChange={e=>onChange(index,{target:{
    name:name,
    value:e
  }})} value={name=='start_time'?'10:00':'11:00'} />

}