import { useState } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap"
import { Auth } from "../Services/Auth";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ForgotPassword = ({ show, handleClose}) => {
    const [validated, setValidated] = useState(false);
    const [message, setMessage]=useState(null)
    const history=useHistory()
    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        console.log(event.target)
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        Auth.passwordReset({email:event.target.email.value}).then(
            (data)=>{
                setMessage(data.message)
                // setInterval(handleClose, 1000);
            }
        )
    }

    return <Modal show={show} onHide={handleClose}
        size="sm"
        centered
    >
        <Modal.Header closeButton>
            <Modal.Title>Forgot Password?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="text-center mb-3">
                <img src="/images/lock-pass-icon.png" alt="" width="79.4" />
            </div>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group className="form-group mb-3" as={Col} md="12" controlId="validationCustom01">
                    <Form.Control
                        required
                        type="email"
                        name="email"
                        placeholder="Email"
                        defaultValue=""
                    />
                    <span className="form-icon">
                        <img src="/images/user-icon.png" alt="" />
                    </span>
                </Form.Group>
                <p className="modal-text text-center">
                    {message}
                </p>
                <div className="text-center">
                    <Button variant="primary" type="submit" className="px-5">
                        Reset
                    </Button>
                </div>
            </Form>
        </Modal.Body>
    </Modal>
}
export default ForgotPassword