import React, { useEffect, useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Modal,
  DropdownButton,
  Dropdown
} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import AdminSideMenu from "./AdminSideMenu";
import config from '../../config';
import { FecthData } from '../../Services/FetchData';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function AdminAuditLog() {
  const user = JSON.parse(localStorage.getItem('user'))
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [audit, setAudit] = useState([])
  const [error, setError] = useState()
  const history = useHistory();

  useEffect(() => {
    console.log('useEffect AuditLog')
    FecthData.getData('users/get_all_activities').then(
      (data) => setAudit(data.data),
      (error) => setError(error.toString())
    );
  }, [])
  const getDateAndTime = (Timestamp) => {
    const date1 = new Date(Timestamp)
    console.log(date1.toLocaleTimeString())
    const timeArr = Timestamp.split("T")
    const date = date1.toLocaleDateString('en-US')
    const time = date1.toLocaleTimeString('en-US')
    return { date, time }
  }
  const logout = () => {
    localStorage.clear()
    history.push('/admin')
  }
  return (
    <>
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="dashboard-wrap">
          <AdminSideMenu />
          <div className="middle">
            <Row>
              <Col md={12}>
                <Form.Group className="form-group mb-2">
                  <Form.Control type="text" placeholder="Search" className="search-input" />
                  <Button variant="primary" type="submit" className="search-btn">
                    <img src="images/search-icon.png" alt="" width="22" />
                  </Button>
                </Form.Group>
              </Col>
            </Row>
            <div className="card">
              <Row>
                <Col md={5} className="mt-2">
                  <div className="d-flex align-items-center">
                    <div className="school-logo-small">
                      <img src="images/school-logo3.png" alt="" />
                    </div>
                    <h4 className="text-muted mb-0" style={{ width: '58%' }}>User Role: Student/Mentee</h4>
                  </div>
                </Col>
                <Col md={7} className="mt-4">
                  <div className="filters">
                    <ul>
                      <li>
                        <Form.Group>
                          <Form.Select>
                            <option value="0">Student/Mentee</option>
                            <option value="1">All</option>
                          </Form.Select>
                        </Form.Group>
                      </li>
                      <li>
                        <Form.Group>
                          <Form.Select>
                            <option value="0">Organization Type</option>
                            <option value="1">All</option>
                            <option value="2">School</option>
                            <option value="3">Organization</option>
                            <option value="4">DasyaUSA</option>
                          </Form.Select>
                        </Form.Group>
                      </li>

                      <li>
                        <Form.Group>
                          <Form.Select>
                            <option value="0">View By</option>
                            <option value="1">Week</option>
                            <option value="2">Month</option>
                            <option value="3">Quarter</option>
                            <option value="4">Year</option>
                          </Form.Select>
                        </Form.Group>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">User Function</th>
                          <th scope="col">User Activity</th>
                          <th scope="col">User Email</th>
                          <th scope="col">User Action</th>
                          <th scope="col">Activity Date
                            <small>(MMDDYYYY)</small>
                          </th>
                          <th scope="col">Timestamp
                            <small>(HH:MM:SS:SS)</small>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {audit.map(item => <tr>
                          <td>{item.activity_type}</td>
                          <td>
                            {item.status}
                          </td>
                          <td>
                            {item.user_id.email}
                          </td>
                          <td>
                            <select>
                              <option selected>Add</option>
                              <option>Maintain</option>
                              <option>Inquiry</option>
                              <option>Delete</option>
                            </select>
                          </td>
                          <td>
                            {getDateAndTime(item.createdAt).date}
                          </td>
                          <td>
                            {getDateAndTime(item.createdAt).time}
                          </td>
                        </tr>)}
                        <tr>

                          <td>Account Creation</td>
                          <td>
                            Pending Parental Consent
                          </td>
                          <td>
                            JF4567
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option selected>Maintain</option>
                              <option>Inquiry</option>
                              <option>Delete</option>
                            </select>
                          </td>
                          <td>
                            11/01/2021
                          </td>
                          <td>
                            19:18:25:10
                          </td>
                        </tr>
                        <tr>
                          <td>Dashboard</td>
                          <td>
                            Accessed Schedule
                          </td>
                          <td>
                            JF4567
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Maintain</option>
                              <option selected>Inquiry</option>
                              <option>Delete</option>
                            </select>
                          </td>
                          <td>
                            11/01/2021
                          </td>
                          <td>
                            19:18:25:10
                          </td>
                        </tr>
                        <tr>
                          <td>My Schedule</td>
                          <td>
                            Profile Creation Successful
                          </td>
                          <td>
                            JF4567
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Maintain</option>
                              <option selected>Inquiry</option>
                              <option>Delete</option>
                            </select>
                          </td>users/get_all_activities
                          <td>
                            11/01/2021
                          </td>
                          <td>
                            19:18:25:10
                          </td>
                        </tr>
                        <tr>
                          <td>Login</td>
                          <td>
                            Login Successful
                          </td>
                          <td>
                            JF4567
                          </td>
                          <td>
                            <select>
                              <option selected>Add</option>
                              <option>Maintain</option>
                              <option>Inquiry</option>
                              <option>Delete</option>
                            </select>
                          </td>
                          <td>
                            11/01/2021
                          </td>
                          <td>
                            19:18:25:10
                          </td>
                        </tr>
                        <tr>
                          <td>Account Creation</td>
                          <td>
                            Pending Parental Consent
                          </td>
                          <td>
                            JF4567
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option selected>Maintain</option>
                              <option>Inquiry</option>
                              <option>Delete</option>
                            </select>
                          </td>
                          <td>
                            11/01/2021
                          </td>
                          <td>
                            19:18:25:10
                          </td>
                        </tr>
                        <tr>
                          <td>Dashboard</td>
                          <td>
                            Accessed Schedule
                          </td>
                          <td>
                            JF4567
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Maintain</option>
                              <option selected>Inquiry</option>
                              <option>Delete</option>
                            </select>
                          </td>
                          <td>
                            11/01/2021
                          </td>
                          <td>
                            19:18:25:10
                          </td>
                        </tr>
                        <tr>
                          <td>My Schedule</td>
                          <td>
                            Profile Creation Successful
                          </td>
                          <td>
                            JF4567
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Maintain</option>
                              <option selected>Inquiry</option>
                              <option>Delete</option>
                            </select>
                          </td>
                          <td>
                            11/01/2021
                          </td>
                          <td>
                            19:18:25:10
                          </td>
                        </tr>
                        <tr>
                          <td>Login</td>
                          <td>
                            Login Successful
                          </td>
                          <td>
                            JF4567
                          </td>
                          <td>
                            <select>
                              <option selected>Add</option>
                              <option>Maintain</option>
                              <option>Inquiry</option>
                              <option>Delete</option>
                            </select>
                          </td>
                          <td>
                            11/01/2021
                          </td>
                          <td>
                            19:18:25:10
                          </td>
                        </tr>
                        <tr>
                          <td>Account Creation</td>
                          <td>
                            Pending Parental Consent
                          </td>
                          <td>
                            JF4567
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option selected>Maintain</option>
                              <option>Inquiry</option>
                              <option>Delete</option>
                            </select>
                          </td>
                          <td>
                            11/01/2021
                          </td>
                          <td>
                            19:18:25:10
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
            </div>

          </div>
          <div className="right">
            <div className="user-short-text">
              <div className="notification-bell">
                <img src="images/bell-icon.png" alt="" width="32" />
                <div className="noti-dot"></div>
              </div>
              <div className="user-short-profile">
                <div className="user-avatar">
                  {user?.propic && <img src={config.imgBaseUrl + user?.propic} width={'100%'} height={'100%'} alt="" />}{!user?.propic && <img src="images/no_profile_img.png" width={'100%'} height={'100%'} alt="" />}
                </div>
                <div className="user-short-name">
                  <h5>{user?.name} </h5>
                  <p>Status: {user?.role} | {user?.status}</p>
                </div>
                <Dropdown >
                  <Dropdown.Toggle drop='none' variant="">

                  </Dropdown.Toggle >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={logout}
                    // href="#/action"
                    >Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <div className="drop-down">
                  <img src="images/drop-icon.png" alt="" width="10" />
                </div> */}
              </div>
            </div>
            {/* <div className="card">
              <div className="school-side-details">
                <div className="school-logo-circle">
                  <img src="images/school-logo3.png" alt="" />
                </div>
                <div className="school-more-option">
                  <Dropdown>
                    <Dropdown.Toggle>
                      <img src="images/more-icon.png" alt="" width="20" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action">View Profile</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="school-name">
                  <h5>Mather High School</h5>
                  <h6>ID: 567872</h6>
                  <a href="#" className="btn btn-primary solid btn-sm no-shadow d-block">Activate Account</a>
                </div>
                <Form>
                  <Row>
                    <Form.Group className="form-group mb-3" as={Col} md="6">
                      <Form.Control type="text" value="John" />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="6">
                      <Form.Control type="text" value="Doe" />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="12">
                      <Form.Control type="text" value="Dean" />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="12">
                      <Form.Control type="text" value="5835 N Lincoln Ave, Chicago, IL 60659, USA" />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="6">
                      <Form.Select className="bg-grey">
                        <option>State</option>
                        <option value="1" selected>Illinois</option>
                        <option value="2">Illinois</option>
                        <option value="3">Illinois</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="6">
                      <Form.Control type="text" value="60659" />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="12">
                      <Form.Control type="tel" value="312-672-8763" />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="12">
                      <Form.Control type="email" value="mather@edu" />
                    </Form.Group>
                    {/* <Col md={12}>
                      <Form.Group className="iagree mb-2" controlId="formbymail">
                        <Form.Check className="text-grey" type="checkbox" label="Contact Me By Email" checked />
                      </Form.Group>
                      <Form.Group className="iagree mb-2" controlId="formbyphone">
                        <Form.Check className="text-grey" type="checkbox" label="Contact Me By Phone" />
                      </Form.Group>
                    </Col>
                    <Col md="6" className="text-center mt-4">
                      <Button className="px-4 btn-pill no-shadow" variant="primary">Admit</Button>
                    </Col>
                    <Col md="6" className="text-center mt-4">
                      <Button className="px-4 btn-pill" variant="secondary">DECLINE</Button>
                    </Col> // closing comment teg
                  </Row>
                </Form>
              </div>
            </div> */}
          </div>
        </div>
      </section>

      {/* <Modal show={show} onHide={handleClose}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add User Function </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
          <div className="modal-form">
            <Row>
              <Form.Group className="form-group mb-3" as={Col} md="12">
                <Form.Control type="text" placeholder="School Name" />
              </Form.Group>
              <Form.Group className="form-group mb-3" as={Col} md="6">
                <Form.Control type="text" placeholder="Point of Contact First Name" />
              </Form.Group>
              <Form.Group className="form-group mb-3" as={Col} md="6">
                <Form.Control type="text" placeholder="Point of Contact Last Name" />
              </Form.Group>
              <Form.Group className="form-group mb-3" as={Col} md="12">
                <Form.Control type="text" placeholder="School Address" />
              </Form.Group>
              <Form.Group className="form-group mb-3" as={Col} md="6">
                <Form.Select>
                  <option>State</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="form-group mb-3" as={Col} md="6">
                <Form.Control type="text" placeholder="Zip Code" />
              </Form.Group>
              <Form.Group className="form-group mb-3" as={Col} md="12">
                <Form.Control type="text" placeholder="Contact Number" />
              </Form.Group>
              <Form.Group className="form-group mb-3" as={Col} md="12">
                <Form.Control type="email" placeholder="Email" />
              </Form.Group>
              <Col md="12" className="text-center mt-2">
                <Button className="px-4 btn-pill" variant="primary">Send Request</Button>
              </Col>
            </Row>
          </div>
        </Form>
      </Modal.Body>
    </Modal> */}

    </>
  )
}

export default AdminAuditLog
