import config from "./../config";
import {authHeader} from "./../helpers/auth-header"

export const School = {
    getSchool,
    sendEmail,
    verifyOtp,
    registerSchool,
    get,
    post,
    put
};

function getSchool(reqData) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqData),
    };
    return fetch(`${config.apiUrl}school/get_all_school`, requestOptions)
      .then(handleResponse)
      .then((data) => {
        return data;
      });
  }

  function sendEmail(reqData) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqData),
    };
    return fetch(`${config.apiUrl}account_otp_verification_send_otp`, requestOptions)
      .then(handleResponse)
      .then((data) => {
        return data;
      });
  }
  
  function verifyOtp(reqData) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqData),
    };
    return fetch(`${config.apiUrl}account_otp_verify`, requestOptions)
      .then(handleResponse)
      .then((data) => {
        return data;
      });
  }

  function registerSchool(reqData) {
      const requestOptions = {
        method: "POST",
      };
      if(reqData) {
       // requestOptions.headers['Content-Type'] = 'multipart/form-data';
        requestOptions.body = reqData;
       }
      return fetch(`${config.apiUrl}school_user/register`, requestOptions)
        .then(handleResponse)
        .then((data) => {
          return data;
        });

      
  }

  function post(reqData,url){
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
    };
    if(reqData) {
      requestOptions.headers['Content-Type'] = 'application/json';
      requestOptions.body = JSON.stringify(reqData);
     }
    return fetch(`${config.apiUrl}${url}`, requestOptions)
      .then(handleResponse)
      .then((data) => {
        return data;
      });
  }
  function get(reqData,url){
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };
    if(reqData) {
      requestOptions.headers['Content-Type'] = 'application/json';
      requestOptions.body = JSON.stringify(reqData);
     }
    return fetch(`${config.apiUrl}${url}`, requestOptions)
      .then(handleResponse)
      .then((data) => {
        return data;
      });
  }
  function put(reqData,url){
    const requestOptions = {
      method: "PUT",
      headers: authHeader(),
    };
    if(reqData) {
      requestOptions.headers['Content-Type'] = 'application/json';
      requestOptions.body = JSON.stringify(reqData);
     }
    return fetch(`${config.apiUrl}${url}`, requestOptions)
      .then(handleResponse)
      .then((data) => {
        return data;
      });
  }

  function handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      const error = (data && data.message) || response.message;
      if(error =='Auth failed'){
        return Promise.reject(error); 
      }else{
        if (data && data.error === 1 ) {
            return Promise.reject(error);   
        }
      }
      return data;
    });
  }