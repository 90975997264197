import React, { useState, useEffect } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Stack,
  Modal
} from "react-bootstrap";
import Header from "../Header";
import Footer from "../Footer";
import config from "./../../config";
import { NavLink, Link, useHistory } from "react-router-dom";
import Autocomplete from 'react-autocomplete';
import { NotificationManager } from 'react-notifications';
import { School } from '../../Services/School';
import { Student } from '../../Services/Student';
import { Next } from 'react-bootstrap/esm/PageItem';
import ActionButton from '../ActionButton';


function TutorSignupStepFive({ nextStep, handleFormData, prevStep, values, addYourSubject }) {
  const [value, setValue] = useState('');
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState('');
  const [tutorUser, setTutorUser] = useState();
  const [subject, setSubject] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState([]);
  const [filterParam, setFilterParam] = useState(["All"]);
  const [searchParam] = useState(["title", "subjects"]);
  const [q, setQ] = useState("");
  const [message, setMessage] = useState(null);
  let history = useHistory();

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  const selectSubject = e => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedSubject([
        ...selectedSubject, value
      ])
    } else {
      setSelectedSubject((prevState) =>
        prevState.filter((fruit) => fruit !== value)
      );
    }
    console.log('this is data ', e.target)
    addYourSubject(e)
  }
  const submitFormData = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append('subjects', values.subjects.toString());
    formData.append('tutor_id', tutorUser?._id);
    Student.post(formData, 'tutor/tutor_add_subject').then(
      (data) => {
        if (!data.errors) {
          NotificationManager.success(data.message, 'success!', 2000);
          setMessage(data.message)
          nextStep()
          //  localStorage.setItem('student_subject', JSON.stringify(data.data))
          //  history.push('/school');
        } else {
          NotificationManager.error(data.message.toString(), 'error!', 2000);
          setMessage(data.message)
        }
        console.log(data)

      },
      (error) => {
        NotificationManager.error(error.toString(), 'error!', 2000);
        setError(error.toString())
      }
    )
  }


  function search(subject) {
    return subject.filter((item) => {

      /*
      // in here we check if our region is equal to our c state
      // if it's equal to then only return the items that match
      // if not return All the countries
      */

      return searchParam.some((newItem) => {

        // if(q !==''){
        return item.subjects.filter((x) => {

          return Object.keys(x).some((k) => {
            return (x.title.toLowerCase().includes(q.toLowerCase()) > -1)
          })
        })
        // x[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
        // }else{
        //   console.log('this is item ',item.title)
        //   return (
        //          item[newItem]
        //              .toString()
        //              .toLowerCase()
        //              .indexOf(item.title.toLowerCase()) > -1
        //      );
        //  }

        //  return (
        //      item[newItem]
        //          .toString()
        //          .toLowerCase()
        //          .indexOf(q.toLowerCase()) > -1
        //  );
      });
      // if (item.title == filterParam) {
      //     return searchParam.some((newItem) => {
      //       return (
      //         item[newItem]
      //             .toString()
      //             .toLowerCase()
      //             .indexOf(q.toLowerCase()) > -1
      //                  );
      //              });
      //          } else if (filterParam == "All") {
      //              return searchParam.some((newItem) => {

      //              // if(q !==''){
      //                 return item.subjects.filter( (x) => Object.keys(x).some(k => x.title.toLowerCase().includes(q.toLowerCase()) > -1) )
      //                // x[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      //               // }else{
      //               //   console.log('this is item ',item.title)
      //               //   return (
      //               //          item[newItem]
      //               //              .toString()
      //               //              .toLowerCase()
      //               //              .indexOf(item.title.toLowerCase()) > -1
      //               //      );
      //               //  }

      //                 //  return (
      //                 //      item[newItem]
      //                 //          .toString()
      //                 //          .toLowerCase()
      //                 //          .indexOf(q.toLowerCase()) > -1
      //                 //  );
      //              });
      //          }
    });
  }

  useEffect(() => {
    const tutorUserz = localStorage.getItem("tutor");
    if (tutorUserz) {
      const foundUser = JSON.parse(tutorUserz);
      setTutorUser(foundUser);
    }

    School.post({}, 'streem_and_subject/get_all_streem_wise_subject').then(
      (data) => {
        if (data.error === 0) {
          setSubject(data.data)
        }
      },
      (error) => {
        NotificationManager.error(error.toString(), 'error!', 2000);
        setError(error.toString())
      }
    )
    console.log(subject)
    console.log('values ', values)
  }, []);

  return (
    <>
      <Header />
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="login-body" style={{ backgroundImage: 'url("images/signup-bg.jpg")', height: '100%', width: 'auto', backgroundPosition: 'center' }}>
          <Container>
            <Row>
              <Col md={12}>
                <div className="step-progress">
                  <div className="step-progress-bar">
                    <div className="step-progress-percent" style={{ width: '100%' }}></div>
                  </div>
                  <div className="step-progress-text">
                    <h6>Profile Completion</h6>
                    <h6>100% Completed</h6>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={9}>
                <div className="signup-box">
                  <div className="signup-left">
                    <div className="user-are">
                      <div className="user-avatar">
                        {(tutorUser?.user_id?.display_logo == 'yes' && tutorUser?.user_id?.propic) ? (<img src={config.imgBaseUrl + tutorUser?.user_id?.propic} alt="" />) : <img src='images/no_profile_img.png' alt="" />}
                      </div>
                      <div className="user-text">
                        <h4>{tutorUser?.first_name} {tutorUser?.last_name}</h4>
                      </div>
                    </div>
                    <h4>What subjects would you like to teach?</h4>
                    <p>What are the subjects of your highest expertise? What are your favorite subjects to teach?</p>
                    <p>Approved subjects will appear in your profile. You may also opt out of any subjects you don’t want to teach anytime.</p>
                    <div className="signup-step" style={{ marginTop: 160, }}>
                      <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li className="active"></li>
                        <li></li>
                      </ul>
                    </div>
                  </div>
                  <div className="signup-right">
                    <Form onSubmit={submitFormData}>
                      <Form.Group className="form-group mb-2" style={{ zIndex: 2 }}>
                        {/* <Form.Control type="text" placeholder="Search" /> */}
                        <Autocomplete className="search"
                          items={[
                            { label: 'Algebra' },
                            { label: 'Geometry' },
                            { label: 'Calculus' },
                            { label: 'Biology' },
                            { label: 'Chemistry' },
                            { label: 'Physics' },
                          ]}
                          shouldItemRender={(item, value
                          ) => item.label.toLowerCase()
                            .indexOf(value.toLowerCase()) > -1}
                          getItemValue={item => item.label}
                          renderItem={(item, isHighlighted) =>
                            <div style={{
                              background: isHighlighted ?
                                '#eee' : 'white',
                              padding: '5px 10px',
                            }}
                              key={item.id}>
                              {item.label}
                            </div>
                          }
                          value={value}
                          onChange={e => setValue(e.target.value)}
                          onSelect={(val) => setValue(val)}
                          inputProps={{
                            style: {
                              width: '100%',
                              background: '#fff',
                              boxShadow: '0 0 8px rgba(0,0,0,0.25)',
                              width: '100%',
                              color: '#000',
                              borderRadius: 10,
                              minHeight: 52,
                              border: '1px solid #E9ECF4',
                              fontSize: 14,
                              paddingLeft: 15,
                              paddingRight: 15,
                            },
                            placeholder: 'Search'
                          }}
                          wrapperStyle={{
                            width: '100%',
                          }}
                        />
                        <Button variant="primary" type="submit" className="search-btn">
                          <img src="images/search-icon.png" alt="" width="22" />
                        </Button>
                      </Form.Group>
                      <div className="subject-name">
                        {subject && subject?.length > 0 && <>
                          {search(subject).map((content, index) => (<>
                            {content?.subjects.length > 0 && <><h6 key={index} className="text-muted">{content?.title}</h6>
                              <div className="label-wrap">
                                {content?.subjects?.map((subjectz, i) => (<>
                                  <input type="checkbox" name={`subject`} onChange={(e) => selectSubject(e)} value={subjectz._id} id={content?.title + i} checked={(selectedSubject.includes(subjectz._id) || values.subjects.includes(subjectz._id))} />
                                  {/* <Form.Check name={`subject${i}`} type="checkbox" onChange={(e)=>selectSubject(e)}  checked={ (selectedSubject.includes(subjectz._id) || values.subjects.includes(subjectz._id) ) } value={subjectz._id} /> */}
                                  <label key={i} htmlFor={content?.title + i}>
                                    <div className="sub-img">
                                      <img src={config.imgBaseUrl + subjectz?.icon_image} alt="" />
                                    </div>
                                    <h6 className="green">{subjectz?.title}</h6>
                                  </label>
                                </>))}
                              </div>
                            </>}
                          </>))}
                        </>}


                      </div>
                      <div className="signup-back-btn">
                        <ActionButton type="submit" className="btn btn-primary btn-pill px-4" title={'Continue'}
                          requiredFields={values?.subjects} />
                        {/* <button type="submit"  className="btn btn-primary btn-pill px-4">Continue</button> */}
                      </div>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default TutorSignupStepFive
