import React, { useEffect, useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Stack,
} from "react-bootstrap";
import Header from "../Header";
import Footer from "../Footer";
import { NavLink, Link } from "react-router-dom";
import Autocomplete from 'react-autocomplete'
import { Organization } from '../../Services/Organization';
import AdminSignupStepOne from './AdminSignupStepOne';
import AdminSignupStepTwo from './AdminSignupStepTwo';
import AdminSignupStepThree from './AdminSignupStepThree';
import AdminSignupStepFour from './AdminSignupStepFour';
import { buildUser } from '../../helpers/user-builder';

function OrgRegistration(props) {
  const data = props.location.state ? props.location.state : null
  const [step, setstep] = useState(1);
  let userData = buildUser(data)
  // if(data){
  //   userData=buildUser(data)
  // }
  const [formData, setFormData] = useState({
    ...userData,
    company: "",
    company_id: "",
    company_name: "",
    company_ein: "",
    company_address: "",
    company_city: "",
    company_state: "",
    company_zip: "",
    address: "",
    // username: data?data.email.replace(/.com|@gmail/g,""):"",
    logo: "",
    logo_url: "",
    display_logo: "yes",
    // first_name: data?data.given_name:"",
    // last_name: data?data.family_name:"",
    // email:data?data.email: "",
    department: "",
    contact_number: "",
    contact_by: [],
    accept_privacy_policy: false,
    // source:data?data.source:null
  })


  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    setstep(step + 1);
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setstep(step - 1);
  };

  // handling form input data by taking onchange value and updating our previous form data state
  const handleInputData = input => e => {
    console.log(e.target)
    // input value from the form
    const { value } = e.target;

    //updating for data state taking previous state and then adding new value to create new object
    setFormData(prevState => ({
      ...prevState,
      [input]: value
    }));
  }
  function handleImage(newValue) {
    console.log(newValue)
    setFormData(prevState => ({
      ...prevState,
      ['logo']: newValue
    }));
  }

  function handleCompany(name, newValue) {
    console.log(newValue)
    setFormData(prevState => ({
      ...prevState,
      [name]: newValue
    }));
  }

  const handleContactBy = input => e => {
    const { value, checked } = e.target;
    const { contact_by } = formData;
    if (checked) {
      setFormData(prevState => ({
        ...prevState,
        contact_by: [...contact_by, value],
      }));

    } else {
      setFormData(prevState => ({
        ...prevState,
        contact_by: contact_by.filter((e) => e !== value),
      }));

    }
  }



  const [value, setValue] = useState('');
  const [category, setCategory] = useState('');
  const [error, setError] = useState();
  const [validated, setValidated] = useState(false);
  const [file, setFile] = useState();
  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  console.log(formData)

  switch (step) {
    case 1:
      return (
        <>
          <AdminSignupStepOne nextStep={nextStep} handleImage={handleImage} handleFormData={handleInputData} handleCompany={handleCompany} values={formData} />
        </>
      )
    case 2:
      return (
        <>
          <AdminSignupStepTwo nextStep={nextStep} prevStep={prevStep} handleFormData={handleInputData} handleContactBy={handleContactBy} values={formData} />
        </>
      )
    case 3:
      return (
        <>
          <AdminSignupStepThree nextStep={nextStep} prevStep={prevStep} handleFormData={handleInputData} values={formData} />
        </>
      )
    case 4:
      return (
        <>
          <AdminSignupStepFour values={formData} prevStep={prevStep} handleFormData={handleCompany} />
        </>
      )
    // never forget the default case, otherwise VS code would be mad!
    default:
    // do nothing
  }
}

export default OrgRegistration
